import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './Navbar.css';
import logo from '../../assets/images/logo.png';
import { ChevronDownIcon } from '../../assets';

function Navbar() {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const [openProducts, setOpenProducts] = useState(false);

  return (
    <Box
      as="nav"
      position="fixed"
      top="0"
      left={0}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding={['.5rem 1.05rem', '.7rem 2.5rem']}
      sx={{
        '& a': {
          color: 'inherit',
          display: 'block',
          '&:not(:first-of-type)': {
            ml: [3, 5],
          },
        },
        background: '#fff',
      }}
      width="100%"
      fontFamily="Quicksand"
      fontSize={['0.7rem', '1rem']}
      zIndex={5}
    >
      <Link to="/">
        <img src={logo} alt="logo" width={74} />
      </Link>

      <Box display="flex" alignItems="center">
        <Link to="/resources"> {t('Resource center')}</Link>

        <a href="mailto:info@rezzie.co">{t('Contact us')}</a>

        {pathname === '/resumeandcoverletterbuilder/login' ||
        pathname === '/resumeandcoverletterbuilder/signup' ? null : (
          /* <Link to="/resume-builder">
          <button type="button" className="button navBar-btn"> 
             {t('Resume builder')} 
             {t('Resume builder')} 
          </button> 
        </Link> */
          <Box
            position="relative"
            ml={5}
            sx={{
              '& img': {
                transition: '0.3s',
                rotate: openProducts ? '180deg' : '0deg',
              },
            }}
          >
            <Typography
              display="flex"
              gap={1}
              fontSize={['0.7rem', '1rem']}
              onClick={() => setOpenProducts((prev) => !prev)}
            >
              Products
              <img width={10} src={ChevronDownIcon} alt="open products" />
            </Typography>
            <Box
              position="absolute"
              whiteSpace="nowrap"
              top="30px"
              left={['-60px']}
              zIndex={10}
              sx={{
                background: '#fff',
                border: '1px solid #eee',
                borderRadius: '8px',
                transition: 'transform 0.3s ease-in-out',
                transformOrigin: 'top',
                transform: `scaleY(${openProducts ? '1' : '0'})`,
              }}
            >
              <Link to="/resumeandcoverletterbuilder/login">
                <Typography px={2} py={1} fontSize={['0.7rem', '1rem']}>
                  Resume and cover <br /> letter builder
                </Typography>
              </Link>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default Navbar;
