import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup';
import { getCountries, getCountry } from '@loophq/country-state-list';
import styled from 'styled-components';
import {
  Container,
  InputBase,
  FormControl,
  Box,
  CircularProgress,
  Select,
  MenuItem,
} from '@mui/material';
import apiClient from '../../../../api/server';
import LiveResumeEdit from '../../../../components/LiveResumeEdit';

const MainContent = styled.main`
  .title {
    font-family: 'Circular Std Bold';
    font-size: 32px;
    line-height: 32px;
    letter-spacing: 0em;
    /* text-align: left; */
    color: #131936;
    margin-bottom: 10px;
  }

  .description {
    font-family: 'Circular Std Book';
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0em;
    /* text-align: left; */
    color: #131936;
    margin-bottom: 30px;
  }

  .input-label {
    margin-bottom: 10px;
    font-family: 'Circular Std Book' !important;
    font-style: normal;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #272833;
  }

  .tip-section {
    display: flex;
    flex-direction: row;
    margin-top: 80px;
  }

  .tip-img-section {
    flex: 1;
  }

  .tip-content-section {
    flex: 14;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .tip-icon {
    width: 34px;
  }

  .tip-title {
    font-family: 'Circular Std Bold';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }

  .tip-description {
    font-family: 'Circular Std Book';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
  }

  .words-left {
    font-family: 'Circular Std Book';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #838383;
  }

  .button-section {
    margin-top: 40px;
    text-align: right;
  }
  .inline {
    margin-right: 45px;
    display: inline;

    :last-child {
      margin-right: 0px;
    }
  }

  .form_next_btn {
    min-width: 147px;
    height: 40px;
    padding: 0 16px;
    border-radius: 4px;
    background: #5c70cd;
    font-size: 16px;
    line-height: 29px;
    letter-spacing: 0em;
    border: none;
    color: white;
    cursor: pointer;
    text-transform: capitalize;
    font-family: 'Circular Std Book' !important;

    :hover {
      background: #5c70cd;
    }
  }

  .back-button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  .input-section {
    margin-bottom: 25px;
  }

  .loading-container {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
  }

  .skip-button {
    font-family: Circular Std Medium !important;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0em;
    float: right;
    color: rgb(92, 112, 205);
    cursor: pointer;
    outline: none;
    background: none;
    border: none;
  }
`;

const BootstrapSelectInput = styled(InputBase)(() => ({
  'label + &': {
    marginTop: 40,
  },
  '.MuiInputBase-root': {
    fontFamily: 'Circular Std Book',
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#F1F2F5',
    border: '1px solid #E7E7ED',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    fontFamily: 'Circular Std Book',
    '&:focus': {
      borderColor: 'rgba(92, 112, 205, 0.13)',
    },
  },
}));

const BootstrapInput = styled(InputBase)(() => ({
  'label + &': {
    marginTop: 40,
  },
  '& .MuiInputBase-root': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#F1F2F5',
    border: '1px solid #E7E7ED',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    fontFamily: 'Circular Std Book',
    '&:focus': {
      borderColor: 'rgba(92, 112, 205, 0.13)',
    },
  },
  '& .MuiInputBase-input': {
    fontFamily: 'Circular Std Book',
    width: '100%',
    color: 'rgb(39, 40, 51)',
  },
}));

const ContactInformation = React.forwardRef(
  ({ user, back, next, formOnly }, ref) => {
    const { t, i18n } = useTranslation();
    const formRef = useRef();
    const [data, setData] = useState({
      id: '',
      firstName: '',
      lastName: '',
      country: '',
      city: '',
      linkedInUrl: '',
      portfolioUrl: '',
    });
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [fromNav, setFromNav] = useState(false);

    useImperativeHandle(ref, () => ({
      async submit() {
        if (formRef.current) {
          setFromNav(true);
          await formRef.current.handleSubmit();
        }
      },
    }));

    const getData = async () => {
      const response = await apiClient.getContact(user.id);
      if (response.status) {
        setData({
          id: response.data.id,
          firstName: response.data.user.firstName
            ? response.data.user.firstName
            : '',
          lastName: response.data.user.lastName
            ? response.data.user.lastName
            : '',
          country: response.data.country ? response.data.country : '',
          city: response.data.city ? response.data.city : '',
          linkedInUrl: response.data.linkedInUrl
            ? response.data.linkedInUrl
            : '',
          portfolioUrl: response.data.portfolioUrl
            ? response.data.portfolioUrl
            : '',
        });

        if (response.data.country) {
          const country = countries.find(
            (x) => x.name === response.data.country
          );
          setStates(country.states);
        }

        setIsLoading(false);
      } else {
        setData({
          id: '',
          firstName: '',
          lastName: '',
          country: '',
          city: '',
          linkedInUrl: '',
          portfolioUrl: '',
        });

        setIsLoading(false);
      }
    };

    const handleGetState = (value) => {
      const countryObj = countries.find((x) => x.name === value);
      const country = getCountry(countryObj.code);
      setStates(country.states);
    };

    const validationSchema = yup.object({
      firstName: yup
        .string('Enter your First Name')
        .required('First Name is required'),
      lastName: yup
        .string('Enter your Last Name')
        .required('Last Name is required'),
      country: yup
        .string('Select your Country')
        .required('Country is required'),
      city: yup
        .string('Select your State/City')
        .required('State/City is required'),
    });

    const handleFormSubmit = async (values, setSubmitting) => {
      let response = '';
      if (data.id) {
        response = await apiClient.editContact(user.id, values, data.id);
      } else {
        response = await apiClient.postContact(user.id, values);
      }
      if (response.status) {
        if (!formOnly) {
          if (fromNav) {
            next(true);
          } else {
            next();
          }
        } else {
          toast.success('Update Successful');
          setSubmitting(false);
        }
      } else {
        toast.error(response.message);
        setSubmitting(false);
      }
    };

    const handleBack = () => {
      back();
    };

    useEffect(() => {
      setIsLoading(true);
      setCountries(getCountries());
    }, []);

    useEffect(() => {
      if (countries.length > 0) {
        getData();
      }
    }, [countries]);

    const handleCountryChange = (e) => {
      formRef.current.values.country = e.target.value;
      formRef.current.values.city = '';
      handleGetState(e.target.value);
    };

    const handleStateChange = (e) => {
      formRef.current.values.city = e.target.value;
    };

    return (
      <MainContent>
        {!isLoading && (
          <Box>
            {!formOnly && (
              <Box>
                <button
                  type="button"
                  className="skip-button"
                  onClick={() => next()}
                >
                  {t('Skip This')}
                </button>
                <p className="title">{t('Contact Information')}</p>
                <p className="description">{t('Fill Contact Information')}</p>
              </Box>
            )}
            <Container id="form-section" className="form-container">
              <Formik
                innerRef={formRef}
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={data}
                onSubmit={(values, { setSubmitting }) => {
                  handleFormSubmit(values, setSubmitting);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  isSubmitting,
                }) => (
                  <form className="form" onSubmit={handleSubmit}>
                    <Box className="form-main--content">
                      <Box className="input-section">
                        <p className="input-label">{t('First Name')}</p>
                        <FormControl
                          className="form-container"
                          variant="standard"
                        >
                          <BootstrapInput
                            placeholder="First Name"
                            id="text-input"
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="firstName"
                          />
                          {errors.firstName && touched.firstName ? (
                            <div>{errors.firstName}</div>
                          ) : null}
                        </FormControl>
                      </Box>

                      <Box className="input-section">
                        <p className="input-label">{t('Last Name')}</p>
                        <FormControl
                          className="form-container"
                          variant="standard"
                        >
                          <BootstrapInput
                            placeholder={t('Last Name')}
                            id="text-input"
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="lastName"
                          />
                          {errors.lastName && touched.lastName ? (
                            <div>{errors.lastName}</div>
                          ) : null}
                        </FormControl>
                      </Box>

                      <Box className="input-section">
                        <p className="input-label">{t('Country')}</p>
                        <FormControl className="form-container">
                          <Select
                            id="country-select"
                            value={values.country}
                            onChange={handleCountryChange}
                            input={<BootstrapSelectInput />}
                            displayEmpty
                            name="country"
                            renderValue={(value) =>
                              value !== '' ? value : t('Select Country')
                            }
                          >
                            <MenuItem value="" disabled>
                              {t('Select Country')}
                            </MenuItem>
                            {countries.map((item) => (
                              <MenuItem key={item.code} value={item.name}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.country && touched.country ? (
                            <div>{errors.country}</div>
                          ) : null}
                        </FormControl>
                      </Box>

                      <Box className="input-section">
                        <p className="input-label">{t('State/City')}</p>
                        <FormControl className="form-container">
                          <Select
                            id="state-city-select"
                            value={values.city}
                            name="city"
                            onChange={handleStateChange}
                            input={<BootstrapSelectInput />}
                            displayEmpty
                            renderValue={(value) =>
                              value !== '' ? value : t('Select State/City')
                            }
                          >
                            <MenuItem value="" disabled>
                              {t('Select State/City')}
                            </MenuItem>
                            {states.map((item) => (
                              <MenuItem key={item} value={item}>
                                {item}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.city && touched.city ? (
                            <div>{errors.city}</div>
                          ) : null}
                        </FormControl>
                      </Box>

                      <Box className="input-section">
                        <p className="input-label"> {t('LinkedIn URL')}</p>
                        <FormControl
                          className="form-container"
                          variant="standard"
                        >
                          <BootstrapInput
                            placeholder={t('LinkedIn URL')}
                            id="text-input"
                            value={values.linkedInUrl}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="linkedInUrl"
                          />
                        </FormControl>
                      </Box>

                      <Box className="input-section">
                        <p className="input-label">{t('Portfolio URL')}</p>
                        <FormControl
                          className="form-container"
                          variant="standard"
                        >
                          <BootstrapInput
                            placeholder={t('Portfolio URL')}
                            id="text-input"
                            value={values.portfolioUrl}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="portfolioUrl"
                          />
                        </FormControl>
                      </Box>

                      <Box className="button-section">
                        {!isSubmitting && (
                          <Box>
                            {!formOnly && (
                              <button
                                type="button"
                                className="back-button inline"
                                onClick={handleBack}
                              >
                                {t('Back')}
                              </button>
                            )}

                            <button
                              type="submit"
                              onClick={handleSubmit}
                              className="form_next_btn inline"
                            >
                              {formOnly ? t('Save') : t('Next Step')}
                            </button>
                          </Box>
                        )}
                        {isSubmitting && <CircularProgress />}
                      </Box>
                    </Box>
                  </form>
                )}
              </Formik>
            </Container>
          </Box>
        )}

        {isLoading && (
          <Container className="loading-container">
            <CircularProgress size={60} />
          </Container>
        )}
      </MainContent>
    );
  }
);
ContactInformation.displayName = 'ContactInformation';
export default ContactInformation;
