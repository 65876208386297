import React from 'react';
import styled from 'styled-components';
import artiImage from '../../../assets/images/artiimage.png';

const ImportantLinks = styled.article`
  margin-top: 64px;

  figure, article {
    display: inline-block;
    vertical-align: top;
  }

  figure {
    margin-right: 20px;
  }

  article {
    max-width: 391px;
  }

  .article_header_main {
    margin-bottom: 27px;
    font-family: 'Circular Std Bold';
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0em;
    color: #131936;
  }
  .article_header_sub {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0em;
    color: #131936;
    
    &_description{
      font-size: 16px;
      line-height: 30px;
      letter-spacing: 0em;

color: #131936;
    }
  }
`;

const Articles = () => (
  <ImportantLinks>
    <h2 className="article_header_main">Important links</h2>
    <figure>
      <img src={artiImage} alt="Arti" />
    </figure>
    <article>
      <h2 className="article_header_sub">How to write a compelling headline</h2>
      <small className="article_header_sub_description">Here we’re going to have the preview to this blog post.</small>
    </article>
  </ImportantLinks>
);

export default Articles;
