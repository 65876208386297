import React from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Button,
} from '@mui/material';

export const CustomCard = ({ img, imgAlt, title, text, link, action }) => (
  <Card sx={{ boxShadow: 'none' }}>
    <CardMedia component="img" image={img} alt={imgAlt} height={200} />
    <CardContent>
      <Typography
        as="h5"
        sx={{ fontSize: '20px', fontWeight: '600', margin: '18px 0' }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          height: '150px',
          marginTop: '0.5rem',
          fontSize: '16px',
          lineHeight: '32px',
          color: '#131936',
          fontWeight: '600',
        }}
      >
        {text}
      </Typography>

      {link && (
        <Link to={link}>
          <Button
            sx={{
              background: '#5c70cd',
              margin: '20px auto 0',
              textTransform: 'unset',
            }}
            type="button"
            variant="contained"
          >
            {action}
          </Button>
        </Link>
      )}
      {action.toLowerCase() === 'coming soon' && (
        <Box
          sx={{
            backgroundColor: '#83a91729',
            width: 'fit-content',
            padding: '10px 12px',
            borderRadius: '16px',
            margin: '20px auto 0',
            color: '#131936',
          }}
        >
          {action}
        </Box>
      )}
    </CardContent>
  </Card>
);

export const SectionHeading = ({ children }) => (
  <Typography sx={{ fontSize: '32px', lineHeight: '40px', fontWeight: '600' }}>
    {children}
  </Typography>
);

export const AboutText = ({ children }) => (
  <Typography
    sx={{
      marginTop: '1.5rem',
      fontSize: { xs: '16px', md: '22px' },
      lineHeight: '32px',
      fontWeight: '500',
    }}
  >
    {children}
  </Typography>
);

export const Wrapper = ({ children }) => (
  <Box
    sx={{
      width: '100%',
      padding: { xs: '3rem 1.05rem', md: '3rem 2.5rem' },
    }}
  >
    {children}
  </Box>
);

export const ImageWrapper = ({ children }) => (
  <Box
    sx={{
      maxWidth: '400px',
      maxHeight: '400px',
      margin: '0 auto',
      '& img': { width: '100%', height: '100%', objectFit: 'contain' },
    }}
  >
    {children}
  </Box>
);
