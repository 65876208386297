import React, { forwardRef, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Btn = ({ onClick, children }) => (
  <Button
    onClick={onClick}
    mt={3}
    sx={{
      background: '#19BCFE',
      color: 'white',
      borderRadius: '4px',
      marginTop: '1.5rem',
      padding: '0.125rem 0.75rem',
      fontSize: '1.25rem',
      fontWeight: '600',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      cursor: 'pointer',
      textTransform: 'unset',
      '&:hover': {
        background: '#1ACDFF',
      },
    }}
  >
    {children}
  </Button>
);

const Question = forwardRef(
  ({ id, question, options, setAnswerCount, scrollToQuestion }, ref) => {
    const [selectedOption, setSelectedOption] = useState(null);

    const handleAnswerCount = (_id) => {
      // Check if a selected option exists
      if (selectedOption !== _id) {
        // Check if a previously selected option is the same as the one being passed.
        // Only change the count when a different option is picked
        if (selectedOption) {
          setAnswerCount((prev) => ({
            ...prev,
            [selectedOption]: prev[selectedOption] - 1,
            [_id]: prev[_id] + 1,
          }));
        } else {
          setAnswerCount((prev) => ({
            ...prev,
            [_id]: prev[_id] + 1,
          }));
        }
        setSelectedOption(_id);
      }
      scrollToQuestion(id, 'next');
    };

    return (
      <Box
        ref={ref}
        height="100vh"
        width="100vw"
        px={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          scrollSnapAlign: 'center',
        }}
      >
        <Box
          display="flex"
          alignItems="start"
          maxWidth="720px"
          gap={2}
          mx="auto"
        >
          <Typography
            as="h3"
            fontSize={['14px', '1rem']}
            mt={1}
            sx={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}
          >
            {id + 1}{' '}
            <ArrowForwardIcon sx={{ fontSize: { xs: '14px', md: '1rem' } }} />
          </Typography>
          <Box flexGrow={1}>
            <Typography as="h3" mb={4} fontSize={['1.25rem', '1.5rem']}>
              {question}
            </Typography>
            <Box>
              {options.map((o) => (
                <Box
                  key={o.id}
                  p={1}
                  display="flex"
                  gap={1}
                  mb={1}
                  sx={{
                    borderRadius: '4px',
                    border: `${
                      selectedOption === o.id ? '2.5' : '1'
                    }px solid #19BCFE`,

                    background: '#19BCFE20',
                  }}
                  fontSize={['1rem', '1.25rem']}
                  onClick={() => handleAnswerCount(o.id)}
                >
                  <Box
                    fontSize="0.75rem"
                    width="22px"
                    height="22px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    onClick={() => scrollToQuestion(id, 'next')}
                    sx={{
                      borderRadius: '4px',
                      border: `1px solid #19BCFE`,
                      background: selectedOption === o.id ? '#19BCFE' : '#fff',
                      color: selectedOption === o.id ? '#fff' : '#19BCFE',
                      fontWeight: 700,
                    }}
                  >
                    {o.id}
                  </Box>
                  {o.text}
                  {selectedOption === o.id && (
                    <CheckIcon fontSize="small" color="#19BCFE" />
                  )}
                </Box>
              ))}

              <Btn onClick={() => scrollToQuestion(id, 'next')}>
                {id !== 8 ? (
                  <>
                    OK <CheckIcon fontSize="small" />
                  </>
                ) : (
                  'Submit'
                )}
              </Btn>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
);

Question.displayName = 'Question';
export default Question;
