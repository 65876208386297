import React from 'react';
// import { Field } from 'formik';
// import { CheckLabel } from '../../components/styles';

const SingleEducation = ({
  values,
  educationIndex,
  touched,
  indexToSubmit,
  setIndexToSubmit,
  isFormRequesting,
  initialValues,
  setinitialValues,
}) => {
  const updateArray = (e) => {
    const caret = e.target.selectionStart;
    const element = e.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });
    const newArr = [...initialValues]; // copying the old datas array
    if (e.target.type === 'checkbox') {
      newArr[educationIndex] = {
        ...values[educationIndex],
        [e.target.name]: e.target.checked,
      }; // replace e.target.value with whatever you want to change it to
    } else
      newArr[educationIndex] = {
        ...values[educationIndex],
        [e.target.name]: e.target.value,
      }; // replace e.target.value with whatever you want to change it to

    setinitialValues(newArr);
  };
  return (
    <div className="single__edu_wrapper">
      <label>
        <span className="important-field">Name of School</span>
        <br />
        <input
          type="text"
          placeholder="Enter the name of the institution here"
          name="name"
          className={
            values[educationIndex]?.name?.length === 0 &&
            touched[educationIndex]
              ? 'rez_input_error'
              : ''
          }
          value={values[educationIndex]?.name}
          required
          onChange={updateArray}
        />
      </label>
      <label>
        <span className="important-field">Degree</span>
        <br />
        <select
          name="degree"
          value={values[educationIndex]?.degree}
          className={
            values[educationIndex]?.degree?.length === 0 &&
            touched[educationIndex]
              ? 'rez_input_error'
              : ''
          }
          required
          onChange={updateArray}
        >
          <option value="">Select Degree</option>
          <option value="Associate">Associate</option>
          <option value="Bachelor's">Bachelor&apos;s</option>
          <option value="Masters">Masters</option>
          <option value="PHD/Doctorate">PHD/Doctorate</option>
        </select>
      </label>
      <label>
        <span className="important-field">Field of study</span>
        <br />
        <input
          type="text"
          placeholder="E.g Computer Science"
          name="fieldOfStudy"
          className={
            values[educationIndex]?.fieldOfStudy?.length === 0 &&
            touched[educationIndex]
              ? 'rez_input_error'
              : ''
          }
          required
          value={values[educationIndex]?.fieldOfStudy}
          onChange={updateArray}
        />
      </label>

      <label>
        <span className="important-field">Date Started</span>
        <br />
        <input
          type="date"
          name="startDate"
          value={values[educationIndex]?.startDate}
          onChange={updateArray}
          className={
            values[educationIndex]?.startDate.length === 0 &&
            touched[educationIndex]
              ? 'rez_input_error'
              : ''
          }
          required
        />
      </label>

      <label>
        <input
          type="checkbox"
          name="active"
          checked={values[educationIndex]?.active}
          onChange={updateArray}
        />
        &nbsp;&nbsp; I currently study here
        <br />
        <br />
      </label>

      {!values[educationIndex].active && (
        <label>
          End Date
          <br />
          <input
            type="date"
            name="endDate"
            required={!values[educationIndex].endDate}
            value={
              values[educationIndex]?.endDate &&
              new Date(values[educationIndex].endDate)
                .toISOString()
                .substring(0, 10)
            }
            onChange={updateArray}
            className={
              !values[educationIndex].active &&
              values[educationIndex]?.endDate?.length === 0 &&
              touched[educationIndex]
                ? 'rez_input_error'
                : ''
            }
          />
        </label>
      )}
      <label>
        Grade
        <br />
        <input
          type="text"
          placeholder="What grade did you attain for this course?"
          name="grade"
          value={values[educationIndex]?.grade}
          onChange={updateArray}
          className={
            values[educationIndex]?.grade?.length === 0 &&
            touched[educationIndex]
              ? 'rez_input_error'
              : ''
          }
        />
      </label>

      <label>
        Activities and Societies
        <br />
        <textarea
          // as="textarea"
          placeholder="Share any achievements and what your role entailed here"
          name="others"
          value={
            values[educationIndex]?.others ? values[educationIndex]?.others : ''
          }
          onChange={updateArray}

          // required
        />
      </label>

      <div>
        <button
          type="submit"
          className="form_next_btn"
          onClick={() => setIndexToSubmit(educationIndex)}
          disabled={isFormRequesting && indexToSubmit === educationIndex}
        >
          {isFormRequesting && indexToSubmit === educationIndex
            ? 'Saving...'
            : 'Save Changes'}
        </button>
        <div className="clearfix" />
      </div>
    </div>
  );
};

export default SingleEducation;
