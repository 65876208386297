import React from 'react';
import {
  Section,
  SectionHeading,
  SectionBody,
  Paragraph,
} from '../styles';

const IntellectualProperty = () => (
  <Section>
    <SectionHeading>Intellectual Property</SectionHeading>
    <SectionBody>
      <Paragraph>
        The Service and its original content (excluding Content provided by You
        or other users), features and functionality are and will remain the
        exclusive property of the Company and its licensors.
      </Paragraph>
      <Paragraph>
        The Service is protected by copyright, trademark, and other laws of both
        the Country and foreign countries.
      </Paragraph>
      <Paragraph>
        Our trademarks and trade dress may not be used in connection with any
        product or service without the prior written consent of the Company.
      </Paragraph>
    </SectionBody>
  </Section>
);

export default IntellectualProperty;
