import React from 'react';

const SingleLanguage = ({
  values,
  LanguageIndex,
  touched,
  initialValues,
  setinitialValues,
  indexToSubmit,
  setIndexToSubmit,
  isFormRequesting,
}) => {
  const updateArray = (e) => {
    const caret = e.target.selectionStart;
    const element = e.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });
    const newArr = [...initialValues]; // copying the old datas array
    if (e.target.type === 'checkbox') {
      newArr[LanguageIndex] = {
        ...values[LanguageIndex],
        [e.target.name]: e.target.checked,
      }; // replace e.target.value with whatever you want to change it to
    } else
      newArr[LanguageIndex] = {
        ...values[LanguageIndex],
        [e.target.name]: e.target.value,
      }; // replace e.target.value with whatever you want to change it to

    setinitialValues(newArr);
  };

  // console.log('valxxx', values);
  return (
    <div className="single__Test_wrapper">
      <label>
        <span className="important-field">Language</span>
        <br />
        <input
          type="text"
          name="language"
          value={values[LanguageIndex]?.language}
          className={
            values[LanguageIndex]?.language?.length === 0 &&
            touched[LanguageIndex] &&
            'rez_input_error'
          }
          required
          placeholder="language"
          onChange={updateArray}
        />
      </label>

      <label>
        <span className="important-field">Proficiency</span>
        <br />
        <select
          name="proficiency"
          defaultValue={values[LanguageIndex].proficiency}
          className={
            values[LanguageIndex]?.proficiency?.length === 0 &&
            touched[LanguageIndex] &&
            'rez_input_error'
          }
          required
          onChange={updateArray}
        >
          <option value="">Select fluency</option>
          <option value="elementary">Elementary proficiency</option>
          <option value="limitedWorkingProficiency">
            Limited working proficiency
          </option>
          <option value="professionalWorkingProficiency">
            Professional working proficiency
          </option>
          <option value="fullPeofessionalProficiency">
            Full professional proficiency
          </option>
          <option value="nativeOrBilingual">
            Native or bilingual proficiency
          </option>
        </select>
      </label>

      <button
        type="submit"
        className="form_next_btn"
        onClick={() => setIndexToSubmit(LanguageIndex)}
        disabled={isFormRequesting && indexToSubmit === LanguageIndex}
      >
        {isFormRequesting && indexToSubmit === LanguageIndex
          ? 'Saving...'
          : 'Save Changes'}
      </button>
    </div>
  );
};

export default SingleLanguage;
