import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ResourcesHeaderIcon } from '../../../../assets';

function Welcome() {
  const { t } = useTranslation();

  return (
    <Box
      as="header"
      height={["fit-content", "100vh"]}
      sx={{
        padding: { xs: '2rem 1.25rem 0.8rem', md: '4rem 2.5rem 1.75rem' },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .header-img': {
          display: 'block',
          mx: 'auto',
          width: '100%',
          maxWidth: '550px',
        },
      }}
    >
      <Box textAlign="center" mb={[8, 0]} mt={[12, 0]}>
        <Typography
          fontSize={['41px', '56px']}
          fontWeight="700"
          maxWidth="725px"
          lineHeight="1.2"
          mb="1.5rem"
          mx="auto"
          sx={{
            textShadow: '0 8px 5px rgba(0, 0, 0, 0.25)',
          }}
        >
          {t('Welcome to the ')}

          <Box as="span" color="#19BCFE" display="inline">
            Resource Center
          </Box>
        </Typography>
        <Typography fontSize="20px" maxWidth="450px" mx="auto" mb={[4]}>
          {t('Make use of the different')}
        </Typography>
        <Box>
          <img
            className="header-img"
            src={ResourcesHeaderIcon}
            alt="3 people"
          />
        </Box>
      </Box>
    </Box>
  );
}

export default Welcome;
