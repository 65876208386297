import React from 'react';
import styled from 'styled-components';
import Layout from '../../layout/Layout';

const MainContent = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Wrapper = ({ children }) => (
  <Layout>
    <MainContent>{children}</MainContent>
  </Layout>
);

export default Wrapper;
