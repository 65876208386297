import React from 'react';
import { Paragraph, Section, SectionBody, SectionHeading } from '../styles';

const Disclaimer = () => (
  <>
    <Section>
      <SectionHeading>
        &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; Disclaimer
      </SectionHeading>
      <SectionBody>
        <Paragraph>
          The Service is provided to You &quot;AS IS&quot; and &quot;AS
          AVAILABLE&quot; and with all faults and defects without warranty of
          any kind. To the maximum extent permitted under applicable law, the
          Company, on its own behalf and on behalf of its Affiliates and its and
          their respective licensors and service providers, expressly disclaims
          all warranties, whether express, implied, statutory or otherwise, with
          respect to the Service, including all implied warranties of
          merchantability, fitness for a particular purpose, title and
          non-infringement, and warranties that may arise out of course of
          dealing, course of performance, usage or trade practice. Without
          limitation to the foregoing, the Company provides no warranty or
          undertaking, and makes no representation of any kind that the Service
          will meet Your requirements, achieve any intended results, be
          compatible or work with any other software, applications, systems, or
          services, operate without interruption, meet any performance or
          reliability standards or be error free or that any errors or defects
          can or will be corrected.
        </Paragraph>
        <Paragraph>
          Without limiting the foregoing, neither the Company nor any of the
          company&apos;s provider makes any representation or warranty of any
          kind, express or implied: (i) as to the operation or availability of
          the Service, or the information, content, and materials or products
          included thereon; (ii) that the Service will be uninterrupted or
          error-free; (iii) as to the accuracy, reliability, or currency of any
          information or content provided through the Service; or (iv) that the
          Service, its servers, the content, or e-mails sent from or on behalf
          of the Company are free of viruses, scripts, trojan horses, worms,
          malware, timebombs or other harmful components.
        </Paragraph>
        <Paragraph>
          Some jurisdictions do not allow the exclusion of certain types of
          warranties or limitations on applicable statutory rights of a
          consumer, so some or all of the above exclusions and limitations may
          not apply to You. But in such a case the exclusions and limitations
          set forth in this section shall be applied to the greatest extent
          enforceable under applicable law.
        </Paragraph>
      </SectionBody>
    </Section>
    <Section>
      <SectionHeading>Governing Law</SectionHeading>
      <Paragraph>
        The laws of the Country, excluding its conflicts of law rules, shall
        govern this Terms and Your use of the Service. Your use of the
        Application may also be subject to other local, state, national, or
        international laws.
      </Paragraph>
    </Section>
    <Section>
      <SectionHeading>Disputes Resolution</SectionHeading>
      <Paragraph>
        If You have any concern or dispute about the Service, You agree to first
        try to resolve the dispute informally by contacting the Company.
      </Paragraph>
    </Section>
    <Section>
      <SectionHeading>For European Union (EU) Users</SectionHeading>
      <Paragraph>
        If You are a European Union consumer, you will benefit from any
        mandatory provisions of the law of the country in which you are resident
        in.
      </Paragraph>
    </Section>
    <Section>
      <SectionHeading>United Kingdom Legal Compliance</SectionHeading>
      <Paragraph>
        You represent and warrant that (i) You are not located in a country that
        is subject to the United Kingdom government embargo, or that has been
        designated by the United Kingdom government as a &quot;terrorist supporting&quot;
        country, and (ii) You are not listed on any United Kingdom government
        list of prohibited or restricted parties.
      </Paragraph>
    </Section>
  </>
);

export default Disclaimer;
