import styled from 'styled-components';

export const MainWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  @media (max-width: 934px) {
    flex-direction: column;
  }
`;

export const CoverDiv = styled.section`
  flex: 1;
  background-color: ${({ theme }) => theme.baseDarkBlue};
  min-height: 100vh;
  padding: 50px 0 0 74px;

  @media (max-width: 576px) {
    padding: 20px 0 0 20px;
  }

  @media (max-width: 576px) {
    padding: 20px 0 0 10px;
  }

  .cover-logo {
    font-size: 18px;
    color: #fff;
    margin-bottom: 20px;
    font-weight: bold;
  }
  .cover-caption {
    max-width: 499px;
    font-weight: bold;
    font-size: 30px;
    line-height: 100.5%;
    color: #fff;
    margin-top: 30px;
    font-family: 'Circular Std Bold';

    @media (min-width: 768px) {
      font-size: 40px;
    }

    @media (min-width: 992px) {
      font-size: 50px;
    }
  }

  .cover-description {
    max-width: 521px;
    font-size: 18px;
    line-height: 30px;
    color: #fff;
    margin-top: 37px;
    margin-bottom: 35px;
  }
`;

export const FormWrapper = styled.section`
  flex: 1;
  min-height: 100vh;
  padding: 117px 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 414px) {
    padding: 30px 20px 0;
  }

  form {
    max-width: 419px;
    @media (max-width: 702px) {
      padding: 15px;
    }

    .login-caption {
      font-family: 'Circular Std Black';
      font-size: 40px;
      line-height: 100.5%;
      color: ${({ theme }) => theme.baseHeadingDark};
      margin-bottom: 40px;
    }

    .logo {
      text-align: center;
    }

    label {
      color: ${({ theme }) => theme.baseText};
    }

    input:not(input[type='checkbox']) {
      background: #f1f2f5;
      border: 1px solid ${({ theme }) => theme.baseInputBackground};
      box-sizing: border-box;
      border-radius: 4px;
      height: 40px;
      width: 419px;
      margin: 5px 0 20px;
      padding: 0 16px;
      outline: none;
      color: ${({ theme }) => theme.baseText};

      @media (max-width: 702px) {
        width: 100%;
      }
    }

    input[type='checkbox'] {
      height: 16px;
      width: 16px;
      @supports (-webkit-appearance: none) or (-moz-appearance: none) {
        -webkit-appearance: none;
        -moz-appearance: none;
        border-radius: 2px;
        border: ${({ recieveUpdates }) =>
          recieveUpdates ? 'none' : '1px solid #cdced9'};
        outline: none;
        display: inline-block;
        position: relative;
        margin: 0;
        cursor: pointer;
        margin-right: 8px;
        vertical-align: top;

        font-size: 14px;
        letter-spacing: 0em;
        background: ${({ theme, recieveUpdates }) =>
          recieveUpdates && theme.baseLightBlue};
      }
    }

    .password-input {
      position: relative;
      z-index: 1;
      svg {
        position: absolute;
        right: 15px;
        top: 33%;
        cursor: pointer;
        z-index: 4;
      }
    }
  }
`;

export const MoreDescription = styled.section`
  span {
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: white;
  }
`;

export const LoginButtons = styled.button`
  background: #fff;
  background-repeat: no-repeat;
  background-position: 100px 50%;
  border: 2px solid ${({ theme }) => theme.baseLightBlue};
  border-radius: 4px;
  margin-top: 16px;
  height: 40px;
  width: 419px;
  font-weight: 500;
  font-size: 16px;
  line-height: 179%;
  cursor: pointer;
  color: ${({ theme }) => theme.baseLightBlue};

  @media (max-width: 702px) {
    width: 100%;
  }
`;

export const LoginButtonDiv = styled.div`
  background: #fff;
  background-repeat: no-repeat;
  background-position: 100px 50%;
  border: 2px solid ${({ theme }) => theme.baseLightBlue};
  border-radius: 4px;
  margin-top: 16px;
  height: 40px;
  width: 419px;
  font-weight: 500;
  font-size: 16px;
  line-height: 179%;
  cursor: pointer;
  color: ${({ theme }) => theme.baseLightBlue};
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 702px) {
    width: 100%;
    padding-left: 20px;
  }
`;

export const GoogleButton = styled(LoginButtonDiv)`
  @media (max-width: 702px) {
    width: 100%;
  }
  margin-top: 0px;
  img {
    height: 20px;
  }
`;

export const LinkedInButton = styled(LoginButtonDiv)`
  @media (max-width: 702px) {
    width: 100%;
  }

  img {
    height: 20px;
  }
`;

export const TwitterButton = styled(LoginButtonDiv)`
  img {
    height: 20px;
  }
`;

export const Divider = styled.section`
  color: ${({ theme }) => theme.baseInputBackground};
  margin: 28px auto;

  @media (max-width: 414px) {
    text-align: center;
    svg {
      display: none;
    }
  }
  span {
    margin: 0 12px;
    vertical-align: middle;
    color: #b3b8d4;
  }
`;

export const MainLoginButton = styled.button`
  background: ${({ theme, isLoggedIn }) =>
    isLoggedIn ? 'green' : theme.baseLightBlue};
  border-radius: 4px;
  height: 40px;
  width: 419px;
  border-radius: 4px;
  color: white;
  border: none;
  margin-top: 20px;
  cursor: pointer;

  @media (max-width: 702px) {
    width: 100%;
  }
`;

export const ForgotPasswordText = styled.p`
  font-size: 14px;
  line-height: 30px;

  letter-spacing: 0em;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;

  a {
    color: ${({ theme }) => theme.baseLightBlue};
  }
`;
