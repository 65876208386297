import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Formik, getIn } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';
import {
  Container,
  InputBase,
  Box,
  CircularProgress,
  FormControl,
  TextField,
  Select,
  MenuItem,
  Button,
  IconButton,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import apiClient from '../../../../../api/server';

const MainContent = styled.main`
  width: 100%;
  max-width: 775px;

  .title {
    font-family: 'Circular Std Bold';
    font-size: 32px;
    line-height: 32px;
    letter-spacing: 0em;
    /* text-align: left; */
    color: #131936;
    margin-bottom: 10px;
  }

  .description {
    font-family: 'Circular Std Book';
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0em;
    /* text-align: left; */
    color: #131936;
    margin-bottom: 30px;
  }

  .form-container {
    padding: 0px;
    width: 100%;
  }

  .input-title {
    margin-bottom: 20px;
    font-family: 'Circular Std Bold' !important;
    font-style: normal;
    font-size: 16px !important;
    color: #272833;
  }

  .input-label {
    margin-bottom: 10px;
    font-family: 'Circular Std Book' !important;
    font-style: normal;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #272833;
  }

  .tip-section {
    display: flex;
    flex-direction: row;
    margin-top: 80px;
  }

  .tip-img-section {
    flex: 1;
  }

  .tip-content-section {
    flex: 14;
  }

  .tip-icon {
    width: 34px;
  }

  .tip-title {
    font-family: 'Circular Std Bold';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }

  .tip-description {
    font-family: 'Circular Std Book';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
  }

  .words-left {
    font-family: 'Circular Std Book';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #838383;
  }

  .button-section {
    margin-top: 40px;
    text-align: right;
  }
  .inline {
    margin-right: 45px;
    display: inline;

    :last-child {
      margin-right: 0px;
    }
  }

  .add-achievement-section {
    width: 100%;
    text-align: right;
  }

  .add-item-section {
    width: 100%;
    text-align: center;
  }

  .form_next_btn {
    border-radius: 4px;
    background: #5c70cd;
    color: white;
    font-family: Circular Std Book !important;
    text-transform: none !important;

    :hover {
      background: #5c70cd;
    }
  }

  .checkbox {
    .MuiFormControlLabel-label {
      font-family: 'Circular Std Book' !important;
      font-style: normal;
      font-size: 14px !important;
      font-weight: 500 !important;
    }
  }

  .back-button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  .input-section {
    margin-bottom: 25px;
  }

  .achievement-section {
    position: relative;
    margin-bottom: 25px;
    border: 1px solid #5c70cd;
    border-radius: 4px;
    padding: 5%;
    background: rgba(92, 112, 205, 0.01);
  }

  .loading-container {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
  }

  .item-section {
    position: relative;
    border: 1px solid #5c70cd;
    border-radius: 4px;
    padding: 4% 5% 5% 5%;
    margin-bottom: 25px;
    background: rgba(92, 112, 205, 0.05);
  }

  .Mui-error {
    border-radius: 4px;
    border: 1px solid red;
  }

  .error-text {
    color: red;
    font-size: 12px;
    padding: 5px;
  }

  .date-input {
    background-color: #f1f2f5 !important;
    .MuiOutlinedInput-input {
      font-family: Circular Std Book !important;
      width: 100% !important;
      font-size: 16px !important;
      color: rgb(39, 40, 51) !important;
    }
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #e7e7ed;
    }
  }

  .delete-button {
    position: absolute;
    right: 10px;
    top: 10px;
    color: red !important;
  }

  .remove-text {
    font-size: 14px !important;
    font-family: Circular Std Medium !important;
    margin-top: 3px;
  }
`;

const CustomTextArea = styled(InputBase)(() => ({
  'label + &': {
    marginTop: 40,
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#F1F2F5',
    border: '1px solid #E7E7ED',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    color: 'rgb(39, 40, 51)',
  },
  fontFamily: 'Circular Std Book !important',
  '&:focus': {
    borderColor: 'rgba(92, 112, 205, 0.13)',
  },
}));

const BootstrapInput = styled(InputBase)(() => ({
  'label + &': {
    marginTop: 40,
  },

  '& .MuiInputBase-root': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#F1F2F5',
    border: '1px solid #E7E7ED',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    fontFamily: 'Circular Std Book !important',
    '&:focus': {
      borderColor: 'rgba(92, 112, 205, 0.13)',
    },
  },
  '& .MuiInputBase-input': {
    fontFamily: 'Circular Std Book !important',
    width: '100%',
    color: 'rgb(39, 40, 51)',
  },
}));

const BootstrapSelectInput = styled(InputBase)(() => ({
  'label + &': {
    marginTop: 40,
  },
  '.MuiInputBase-root': {
    fontFamily: 'Circular Std Book !important',
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#F1F2F5',
    border: '1px solid #E7E7ED',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    fontFamily: 'Circular Std Book !important',
    '&:focus': {
      borderColor: 'rgba(92, 112, 205, 0.13)',
    },
  },
}));

const Patents = ({ user, back, next }) => {
  const formRef = useRef();
  const [data, setData] = useState([
    {
      id: '',
      index: 0,
      title: '',
      officeAddress: '',
      patentIssued: false,
      patentDate: moment().toDate(),
      patUrl: '',
      applicationNumber: '',
      description: '',
      allInventors: [],
      inventor: '',
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    const response = await apiClient.getPatent(user.id);
    if (response.status) {
      response.data.forEach((item, index) => {
        item.index = index;
        Object.keys(item).forEach((key) => {
          if (item[key] == null) {
            item[key] = '';
          }
        });

        try {
          item.allInventors.forEach((inventor, inventorIndex) => {
            item.allInventors[inventorIndex] = JSON.parse(inventor);
          });
        } catch (e) {
          console.log(e);
        }
      });

      if (response.data.length > 0) {
        setData(response.data);
        console.log(response.data);
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const validationSchema = yup.array().of(
    yup.object().shape({
      title: yup.string('Enter the title').required('Title is required'),
      officeAddress: yup
        .string('Enter the publisher')
        .required('Patent Office is required'),
      applicationNumber: yup
        .string('Enter the Publication Date')
        .required('Patent / Application Number is required'),
    })
  );

  const handleFormSubmit = async (values, setSubmitting) => {
    const response = await apiClient.postPatent(user.id, values);

    if (response.status) {
      toast.success('Update Successful');
      setSubmitting(false);
    } else {
      toast.error(response.message);
      setSubmitting(false);
    }
  };

  const handleAddEntry = (setFieldValue) => {
    const array = formRef.current.values;
    array.push({
      id: '',
      index: array.length,
      title: '',
      officeAddress: '',
      patentIssued: false,
      patentDate: moment().toDate(),
      applicationNumber: '',
      description: '',
      allInventors: [],
      inventor: '',
    });
    setFieldValue(``, array, false);
  };

  const handleDeleteEntry = (index, setFieldValue) => {
    const array = formRef.current.values;
    array.splice(index, 1);
    setFieldValue(``, array, false);
  };

  const handleAddInventor = (index, setFieldValue) => {
    const array = formRef.current.values[index].allInventors;
    array.push({
      id: `${index}-${formRef.current.values[index].allInventors.length}`,
      value: '',
    });
    setFieldValue(`[${index}].allInventors`, array, false);
  };

  const handleDeleteInventor = (index, inventorIndex, setFieldValue) => {
    const array = formRef.current.values[index].allInventors;
    array.splice(inventorIndex, 1);
    setFieldValue(`[${index}].allInventors`, array, false);
  };

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, []);

  return (
    <MainContent>
      {!isLoading && (
        <Box>
          <Container id="form-section" className="form-container">
            <Formik
              innerRef={formRef}
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={data}
              onSubmit={(values, { setSubmitting }) => {
                handleFormSubmit(values, setSubmitting);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  {values.map((item, index) => (
                    <Box key={`${item.index}`}>
                      <Box className="item-section">
                        <p className="input-title">Patent #{index + 1}</p>
                        <Box className="input-section">
                          <p className="input-label">Patent Title</p>
                          <IconButton
                            aria-label="delete"
                            component="span"
                            disableFocusRipple
                            disableRipple
                            className="delete-button"
                            onClick={() =>
                              handleDeleteEntry(index, setFieldValue)
                            }
                          >
                            <DeleteIcon />
                            <span className="remove-text">Remove</span>
                          </IconButton>
                          <FormControl
                            className="form-container"
                            variant="standard"
                          >
                            <BootstrapInput
                              placeholder="Enter Patent Title"
                              id="text-input"
                              value={item.title}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name={`[${index}].title`}
                              error={
                                getIn(errors, `[${index}].title`) &&
                                getIn(touched, `[${index}].title`)
                              }
                            />
                            {getIn(errors, `[${index}].title`) &&
                            getIn(touched, `[${index}].title`) ? (
                              <Box className="error-text">
                                {getIn(errors, `[${index}].title`)}
                              </Box>
                            ) : null}
                          </FormControl>
                        </Box>

                        <Box className="input-section">
                          <p className="input-label">Patent Office</p>
                          <FormControl
                            className="form-container"
                            variant="standard"
                          >
                            <BootstrapInput
                              placeholder="Enter Patent Office"
                              id="text-input"
                              value={item.officeAddress}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name={`[${index}].officeAddress`}
                              error={
                                getIn(errors, `[${index}].officeAddress`) &&
                                getIn(touched, `[${index}].officeAddress`)
                              }
                            />
                            {getIn(errors, `[${index}].officeAddress`) &&
                            getIn(touched, `[${index}].officeAddress`) ? (
                              <Box className="error-text">
                                {getIn(errors, `[${index}].officeAddress`)}
                              </Box>
                            ) : null}
                          </FormControl>
                        </Box>

                        <Box className="input-section">
                          <p className="input-label">
                            Patent / Application Number
                          </p>
                          <FormControl
                            className="form-container"
                            variant="standard"
                          >
                            <BootstrapInput
                              placeholder="Enter Patent / Application Number"
                              id="text-input"
                              value={item.applicationNumber}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name={`[${index}].applicationNumber`}
                              error={
                                getIn(errors, `[${index}].applicationNumber`) &&
                                getIn(touched, `[${index}].applicationNumber`)
                              }
                            />
                            {getIn(errors, `[${index}].applicationNumber`) &&
                            getIn(touched, `[${index}].applicationNumber`) ? (
                              <Box className="error-text">
                                {getIn(errors, `[${index}].applicationNumber`)}
                              </Box>
                            ) : null}
                          </FormControl>
                        </Box>

                        <p className="input-label">
                          Add other authors that contributed to this patent
                        </p>

                        <br />

                        {item.allInventors &&
                          item.allInventors.map((inventor, inventorIndex) => (
                            <Box
                              className="achievement-section"
                              key={`${inventor.id}`}
                            >
                              <p className="input-label">
                                Inventor #{inventorIndex + 1}
                              </p>
                              <IconButton
                                aria-label="delete"
                                component="span"
                                disableFocusRipple
                                disableRipple
                                className="delete-button"
                                onClick={() =>
                                  handleDeleteInventor(
                                    index,
                                    inventorIndex,
                                    setFieldValue
                                  )
                                }
                              >
                                <DeleteIcon />
                                <span className="remove-text">Remove</span>
                              </IconButton>
                              <FormControl
                                className="form-container"
                                variant="standard"
                              >
                                <BootstrapInput
                                  placeholder="Enter Inventor Name"
                                  id="text-input"
                                  value={inventor.value}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name={`[${index}].allInventors.[${inventorIndex}].value`}
                                  error={
                                    getIn(
                                      errors,
                                      `[${index}].allInventors.[${inventorIndex}].value`
                                    ) &&
                                    getIn(
                                      touched,
                                      `[${index}].allInventors.[${inventorIndex}].value`
                                    )
                                  }
                                />
                              </FormControl>
                            </Box>
                          ))}
                        <Box className="add-achievement-section">
                          <Button
                            variant="contained"
                            disableFocusRipple
                            disableRipple
                            disableElevation
                            className="form_next_btn"
                            onClick={() =>
                              handleAddInventor(index, setFieldValue)
                            }
                            startIcon={<AddIcon />}
                          >
                            Add Inventor
                          </Button>
                        </Box>

                        <Box className="input-section">
                          <FormControlLabel
                            className="checkbox"
                            control={
                              <Checkbox
                                checked={item.patentIssued}
                                onChange={handleChange}
                                sx={{
                                  '&.Mui-checked': {
                                    color: '#5c70cd',
                                  },
                                }}
                                name={`[${index}].patentIssued`}
                              />
                            }
                            label="Patent Issued"
                          />
                        </Box>

                        {item.patentIssued && (
                          <Box className="input-section">
                            <p className="input-label">Patent Issue Date</p>

                            <FormControl
                              className="form-container"
                              variant="standard"
                            >
                              <MobileDatePicker
                                inputFormat="MM/YYYY"
                                views={['year', 'month']}
                                clearable
                                placeholder="Enter Patent Issue Date"
                                value={item.patentDate}
                                onChange={(value) =>
                                  setFieldValue(`[${index}].patentDate`, value)
                                }
                                onBlur={handleBlur}
                                error={
                                  getIn(errors, `[${index}].patentDate`) &&
                                  getIn(touched, `[${index}].patentDate`)
                                }
                                name={`[${index}].patentDate`}
                                renderInput={(params) => (
                                  <TextField
                                    className="date-input"
                                    {...params}
                                  />
                                )}
                              />

                              {getIn(errors, `[${index}].patentDate`) &&
                              getIn(touched, `[${index}].patentDate`) ? (
                                <Box className="error-text">
                                  {getIn(errors, `[${index}].patentDate`)}
                                </Box>
                              ) : null}
                            </FormControl>
                          </Box>
                        )}
                        <Box className="input-section">
                          <p className="input-label">Patent URL</p>
                          <FormControl
                            className="form-container"
                            variant="standard"
                          >
                            <BootstrapInput
                              placeholder="Enter Patent URL"
                              id="text-input"
                              value={item.patUrl}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name={`[${index}].patUrl`}
                              error={
                                getIn(errors, `[${index}].patUrl`) &&
                                getIn(touched, `[${index}].patUrl`)
                              }
                            />
                            {getIn(errors, `[${index}].patUrl`) &&
                            getIn(touched, `[${index}].patUrl`) ? (
                              <Box className="error-text">
                                {getIn(errors, `[${index}].patUrl`)}
                              </Box>
                            ) : null}
                          </FormControl>
                        </Box>

                        <Box className="input-section">
                          <p className="input-label">Description</p>
                          <FormControl
                            className="form-container"
                            variant="standard"
                          >
                            <BootstrapInput
                              placeholder="Enter Description"
                              id="text-input"
                              value={item.description}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name={`[${index}].description`}
                              error={
                                getIn(errors, `[${index}].description`) &&
                                getIn(touched, `[${index}].description`)
                              }
                            />
                            {getIn(errors, `[${index}].description`) &&
                            getIn(touched, `[${index}].description`) ? (
                              <Box className="error-text">
                                {getIn(errors, `[${index}].description`)}
                              </Box>
                            ) : null}
                          </FormControl>
                        </Box>
                      </Box>
                    </Box>
                  ))}

                  <Box className="add-item-section">
                    <Button
                      variant="contained"
                      disableFocusRipple
                      disableRipple
                      disableElevation
                      className="form_next_btn"
                      onClick={() => handleAddEntry(setFieldValue)}
                      startIcon={<AddIcon />}
                    >
                      Add Patent
                    </Button>
                  </Box>

                  <Box className="button-section">
                    {!isSubmitting && (
                      <Box>
                        <button
                          type="submit"
                          onClick={handleSubmit}
                          className="form_next_btn inline"
                        >
                          Save
                        </button>
                      </Box>
                    )}
                    {isSubmitting && <CircularProgress />}
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        </Box>
      )}
      {isLoading && (
        <Container className="loading-container">
          <CircularProgress size={60} />
        </Container>
      )}
    </MainContent>
  );
};

export default Patents;
