import React from 'react';
import { Grid } from '@mui/material';
import { SectionHeading, AboutText, Wrapper, ImageWrapper } from './styles';
import svg from '../../../../assets/images/landing-2.png';


function Index2() {
  return (
    <Wrapper>
      <Grid container alignItems="center" spacing={4}>
        <Grid item sm={6}>
          <ImageWrapper>
            <img src={svg} alt="Build the Perfect Resume in Minutes" />
          </ImageWrapper>
        </Grid>
        <Grid item sm={6}>
          <SectionHeading>
            Build the Perfect Resume in Minutes
          </SectionHeading>
          <AboutText>
            For us, simplicity through ease of use is everything. As such, our
            technology is designed to reduce human effort where possible. Our
            free templates are easy to use and need just enough information to
            give you the best outcome. With our inbuilt help texts and support
            resources, you&apos;ll get a tailored resume in no time.
          </AboutText>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Index2;
