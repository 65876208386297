import React from 'react';

const SingleHonor = ({
  values,
  HonorIndex,
  touched,
  initialValues,
  setinitialValues,
  indexToSubmit,
  setIndexToSubmit,
  isFormRequesting,
}) => {
  const updateArray = (e) => {
    const caret = e.target.selectionStart;
    const element = e.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });
    const newArr = [...initialValues]; // copying the old datas array
    if (e.target.type === 'checkbox') {
      newArr[HonorIndex] = {
        ...values[HonorIndex],
        [e.target.name]: e.target.checked,
      }; // replace e.target.value with whatever you want to change it to
    } else
      newArr[HonorIndex] = {
        ...values[HonorIndex],
        [e.target.name]: e.target.value,
      }; // replace e.target.value with whatever you want to change it to

    setinitialValues(newArr);
  };
  return (
    <div className="single__Honor_wrapper">
      <label>
        <span className="important-field">Title</span>
        <br />
        <input
          type="text"
          name="title"
          value={values[HonorIndex]?.title}
          className={
            values[HonorIndex]?.title?.length === 0 &&
            touched[HonorIndex] &&
            'rez_input_error'
          }
          required
          placeholder="E.g Nobel prize"
          onChange={updateArray}
        />
      </label>

      <label>
        Associated with
        <br />
        <input
          type="text"
          placeholder="E.g Weather"
          name="associatedWith"
          value={values[HonorIndex]?.associatedWith}
          onChange={updateArray}
          className={
            values[HonorIndex]?.associatedWith?.length === 0 &&
            touched[HonorIndex] &&
            'rez_input_error'
          }
        />
      </label>

      <label>
        Issuer
        <br />
        <input
          type="text"
          placeholder="E.g Guiness World record"
          name="issuer"
          value={values[HonorIndex]?.issuer}
          onChange={updateArray}
          className={
            values[HonorIndex]?.issuer?.length === 0 &&
            touched[HonorIndex] &&
            'rez_input_error'
          }
        />
      </label>

      <label>
        <span className="important-field">Issue Date</span>
        <br />
        <input
          type="date"
          name="issueDate"
          className={
            values[HonorIndex]?.issueDate?.length === 0 && touched[HonorIndex]
              ? 'rez_input_error'
              : ''
          }
          onChange={updateArray}
          value={
            values[HonorIndex]?.issueDate &&
            new Date(values[HonorIndex].issueDate)
              .toISOString()
              .substring(0, 10)
          }
          required
        />
      </label>

      <label>
        Description
        <br />
        <textarea
          type="text"
          placeholder="description"
          name="description"
          value={values[HonorIndex]?.description}
          className={
            values[HonorIndex]?.description?.length === 0 &&
            touched[HonorIndex] &&
            'rez_input_error'
          }
          onChange={updateArray}
        />
      </label>

      <button
        type="submit"
        className="form_next_btn"
        onClick={() => setIndexToSubmit(HonorIndex)}
        disabled={isFormRequesting && indexToSubmit === HonorIndex}
      >
        {isFormRequesting && indexToSubmit === HonorIndex
          ? 'Saving...'
          : 'Save Changes'}
      </button>
    </div>
  );
};

export default SingleHonor;
