import React from 'react';
import { Section, SectionHeading, SectionBody, Paragraph } from '../styles';

const UserAccounts = () => (
  <Section>
    <SectionHeading>User Accounts</SectionHeading>
    <SectionBody>
      <Paragraph>
        When You create an account with Us, You must provide Us information that
        is accurate, complete, and current at all times. Failure to do so
        constitutes a breach of the Terms, which may result in immediate
        termination of Your account on Our Service.
      </Paragraph>
      <Paragraph>
        You are responsible for safeguarding the password that You use to access
        the Service and for any activities or actions under Your password,
        whether Your password is with Our Service or a Third-Party Social Media
        Service.
      </Paragraph>
      <Paragraph>
        You agree not to disclose Your password to any third party. You must
        notify Us immediately upon becoming aware of any breach of security or
        unauthorized use of Your account.
      </Paragraph>
      <Paragraph>
        You may not use as a username the name of another person or entity or
        that is not lawfully available for use, a name or trademark that is
        subject to any rights of another person or entity other than You without
        appropriate authorization, or a name that is otherwise offensive, vulgar
        or obscene.
      </Paragraph>
      <Paragraph>
        Your access to and use of the Service is also conditioned on Your
        acceptance of and compliance with the Privacy Policy of the Company. Our
        Privacy Policy describes Our policies and procedures on the collection,
        use and disclosure of Your personal information when You use the
        Application or the Website and tells You about Your privacy rights and
        how the law protects You. Please read Our Privacy Policy carefully
        before using Our Service
      </Paragraph>
    </SectionBody>
  </Section>
);

export default UserAccounts;
