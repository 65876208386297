import styled from 'styled-components';
import caretDouble from '../../assets/images/caret-double.svg';
import calenderIcon from '../../assets/images/Icon-calendar.svg';

export default styled.form`
  display: ${({ formShown }) => (formShown ? 'block' : 'none')};

  margin-top: 40px;

  .form_next_btn {
    height: 40px;
    width: 147px;
    border-radius: 4px;
    background: #5c70cd;
    font-size: 16px;
    line-height: 29px;
    letter-spacing: 0em;
    border: none;
    color: white;
    cursor: pointer;
  }

  .single__experience_wrapper:not(.single__experience_wrapper:first-child),
  .single__license_wrapper:not(.single__license_wrapper:first-child),
  .single__edu_wrapper:not(.single__edu_wrapper:first-child),
  .single__volunteer_wrapper:not(.single__volunteer_wrapper:first-child) {
    border-top: 1px solid #ebebeb;
    margin-top: 20px;
    padding-top: 20px;
  }

  .single__experience_wrapper,
  .single__license_wrapper,
  .single__edu_wrapper,
  .single__volunteer_wrapper,
  .single__accomplishment_wrapper {
    .form_next_btn {
      margin: 20px 0 40px;
      cursor: pointer;
    }
  }

  .textarea_add_experience {
    font-family: 'Circular Std Medium';
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0em;
    color: #5c70cd;
    cursor: pointer;
    svg {
      vertical-align: sub;
    }
  }
  .textarea_delete_experience {
    font-family: 'Circular Std Medium';
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0em;
    color: #e03232;
    cursor: pointer;
    svg {
      vertical-align: sub;
    }
  }

  label {
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0em;

    select,
    input {
      margin-bottom: 25px;
    }

    select {
      appearance: none;
      width: 100%;
      height: 40px;
      border-radius: 4px;
      background: #f1f2f5;
      border: 1px solid ${({ theme }) => theme.baseInputBackground};
      padding: 0 16px;

      background: url(${caretDouble}), #f1f2f5;
      background-repeat: no-repeat;
      background-position: right 13px top 50%, 0 0;

      font-size: 16px;
      line-height: 29px;
      letter-spacing: 0em;
      color: ${({ theme }) => theme.baseText};
    }

    input:not(input[type='checkbox'], input[type='radio']) {
      width: 100%;
      height: 40px;
      border-radius: 4px;
      background: #f1f2f5;
      border: 1px solid ${({ theme }) => theme.baseInputBackground};
      padding: 0 16px;

      font-size: 16px;
      line-height: 29px;
      letter-spacing: 0em;
      color: ${({ theme }) => theme.baseText};
    }

    textarea {
      width: 100%;
      height: 90px;
      border-radius: 4px;
      resize: none;
      background: #f1f2f5;
      border: 1px solid #e7e7ed;
      border-radius: 4px;
      padding: 11px 16px;
      margin-bottom: 10px;

      font-size: 16px;
      line-height: 29px;
      letter-spacing: 0em;
      color: #6b6c7e;
    }

    input[type='number'] {
      max-width: 106px;
      margin-right: 20px;
    }

    .textarea_wordcount {
      font-size: 14px;
      line-height: 30px;
      letter-spacing: 0em;
      color: #838383;
    }

    input[type='date'] {
      border: none;
      box-sizing: border-box;
      outline: 0;
      padding: 0.75rem;
      position: relative;
      width: 100%;
      padding: 0 10px 0 16px !important;
      &::-webkit-inner-spin-button,
      &::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
      }
      background: url(${calenderIcon}), #f1f2f5 !important;
      background-repeat: no-repeat !important;
      background-position: right 13px top 50%, 0 0 !important;
      text-transform: uppercase !important;
    }
  }

  footer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    span {
      font-size: 18px;
      letter-spacing: 0em;
      color: #131936;
      margin-right: 30px;
    }
  }
`;
