import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';
import {
  Container,
  InputBase,
  Box,
  CircularProgress,
  FormControl,
} from '@mui/material';
import tipIcon from '../../../../assets/images/tip_icon.png';
import apiClient from '../../../../api/server';
import LiveResumeEdit from '../../../../components/LiveResumeEdit';

const MainContent = styled.main`
  width: 100%;
  max-width: 775px;

  .title {
    font-family: 'Circular Std Bold';
    font-size: 32px;
    line-height: 32px;
    letter-spacing: 0em;
    /* text-align: left; */
    color: #131936;
    margin-bottom: 10px;
  }

  .description {
    font-family: 'Circular Std Book';
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0em;
    /* text-align: left; */
    color: #131936;
    margin-bottom: 30px;
  }


  .input-label {
    margin-bottom: 10px;
    font-family: 'Circular Std Book' !important;
    font-style: normal;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #272833;
  }


  .tip-img-section {
    flex: 1;
  }

  .tip-content-section {
    flex: 14;
  }

  .tip-icon {
    width: 34px;
  }

  .tip-title {
    font-family: 'Circular Std Bold';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }

  .tip-description {
    font-family: 'Circular Std Book';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
  }

  .words-left {
    font-family: 'Circular Std Book';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #838383;
  }

  .button-section {
    margin-top: 40px;
    text-align: right;
  }
  .inline {
    margin-right: 45px;
    display: inline;

    :last-child {
      margin-right: 0px;
    }
  }

  .form_next_btn {
    min-width: 147px;
    height: 40px;
    padding: 0 16px;
    border-radius: 4px;
    background: #5c70cd;
    font-size: 16px;
    line-height: 29px;
    letter-spacing: 0em;
    border: none;
    color: white;
    cursor: pointer;
    text-transform: capitalize;
    font-family: 'Circular Std Book' !important;

    :hover {
      background: #5c70cd;
    }
  }

  .MuiFormControl-root {
    width: 100%;
  }


  .back-button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  .input-section {
    margin-bottom: 25px;
  }

  .loading-container {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
  }

  .skip-button {
    font-family: Circular Std Medium !important;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0em;
    float: right;
    color: rgb(92, 112, 205);
    cursor: pointer;
    outline: none;
    background: none;
    border: none;
  }
`;

const CustomTextArea = styled(InputBase)(() => ({
  'label + &': {
    marginTop: 40,
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#F1F2F5',
    border: '1px solid #E7E7ED',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    color: 'rgb(39, 40, 51)',
  },
  fontFamily: ['Circular Std Regular'].join(','),
  '&:focus': {
    borderColor: 'rgba(92, 112, 205, 0.13)',
  },
}));

const Headline = React.forwardRef(({ user, back, next, formOnly }, ref) => {
  const formRef = useRef();
  const [data, setData] = useState({
    id: '',
    details: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [fromNav, setFromNav] = useState(false);

  useImperativeHandle(ref, () => ({
    async submit() {
      if (formRef.current) {
        setFromNav(true);
        await formRef.current.handleSubmit();
      }
    },
  }));

  const getData = async () => {
    const response = await apiClient.getHeadline(user.id);
    if (response.status) {
      setData({
        id: response.data.id,
        details: response.data.details,
      });
      setIsLoading(false);

      console.log(response.data)
    } else {
      setData({
        id: '',
        details: '',
      });
      setIsLoading(false);
    }
  };

  const validationSchema = yup.object({
    details: yup.string('Enter your headline'),
  });

  const handleFormSubmit = async (values, setSubmitting) => {
    let response = '';
    if (data.id) {
      response = await apiClient.editHeadline(user.id, values, data.id);
    } else {
      response = await apiClient.postHeadline(user.id, values);
    }
    if (response.status) {
      if (!formOnly) {
        if (fromNav) {
          next(true);
        } else {
          next();
        }
      } else {
        toast.success('Update Successful');
        setSubmitting(false);
      }
    } else {
      toast.error(response.message);
      setSubmitting(false);
    }
  };

  const handleBack = () => {
    back();
  };

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, []);

  return (
    <MainContent>
      {!isLoading && (
        <Box>
          {!formOnly && (
            <Box>
              <button
                type="button"
                className="skip-button"
                onClick={() => next()}
              >
                Skip This
              </button>
              <p className="title">Headline</p>
              <p className="description">
                Tell us a compelling summary of your experiences and interests.
              </p>
            </Box>
          )}

          <Container id="form-section" className="form-container">
            <Formik
              innerRef={formRef}
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={data}
              onSubmit={(values, { setSubmitting }) => {
                handleFormSubmit(values, setSubmitting);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleSubmit,
                handleChange,
                handleBlur,
                isSubmitting,
              }) => (
                <form className='form' onSubmit={handleSubmit}>
                  <Box className="form-main--content">
                    <p className="input-label">Headline</p>
                    <FormControl className="form-container" variant="standard">
                      <CustomTextArea
                        placeholder="Let’s hear a compelling summary of your experiences, achievements and background. This is the first thing recruiters and hiring managers look at when you present your resume."
                        id="custom-textarea-input"
                        multiline
                        rows={8}
                        value={values.details}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="details"
                      />
                      {errors.details && touched.details ? (
                        <div>{errors.details}</div>
                      ) : null}
                    </FormControl>
                    <Box className="button-section">
                      {!isSubmitting && (
                        <Box>
                          {!formOnly && (
                            <button
                              type="button"
                              className="back-button inline"
                              onClick={handleBack}
                            >
                              Back
                            </button>
                          )}

                          <button
                            type="submit"
                            onClick={handleSubmit}
                            className="form_next_btn inline"
                          >
                            {formOnly ? 'Save' : 'Next Step'}
                          </button>
                        </Box>
                      )}
                      {isSubmitting && <CircularProgress />}
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        </Box>
      )}
      <Container id="tip-section" className="tip-section">
        <Box className="tip-img-section">
          <img className="tip-icon" src={tipIcon} alt="bulb icon" />
        </Box>
        <Box className="tip-content-section">
          <p className="tip-title">Tips to help you</p>
          <p className="tip-description">
            Keep it short (one to two lines at most). Use the title case.
            Include years of relevant experience (if you&apos;re experienced).
            Add one or two keywords from the job ad.{' '}
            <em>
              E.g &quot;Detail-oriented UX Designer with 5+ years of design
              experience working with Balsamiq, Figma and Adobe XD.&quot;{' '}
            </em>
          </p>
        </Box>
      </Container>
      {isLoading && (
        <Container className="loading-container">
          <CircularProgress size={60} />
        </Container>
      )}
    </MainContent>
  );
});
Headline.displayName = 'Headline';
export default Headline;
