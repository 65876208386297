import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Grid, Typography, Divider, IconButton } from '@mui/material';

// import styled from 'styled-components';
import { routes } from '../onboardingRoutes';

// const HeadingNav = styled.nav`
//   width: 100%;
//   height: 76px;
//   border-radius: 10px;
//   background: #ffffff;
//   border: 1px solid #e4e4e4;

//   display: flex;
//   justify-content: center;
//   align-items: center;

//   div {
//     flex: 1;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     cursor: pointer;

//     aside {
//       margin-left: 15px;
//       span {
//         font-family: 'Circular Std Medium';
//         font-size: 16px;
//         letter-spacing: 0em;
//         color: ${({ theme }) => theme.baseDarkBlue};
//       }
//       .step {
//         font-family: 'Circular Std Medium';
//         font-size: 16px;
//         letter-spacing: 0em;
//         margin-bottom: 5px;
//         color: ${({ theme }) => theme.baseLightBlue};
//       }
//     }

//     section {
//       svg {
//         path {
//           fill: #c0c7e4;
//         }
//       }
//     }

//     &:not(:first-child) {
//       border-left: 1px solid #e4e4e4;
//       /* text-align: center; */
//     }
//     &.active {
//       flex: 3;
//       section {
//         width: 49px;
//         height: 49px;
//         border-radius: 50%;
//         background: #5c70cd;
//         display: flex;
//         justify-content: center;
//         align-items: center;

//         svg {
//           path {
//             fill: white;
//           }
//         }
//       }
//     }
//   }
// `;

const Navigation = () => {
  const { pathname } = useLocation();

  const routeToPath = () => {};

  return (
    <Box>
      <Grid
        container
        spacing={2}
        sx={{
          padding: '10px',
          borderRadius: '10px',
          border: '1px solid #e4e4e4',
        }}
      >
        {routes.map((cur, index) =>
          cur.path === pathname ? (
            <Grid
              item
              xs={5}
              onClick={() => routeToPath(cur.path)}
              aria-hidden="true"
              className="active"
              key={cur.path}
              sx={{
                paddingLeft: { xs: '3px !important', sm: '16px !important' },
                borderRight:
                  index === routes.length - 1 ? 0 : '1px solid #e4e4e4',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <IconButton
                  disableFocusRipple
                  disableRipple
                  sx={{
                    color: '#FFFFFF',
                    backgroundColor: '#5c70cd',
                    cursor: 'default',
                  }}
                >
                  {cur.icon}
                </IconButton>
                <Box
                  sx={{
                    marginLeft: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: 'Circular Std Medium',
                      fontSize: { xs: '12px', sm: '16px' },
                      letterSpacing: '0em',
                      marginBottom: '5px',
                      color: '#5C70CD',
                    }}
                  >
                    Step {index + 1}
                    /8
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Circular Std Medium',
                      fontSize: { xs: '12px', sm: '16px' },
                      letterSpacing: '0em',
                      marginBottom: '5px',
                      color: '#131936',
                    }}
                  >
                    {cur.name}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ) : (
            <Grid
              item
              xs={1}
              alignItems="center"
              justifyContent="center"
              onClick={() => routeToPath(cur.path)}
              aria-hidden="true"
              key={cur.path}
              sx={{
                paddingLeft: { xs: '0px !important', sm: '16px !important' },
                borderRight:
                  index === routes.length - 1 ? 0 : '1px solid #e4e4e4',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <IconButton
                  disableFocusRipple
                  disableRipple
                  sx={{
                    paddingLeft: { xs: '3px !important', sm: '8px !important' },
                    color: '#c0c7e4',
                    cursor: 'default',
                  }}
                >
                  {cur.icon}
                </IconButton>
              </Box>
              <Divider orientation="vertical" variant="middle" flexItem />
            </Grid>
          )
        )}
      </Grid>
      {/* <Stack
        sx={{ display: { xs: 'none', sm: 'flex' } }}
        direction="row"
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        {routes.map((cur, index) =>
          cur.path === pathname ? (
            <Box
              onClick={() => routeToPath(cur.path)}
              aria-hidden="true"
              className="active"
              key={cur.path}
            >
              <section>{cur.icon}</section>

              <aside>
                <Typography className="step">
                  Step {index + 1}
                  /8
                </Typography>
                <span>{cur.name}</span>
              </aside>
            </Box>
          ) : (
            <Box
              onClick={() => routeToPath(cur.path)}
              aria-hidden="true"
              key={cur.path}
            >
              <section>{cur.icon}</section>
            </Box>
          )
        )}
      </Stack> */}
    </Box>
  );
};
export default Navigation;
