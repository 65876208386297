import {
  REGISTER_REQUEST,
  LOGIN_REQUEST,
  SET_USER_DETAILS,
  REGISTER_FAILURE,
  REGISTER_SUCCESS,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOG_OUT,
} from '../actionTypes';

const initialState = {
  isLoggingIn: false,
  user: {},
  lastLogin: null,
};

export function authReducer(state = { ...initialState }, { type, payload }) {
  //  Auth
  switch (type) {
    case REGISTER_REQUEST:
      return {
        ...state,
        isLoggingIn: true,
      };

    case LOGIN_REQUEST:
      return {
        ...state,
        isLoggingIn: true,
      };

    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggingIn: true,
        lastLogin: Date.now(),
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggingIn: true,
        lastLogin: Date.now(),
      };

    case REGISTER_FAILURE:
      return {
        ...state,
        isLoggingIn: false,
        user: {},
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        isLoggingIn: false,
        user: {},
      };

    case LOG_OUT:
      return initialState;

    case SET_USER_DETAILS:
      return {
        ...state,
        user: payload,
      };

    default:
      return state;
  }
}
