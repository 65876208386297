import React from 'react';
import {
  Section,
  SectionBody,
  SectionHeading,
  SectionSubheading,
  Paragraph,
  List,
  ListItem,
} from '../styles';

const Copyright = () => (
  <Section>
    <SectionHeading>Copyright Policy</SectionHeading>
    <SectionBody>
      <SectionSubheading>
        Intellectual Property Infringement
      </SectionSubheading>
      <Paragraph>
        We respect the intellectual property rights of others. It is Our policy
        to respond to any claim that Content posted on the Service infringes a
        copyright or other intellectual property infringement of any person.
      </Paragraph>
      <Paragraph>
        If You are a copyright owner, or authorized on behalf of one, and You
        believe that the copyrighted work has been copied in a way that
        constitutes copyright infringement that is taking place through the
        Service, you must submit Your notice in writing to the attention via
        email info@rezzie.co and include in Your notice a detailed description
        of the alleged infringement.
      </Paragraph>
      <Paragraph>
        You may be held accountable for damages (including costs and
        attorneys&apos; fees) for misrepresenting that any Content is infringing
        Your copyright.
      </Paragraph>
    </SectionBody>
    <SectionBody>
      <SectionSubheading>
        DMCA Notice and DMCA Procedure for Copyright Infringement Claims
      </SectionSubheading>
      <Paragraph>
        You may submit a notification pursuant to the Digital Millennium
        Copyright Act (DMCA) by providing our Copyright Agent with the following
        information in writing (see 17 U.S.C 512(c)(3) for further detail):
      </Paragraph>
      <List>
        <ListItem>
          An electronic or physical signature of the person authorized to act on
          behalf of the owner of the copyright&apos;s interest.
        </ListItem>
        <ListItem>
          A description of the copyrighted work that You claim has been
          infringed, including the URL (i.e., web page address) of the location
          where the copyrighted work exists or a copy of the copyrighted work.
        </ListItem>
        <ListItem>
          Identification of the URL or other specific location on the Service
          where the material that You claim is infringing is located.
        </ListItem>
        <ListItem>Your address, telephone number, and email address.</ListItem>
        <ListItem>
          A statement by You that You have a good faith belief that the disputed
          use is not authorized by the copyright owner, its agent, or the law.
        </ListItem>
        <ListItem>
          A statement by You, made under penalty of perjury, that the above
          information in Your notice is accurate and that You are the copyright
          owner or authorized to act on the copyright owner&apos;s behalf.
        </ListItem>
      </List>
      <Paragraph>
        You can contact us via email, <a href="mailto:info@rezzie.co.">info@rezzie.co.</a> Upon receipt of a
        notification, the Company will take whatever action, in its sole
        discretion, it deems appropriate, including removal of the challenged
        content from the Service.
      </Paragraph>
    </SectionBody>
  </Section>
);

export default Copyright;