import React from 'react';
import {
  Section,
  SectionHeading,
  Paragraph,
  SectionBody
} from '../styles';

const WebsiteLinks = () => (
  <Section>
    <SectionHeading>Links to Other Websites</SectionHeading>
    <SectionBody>
      <Paragraph>
        Our Service may contain links to other websites that are not operated by
        Us. If You click on a third party link, You will be directed to that
        third party&apos;s site. We strongly advise You to review the Privacy Policy
        of every site You visit.
      </Paragraph>
      <Paragraph>
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </Paragraph>
    </SectionBody>
  </Section>
);

export default WebsiteLinks;
