import React from 'react';
import { useTranslation } from 'react-i18next';
import Heading from './components/Heading&Caption';
import Wrapper from './layout/Wrapper';
import ContactForm from './components/forms/contact/ContactForm';

const Onboarding = () => {
  const { t, i18n } = useTranslation();

  return (
    <Wrapper>
      <Heading
        caption={t('Contact Information')}
        skipBtn
        smallCaption={t('Fill Contact Information')}
      />
      {/* Contact Form */}
      <ContactForm formShown />
    </Wrapper>
  );
};

export default Onboarding;
