import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { GOTO_NEXT_ONBOARDINGROUTE } from '../../../redux/actionTypes';

const OnboardingHeading = styled.section`
  display: flex;
  flex-direction: column;
  .paragraph_caption {
    font-family: "Circular Std Bold";
    font-size: 32px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    color: ${({ theme }) => theme.baseHeadingDark};
    margin-top: 57px;
    margin-bottom: 10px;
    text-align: ${({ centerHeading }) => (centerHeading ? 'center' : 'left')};

    .skip-button {
      font-family: "Circular Std Book";
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 0em;
      float: right;
      color: ${({ theme }) => theme.baseLightBlue};
      cursor: pointer;
    }
  }

  small {
    font-family: "Circular Std Book";
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0em;
    color: ${({ theme }) => theme.baseHeadingDark};
    text-align: ${({ centerHeading }) => (centerHeading ? 'center' : 'left')};
  }
`;

const Heading = ({
  caption, skipBtn, smallCaption, centerHeading,
}) => {
  const dispatch = useDispatch();

  /* handle Skip button click */
  const nextRoute = () => dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
  const nextRouteOnKey = (e) => (e.key === 'Enter') && dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });

  return (
    <OnboardingHeading centerHeading={centerHeading}>
      <p className="paragraph_caption">
        {caption && caption}
        {skipBtn && (
          <span role="button" tabIndex={0} className="skip-button" onClick={nextRoute} onKeyPress={nextRouteOnKey}>
            Skip this
          </span>
        )}
      </p>
      <small>{smallCaption && smallCaption}</small>
      <br />
      <br />
    </OnboardingHeading>
  );
};

export default Heading;
