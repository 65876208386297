import React from 'react';
import {
  Section,
  SectionHeading,
  Paragraph,
  SectionBody
} from '../styles';

const PrivacyChanges = () => (
  <>
    <Section>
      <SectionHeading>Changes to this Privacy Policy</SectionHeading>
      <SectionBody>
        <Paragraph>
          We will let You know via email and/or a prominent notice on Our
          Service, prior to the change becoming effective and update the
          &quot;Last updated&quot; date at the top of this Privacy Policy.
        </Paragraph>
        <Paragraph>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </Paragraph>
      </SectionBody>
    </Section>
    <Section>
      <SectionHeading>Contact Us</SectionHeading>
      <SectionBody>
        <Paragraph>
          If you have any questions about this Privacy Policy, You can contact
          us: By email: <a href="mailto:info@rezzie.co">info@rezzie.co</a>
        </Paragraph>
      </SectionBody>
    </Section>
  </>
);

export default PrivacyChanges;
