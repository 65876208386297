import React from 'react';
import Wrapper from './layout/Wrapper';
import Heading from './components/Heading&Caption';
import VolunteerForm from './components/forms/volunteer/VolunteerForm';

const Volunteer = () => (
  <Wrapper>
    <Heading
      caption="Volunteer History"
      skipBtn
      smallCaption="Do you have any experience volunteering? This could be really helpful."
    />
    <VolunteerForm formShown />
  </Wrapper>
);

export default Volunteer;
