import React from 'react';
import styled from 'styled-components';

const ResumeContainer = styled.div`
  background: #666f82;
  padding: 2rem;
  height: 100%;
  line-height: 1.5;
  & .resume-body {
    background: #fff;
    border-radius: 4px;
    padding: 2rem;
    height: 100%;
    min-height: 500px;
    & .resume-main--content {
      display: flex;
      border-top: 1px solid #666f82;
      height: 90%;
    }
    & .name,
    .section-title,
    .section-subtitle {
      text-transform: uppercase;
      letter-spacing: 1px;
    }
    & .section-title {
      font-size: 0.75rem;
      margin-bottom: 10px;
      &:after {
        margin-top: 2px;
        content: '';
        display: block;
        height: 2px;
        width: 20px;
        background: #131936;
      }
    }
    & .section-subtitle {
      font-size: 0.5rem;
    }

    & .contact-details {
      padding: 1rem 1rem 0 0;
      border-right: 1px solid #666f82;
    }
    & .profile {
      & p {
        border-bottom: 1px solid #666f82;
      }
    }
    & .profile,
    .employment {
      padding: 1rem 0 0 1rem;
    }
  }
  & .name {
    margin-bottom: 1rem;
  }
  & .address,
  .name {
    & span {
      display: block;
    }
  }
`;

const Paragraph = styled.p`
  font-size: 0.5rem;
  padding-bottom: 2rem;
`;

export default function LiveResumeEdit({
  firstName,
  lastName,
  city,
  country,
  state,
  profile,
}) {
  return (
    <ResumeContainer>
      <div className='resume-body'>
        <h2 className='name'>
          <span>{firstName}</span>
          <span>{lastName}</span>
        </h2>
        <div className='resume-main--content'>
          <div className='contact-details'>
            <h4 className='section-title'>Details</h4>
            <h5 className='section-subtitle'>Address</h5>
            <Paragraph className='address'>
              {!!city && <span>{city},</span>}
              {!!state && <span>{state},</span>}
              {!!country && <span>{country}</span>}
            </Paragraph>
          </div>
          <div>
            <div className='profile'>
              <h4 className='section-title'>Profile</h4>
              <p>{profile}</p>
            </div>
            <div className='employment'>
              <h4 className='section-title'>Employment History</h4>
              <Paragraph>{profile}</Paragraph>
            </div>
          </div>
        </div>
      </div>
    </ResumeContainer>
  );
}
