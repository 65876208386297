import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  padding: 0 8px;
  border-radius: 2px;
  border: 1px solid #a7a9bc;

  font-family: 'Circular Std Medium';
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0em;
  color: #6b6c7e;
  margin-right: 10px;
  svg {
    margin-left: 15px;
    cursor: pointer;
  }
`;
const SinglePublication = ({
  values,
  PublicationIndex,
  touched,
  setAddAuthor,
  addAuthor,
  handleChange,
  initialValues,
  setinitialValues,
  indexToSubmit,
  setIndexToSubmit,
  isFormRequesting,
}) => {
  const removePublication = (pI, index, vd) => {
    const getArrayToUpdate = vd[pI];
    const updatedPublications = getArrayToUpdate.allAuthor.filter(
      (ft, indexc) => indexc !== index
    );
    vd[pI].allAuthor = updatedPublications;
    setinitialValues(vd);
  };
  const addPublication = (e, i, vd) => {
    if (vd[i].author !== '') {
      if (vd[i].allAuthor === null) {
        vd[i].allAuthor = [];
        const updatedPublications = [...vd[i].allAuthor, vd[i].author];
        // console.log(updatedPublications);
        vd[i].allAuthor = updatedPublications;
        vd[i].author = '';
        setinitialValues(vd);
      } else {
        const updatedPublications = [...vd[i].allAuthor, vd[i].author];
        // console.log(updatedPublications);
        vd[i].allAuthor = updatedPublications;
        vd[i].author = '';
        setinitialValues(vd);
      }
    }
  };
  const updateArray = (e) => {
    const caret = e.target.selectionStart;
    const element = e.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });
    const newArr = [...initialValues]; // copying the old datas array
    if (e.target.type === 'checkbox') {
      newArr[PublicationIndex] = {
        ...values[PublicationIndex],
        [e.target.name]: e.target.checked,
      }; // replace e.target.value with whatever you want to change it to
    } else
      newArr[PublicationIndex] = {
        ...values[PublicationIndex],
        [e.target.name]: e.target.value,
      }; // replace e.target.value with whatever you want to change it to

    setinitialValues(newArr);
  };
  return (
    <div className="single__Publication_wrapper">
      <label>
        <span className="important-field">Title</span>
        <br />
        <input
          type="text"
          name="title"
          value={values[PublicationIndex]?.title}
          className={
            values[PublicationIndex]?.title?.length === 0 &&
            touched[PublicationIndex] &&
            'rez_input_error'
          }
          required
          placeholder="E.g Global Warming"
          onChange={updateArray}
        />
      </label>
      <label>
        <span className="important-field">Publication/Publisher</span>
        <br />
        <input
          type="text"
          placeholder="E.g Microsoft"
          name="publisher"
          value={values[PublicationIndex]?.publisher}
          className={
            values[PublicationIndex]?.publisher?.length === 0 &&
            touched[PublicationIndex]
              ? 'rez_input_error'
              : ''
          }
          onChange={updateArray}
          required
        />
      </label>

      <label>
        <span className="important-field">Publication Date</span>
        <br />
        <input
          type="date"
          name="publicationDate"
          className={
            values[PublicationIndex]?.publicationDate?.length === 0 &&
            touched[PublicationIndex]
              ? 'rez_input_error'
              : ''
          }
          onChange={updateArray}
          value={
            values[PublicationIndex]?.publicationDate &&
            new Date(values[PublicationIndex].publicationDate)
              .toISOString()
              .substring(0, 10)
          }
          required
        />
      </label>

      {addAuthor ? (
        <>
          <label>
            <input
              type="text"
              placeholder="e.g John Doe"
              name={`[${PublicationIndex}].author`}
              onChange={handleChange}
              value={values[PublicationIndex]?.author}
              onKeyDown={(e) =>
                e.key === 'Enter' && addPublication(e, PublicationIndex, values)
              }
            />
          </label>
        </>
      ) : null}
      <br />
      {values[PublicationIndex]?.allAuthor !== null &&
        values[PublicationIndex]?.allAuthor.length > 0 && (
          <label>
            Authors
            <br />
            {values[PublicationIndex]?.allAuthor?.map((item, index) => (
              <Card key={`${Date.now()}__${item}`}>
                {item}
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() =>
                    removePublication(PublicationIndex, index, values)
                  }
                >
                  <path
                    d="M5.72515 5.01764L9.37144 1.37136C9.56686 1.17593 9.56686 0.859361 9.37144 0.664488C9.17601 0.469064 8.85942 0.469064 8.66457 0.664488L5.01828 4.31078L1.37199 0.663902C1.17714 0.468478 0.859995 0.468478 0.665122 0.663902C0.469699 0.859325 0.469699 1.1759 0.665122 1.37077L4.31141 5.01764L0.665122 8.66393C0.469699 8.85936 0.469699 9.17595 0.665122 9.3708C0.762265 9.46908 0.890273 9.51766 1.01826 9.51766C1.14625 9.51766 1.27426 9.46908 1.37199 9.37137L5.01828 5.7245L8.66457 9.37078C8.7623 9.46851 8.89029 9.51708 9.01828 9.51708C9.14627 9.51708 9.27428 9.4685 9.37199 9.37078C9.56741 9.17536 9.56741 8.85877 9.37199 8.66392L5.72511 5.01763L5.72515 5.01764Z"
                    fill="#272833"
                  />
                </svg>
              </Card>
            ))}
          </label>
        )}
      <br />
      <label>
        {addAuthor
          ? ''
          : 'You are an author. Add others that contributed to this publication'}
        <br /> <br />
        <span
          role="button"
          tabIndex="-9"
          className="textarea_add_experience"
          onClick={() => setAddAuthor(!addAuthor)}
          onKeyPress={(e) => e.key === 'Enter' && setAddAuthor(!addAuthor)}
        >
          <Plus />
          &nbsp;&nbsp; {addAuthor ? 'Remove' : 'Add'}{' '}
          {addAuthor ? 'authors' : 'author'}
        </span>
        <br /> <br />
      </label>

      <label>
        Publication Url
        <br />
        <input
          type="text"
          placeholder="E.g Microsoft"
          name="publicationUrl"
          value={values[PublicationIndex]?.publicationUrl}
          onChange={updateArray}
          className={
            values[PublicationIndex]?.publicationUrl?.length === 0 &&
            touched[PublicationIndex] &&
            'rez_input_error'
          }
        />
      </label>

      <label>
        Description
        <br />
        <textarea
          type="text"
          placeholder="E.g Microsoft"
          name="description"
          value={values[PublicationIndex]?.description}
          className={
            values[PublicationIndex]?.description?.length === 0 &&
            touched[PublicationIndex] &&
            'rez_input_error'
          }
          onChange={updateArray}
        />
      </label>

      {/* {values.length > 1 && (
      <div>
        <button
          type="submit"
          className="form_next_btn"
          onClick={() => setIndexToSubmit(PublicationIndex)}
          disabled={isFormRequesting && indexToSubmit === PublicationIndex}
        >
          {isFormRequesting && indexToSubmit === PublicationIndex
            ? 'Saving...'
            : 'Save Changesd'}
        </button>
        <div className="clearfix" />
      </div> */}
      {/* )} */}

      <button
        type="submit"
        className="form_next_btn"
        onClick={() => setIndexToSubmit(PublicationIndex)}
        disabled={isFormRequesting && indexToSubmit === PublicationIndex}
      >
        {isFormRequesting && indexToSubmit === PublicationIndex
          ? 'Saving...'
          : 'Save Changes'}
      </button>
    </div>
  );
};

export default SinglePublication;

const Plus = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9944 7.00012H8.98156V1.00293C8.98156 0.45293 8.53156 0.00292969 7.98156 0.00292969C7.43156 0.00292969 6.98156 0.45293 6.98156 1.00293V7.00012H1C0.45 7.00012 0 7.45012 0 8.00012C0 8.55012 0.45 9.00012 1 9.00012H6.98156V14.9973C6.98156 15.5473 7.43156 15.9973 7.98156 15.9973C8.53156 15.9973 8.98156 15.5473 8.98156 14.9973V9.00015H14.9944C15.5444 9.00015 15.9944 8.55015 15.9944 8.00015C15.9944 7.45015 15.5444 7.00015 14.9944 7.00015V7.00012Z"
      fill="#5C70CD"
    />
  </svg>
);
