import React from 'react';
import Wrapper from './layout/Wrapper';
import Heading from './components/Heading&Caption';
import LicenseForm from './components/forms/licenseandcertification/LicenseForm';

const Licenses = () => (
  <Wrapper>
    <Heading
      caption="Licenses and Certifications"
      skipBtn
      smallCaption="Do you have any licenses and/or certifications?"
    />
    <LicenseForm formShown />
  </Wrapper>
);

export default Licenses;
