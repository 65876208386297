import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Box,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import apiClient from '../../api/server';
import { SET_ACTIVE_TEMPLATE } from '../../redux/actionTypes';

const TemplateViewer = ({ open, close, user, templateId }) => {
  const [image, setImage] = useState('');
  const dispatch = useDispatch();

  const getPreview = async () => {
    const res = await apiClient.getTemplatePreview(user.id, templateId);
    if (res.status) {
      setImage(res.data);
    }
  };

  useEffect(() => {
    getPreview();
  }, []);

  const setTemplate = async () => {
    dispatch({
      type: SET_ACTIVE_TEMPLATE,
      payload: templateId,
    });

    const res = await apiClient.getTemplatePdf(user.id, templateId);

    if (res.status) {
      console.log(res);
      const a = document.createElement('a');
      a.href = res.data;
      a.download = 'resume.pdf';
      a.click();
      toast.success('Template Selected successfully');
      close();
    }
  };

  console.log(setTemplate);

  return (
    <Dialog open={open} keepMounted onClose={close}>
      <DialogTitle sx={{ marginBottom: 5 }}>
        {image && (
          <IconButton
            aria-label="close"
            onClick={close}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        {image && (
          <Box
            sx={{
              maxWidth: 900,
            }}
          >
            <img style={{ width: '100%' }} src={image} alt="" />
          </Box>
        )}
        {!image && <CircularProgress />}
      </DialogContent>
    </Dialog>
  );
};

export default TemplateViewer;
