import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import resumeBuilderImg from '../../../../assets/images/resume-review.jpeg';
import coverletterImg from '../../../../assets/images/cover-letter-review.svg';
import jobBoardImg from '../../../../assets/images/job-board.svg';
import { CustomCard} from './styles'

function Aboutpage() {
  const { t, i18n } = useTranslation();
  return (
    <Box
      as="section"
      className=" aboutpage"
      sx={{
        margin: '7rem 0',
        textAlign: 'center',
        padding: {
          xs: '2rem 1.25rem 0.8rem',
          sm: '4rem 2.5rem 1.7rem',
        },
      }}
    >
      <Typography
        sx={{ fontWeight: '600', fontSize: '35px', lineHeight: '40px' }}
      >
        {t('Browse through')}
      </Typography>

      <Grid container spacing={4} sx={{ marginTop: '0' }}>
        <Grid className="feature-card" item sm={4} position="relative">
          <CustomCard
            title="Resume and Cover Letter Builder"
            img={resumeBuilderImg}
            alt="resume builder"
            text="Our resume and cover letter builder provides templates, tools, and proven tips for building winning resumes and cover letters in diverse fields."
            action="Try for free"
            link="/resumeandcoverletterbuilder/login"
          />
        </Grid>

        <Grid item sm={4} sx={{ marginTop: '20px' }}>
          <CustomCard
            title={t('Resume and Cover Letter Review')}
            img={coverletterImg}
            imgAlt="cover letter"
            text={t('Get your resume')}
            action={t('Coming soon')}
            link=""
          />
        </Grid>
        <Grid item sm={4} sx={{ marginTop: '20px' }}>
          <CustomCard
            title={t('Job Board')}
            img={jobBoardImg}
            imgAlt="Job board"
            text={t('Create job post')}
            action={t('Coming soon')}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Aboutpage;
