import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import useSWR from 'swr';
import produce from 'immer';
import styled from 'styled-components';
import apiClient from '../../../../../api/server';
import { postSkills } from '../../../../../redux/actions';
import FormWrapper from '../../FormWrapper';
import { GOTO_NEXT_ONBOARDINGROUTE } from '../../../../../redux/actionTypes';

const Card = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  min-height: 24px;
  padding: 0 8px;
  border-radius: 2px;
  border: 1px solid #a7a9bc;

  font-family: 'Circular Std Medium';
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0em;
  color: #6b6c7e;
  margin-right: 10px;
  margin-bottom: 10px;
  svg {
    margin-left: 15px;
    cursor: pointer;
  }
`;
const Skillform = ({ noFooter, formShown }) => {
  /* Get current user */
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  /* Get skills from backend */
  const { data, mutate } = useSWR(
    () => `/users/${user.id}/skills`,
    () => apiClient.getSkills(user.id)
  );
  const [initialValues, setinitialValues] = useState({
    allSkills: [],
    professionalSkills: [],
    singleSkill: '',
    proSkill: '',
  });

  useEffect(() => {
    if (data?.data && data?.data?.length > 0) {
      const userSkills = data?.data.filter((i) => i.type === 'professional')[0]
        ?.skills;
      const otherSkills = data?.data?.filter((i) => i.type === 'others')[0]
        ?.skills;
      setinitialValues((i) =>
        produce(i, (draft) => {
          draft.professionalSkills = userSkills || [];
          draft.allSkills = otherSkills || [];
        })
      );
    }
  }, [data]);

  /**
   * add a skill
   *
   * @function
   * @param {Array} payload The skills to be sent
   * @return {void}
   */
  const removeDuplicate = (dt) => [...new Set(dt)];

  const addSkill = (values) => {
    // console.log(values);
    if (values.singleSkill !== '') {
      const newSkills = values.singleSkill.split(',');
      const updatedSkills = {
        type: 'others',
        skills: initialValues.allSkills.length
          ? removeDuplicate([...initialValues.allSkills, ...newSkills])
          : newSkills,
      };
      dispatch(postSkills(user.id, updatedSkills));
    }
    setTimeout(() => mutate(), 1500);
  };

  const addProSkill = (values) => {
    // console.log(values);

    if (values.proSkill !== '') {
      const newSkills = values.proSkill.split(',');
      const updatedSkills = {
        type: 'professional',
        skills: initialValues.professionalSkills.length
          ? removeDuplicate([...initialValues.professionalSkills, ...newSkills])
          : newSkills,
      };

      dispatch(postSkills(user.id, updatedSkills));
    }
    setTimeout(() => mutate(), 1500);
  };

  /**
   * remove a skill
   *
   * @function
   * @param {index} payload The index to be removed
   * @return {void}
   */
  const removeSkill = (item) => {
    const updatedSkills = initialValues?.allSkills.filter((x) => x !== item);
    setinitialValues({ ...initialValues, allSkills: updatedSkills });
    dispatch(postSkills(user.id, { skills: updatedSkills, type: 'others' }));
  };
  const removeProSkill = (item) => {
    const updatedSkills = initialValues?.professionalSkills.filter(
      (x) => x !== item
    );
    setinitialValues({ ...initialValues, professionalSkills: updatedSkills });
    dispatch(
      postSkills(user.id, { skills: updatedSkills, type: 'professional' })
    );
  };

  const handleKeyDown = (e, values, type) => {
    if (e.key === 'Enter' && values) {
      if (type === 'Professional') {
        addProSkill(values);
      } else if (type === 'Other') {
        addSkill(values);
      }
    }
  };

  // console.log(initialValues);
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={() => dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE })}
    >
      {({ values, handleChange, handleSubmit }) => (
        <FormWrapper
          formShown={formShown}
          noFooter={noFooter}
          handleSubmit={handleSubmit}
        >
          <label>
            Professional Skills
            <br />
            <input
              type="text"
              placeholder="e.g Excel, Javascript, Photoshop"
              name="proSkill"
              onChange={handleChange}
              value={values.proSkill}
              onKeyDown={((e) => handleKeyDown(e, values), 'Professional')}
            />
          </label>
          <div>
            <button
              type="submit"
              className="form_next_btn"
              onClick={(e) => {
                e.preventDefault();
                addProSkill(values);
              }}
            >
              Add
            </button>
            <div className="clearfix" />
          </div>
          <br />
          <br />

          {values?.professionalSkills?.map((item) => (
            <Card key={Math.random()}>
              {item}
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => {
                  removeProSkill(item);
                }}
              >
                <path
                  d="M5.72515 5.01764L9.37144 1.37136C9.56686 1.17593 9.56686 0.859361 9.37144 0.664488C9.17601 0.469064 8.85942 0.469064 8.66457 0.664488L5.01828 4.31078L1.37199 0.663902C1.17714 0.468478 0.859995 0.468478 0.665122 0.663902C0.469699 0.859325 0.469699 1.1759 0.665122 1.37077L4.31141 5.01764L0.665122 8.66393C0.469699 8.85936 0.469699 9.17595 0.665122 9.3708C0.762265 9.46908 0.890273 9.51766 1.01826 9.51766C1.14625 9.51766 1.27426 9.46908 1.37199 9.37137L5.01828 5.7245L8.66457 9.37078C8.7623 9.46851 8.89029 9.51708 9.01828 9.51708C9.14627 9.51708 9.27428 9.4685 9.37199 9.37078C9.56741 9.17536 9.56741 8.85877 9.37199 8.66392L5.72511 5.01763L5.72515 5.01764Z"
                  fill="#272833"
                />
              </svg>
            </Card>
          ))}
          <br />
          <br />
          <br />
          <label>
            Other Skills
            <br />
            <input
              type="text"
              placeholder="e.g Data Analysis, Management, Recruitment"
              name="singleSkill"
              onChange={handleChange}
              value={values.singleSkill}
              onKeyDown={(e) => handleKeyDown(e, values, 'Other')}
            />
          </label>
          <div>
            <button
              type="submit"
              className="form_next_btn"
              onClick={(e) => {
                e.preventDefault();
                addSkill(values);
              }}
            >
              Add
            </button>
            <div className="clearfix" />
          </div>
          <br />

          {values?.allSkills?.map((item) => (
            <Card key={Math.random()}>
              {item}
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => {
                  removeSkill(item);
                }}
              >
                <path
                  d="M5.72515 5.01764L9.37144 1.37136C9.56686 1.17593 9.56686 0.859361 9.37144 0.664488C9.17601 0.469064 8.85942 0.469064 8.66457 0.664488L5.01828 4.31078L1.37199 0.663902C1.17714 0.468478 0.859995 0.468478 0.665122 0.663902C0.469699 0.859325 0.469699 1.1759 0.665122 1.37077L4.31141 5.01764L0.665122 8.66393C0.469699 8.85936 0.469699 9.17595 0.665122 9.3708C0.762265 9.46908 0.890273 9.51766 1.01826 9.51766C1.14625 9.51766 1.27426 9.46908 1.37199 9.37137L5.01828 5.7245L8.66457 9.37078C8.7623 9.46851 8.89029 9.51708 9.01828 9.51708C9.14627 9.51708 9.27428 9.4685 9.37199 9.37078C9.56741 9.17536 9.56741 8.85877 9.37199 8.66392L5.72511 5.01763L5.72515 5.01764Z"
                  fill="#272833"
                />
              </svg>
            </Card>
          ))}
          <br />
          <br />
          <br />
          <br />
          <br />
        </FormWrapper>
      )}
    </Formik>
  );
};

export default Skillform;
