import React from 'react';
import { Grid } from '@mui/material';
import { withTranslation } from 'react-i18next';
import { CoverDiv, MoreDescription } from '../../screens/auth/styles';

const AuthLayout = ({ t }) => (
  <Grid sx={{ display: { xs: 'none', md: 'flex' } }} item xs={12} md={6}>
    <CoverDiv>
      <p className="cover-caption">{t('Login Header')}</p>
      <p className="cover-description">
        {t('Join us')}
        <br />
        <br />
        {t('Find help')}
      </p>
      <MoreDescription>
        <span>💼&nbsp;&nbsp;&nbsp;{t('Like Professionals')}</span>

        <br />
        <br />
        <span>
          🚀&nbsp;&nbsp;&nbsp;{t('Tools')} <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {t('Including yours')}
        </span>
        <br />
        <br />
        <span>😉&nbsp;&nbsp;&nbsp;{t('Recruitment team')}</span>
        <br />
        <br />
        <span>
          💃🏼&nbsp;&nbsp;&nbsp;{t('All for')} <strong>{t('FREE')}</strong>!
        </span>
      </MoreDescription>
    </CoverDiv>
  </Grid>
);

export default withTranslation()(AuthLayout);
