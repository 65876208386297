import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Formik, getIn } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';
import {
  Container,
  InputBase,
  Box,
  CircularProgress,
  FormControl,
  TextField,
  Button,
  IconButton,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import apiClient from '../../../../../api/server';

const MainContent = styled.main`
  width: 100%;
  max-width: 775px;

  .title {
    font-family: 'Circular Std Bold';
    font-size: 32px;
    line-height: 32px;
    letter-spacing: 0em;
    /* text-align: left; */
    color: #131936;
    margin-bottom: 10px;
  }

  .description {
    font-family: 'Circular Std Book';
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0em;
    /* text-align: left; */
    color: #131936;
    margin-bottom: 30px;
  }

  .form-container {
    padding: 0px;
    width: 100%;
  }

  .input-title {
    margin-bottom: 20px;
    font-family: 'Circular Std Bold' !important;
    font-style: normal;
    font-size: 16px !important;
    color: #272833;
  }

  .input-label {
    margin-bottom: 10px;
    font-family: 'Circular Std Book' !important;
    font-style: normal;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #272833;
  }

  .tip-section {
    display: flex;
    flex-direction: row;
    margin-top: 80px;
  }

  .tip-img-section {
    flex: 1;
  }

  .tip-content-section {
    flex: 14;
  }

  .tip-icon {
    width: 34px;
  }

  .tip-title {
    font-family: 'Circular Std Bold';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }

  .tip-description {
    font-family: 'Circular Std Book';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
  }

  .words-left {
    font-family: 'Circular Std Book';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #838383;
  }

  .button-section {
    margin-top: 40px;
    text-align: right;
  }
  .inline {
    margin-right: 45px;
    display: inline;

    :last-child {
      margin-right: 0px;
    }
  }

  .add-achievement-section {
    width: 100%;
    text-align: right;
  }

  .add-item-section {
    width: 100%;
    text-align: center;
  }

  .form_next_btn {
    border-radius: 4px;
    background: #5c70cd;
    color: white;
    font-family: Circular Std Book !important;
    text-transform: none !important;

    :hover {
      background: #5c70cd;
    }
  }

  .checkbox {
    .MuiFormControlLabel-label {
      font-family: 'Circular Std Book' !important;
      font-style: normal;
      font-size: 14px !important;
      font-weight: 500 !important;
    }
  }

  .back-button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  .input-section {
    margin-bottom: 25px;
  }

  .achievement-section {
    position: relative;
    margin-bottom: 25px;
    border: 1px solid #5c70cd;
    border-radius: 4px;
    padding: 5%;
    background: rgba(92, 112, 205, 0.01);
  }

  .loading-container {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
  }

  .item-section {
    position: relative;
    border: 1px solid #5c70cd;
    border-radius: 4px;
    padding: 4% 5% 5% 5%;
    margin-bottom: 25px;
    background: rgba(92, 112, 205, 0.05);
  }

  .Mui-error {
    border-radius: 4px;
    border: 1px solid red;
  }

  .error-text {
    color: red;
    font-size: 12px;
    padding: 5px;
  }

  .date-input {
    background-color: #f1f2f5 !important;
    .MuiOutlinedInput-input {
      font-family: Circular Std Book !important;
      width: 100% !important;
      font-size: 16px !important;
      color: rgb(39, 40, 51) !important;
    }
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #e7e7ed;
    }
  }

  .delete-button {
    position: absolute;
    right: 10px;
    top: 10px;
    color: red !important;
  }

  .remove-text {
    font-size: 14px !important;
    font-family: Circular Std Medium !important;
    margin-top: 3px;
  }
`;

// const CustomTextArea = styled(InputBase)(() => ({
//   'label + &': {
//     marginTop: 40,
//   },
//   '& .MuiInputBase-input': {
//     borderRadius: 4,
//     position: 'relative',
//     backgroundColor: '#F1F2F5',
//     border: '1px solid #E7E7ED',
//     fontSize: 16,
//     width: '100%',
//     padding: '10px 12px',
//     color: 'rgb(39, 40, 51)',
//   },
//   fontFamily: 'Circular Std Book !important',
//   '&:focus': {
//     borderColor: 'rgba(92, 112, 205, 0.13)',
//   },
// }));

const BootstrapInput = styled(InputBase)(() => ({
  'label + &': {
    marginTop: 40,
  },

  '& .MuiInputBase-root': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#F1F2F5',
    border: '1px solid #E7E7ED',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    fontFamily: 'Circular Std Book !important',
    '&:focus': {
      borderColor: 'rgba(92, 112, 205, 0.13)',
    },
  },
  '& .MuiInputBase-input': {
    fontFamily: 'Circular Std Book !important',
    width: '100%',
    color: 'rgb(39, 40, 51)',
  },
}));

// const BootstrapSelectInput = styled(InputBase)(() => ({
//   'label + &': {
//     marginTop: 40,
//   },
//   '.MuiInputBase-root': {
//     fontFamily: 'Circular Std Book !important',
//   },
//   '& .MuiInputBase-input': {
//     borderRadius: 4,
//     position: 'relative',
//     backgroundColor: '#F1F2F5',
//     border: '1px solid #E7E7ED',
//     fontSize: 16,
//     width: '100%',
//     padding: '10px 12px',
//     fontFamily: 'Circular Std Book !important',
//     '&:focus': {
//       borderColor: 'rgba(92, 112, 205, 0.13)',
//     },
//   },
// }));

const TestScores = ({ user }) => {
  const formRef = useRef();
  const [data, setData] = useState([
    {
      id: '',
      index: 0,
      title: '',
      score: '',
      testDate: moment().toDate(),
      associatedWith: '',
      description: '',
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    const response = await apiClient.getTestScore(user.id);
    if (response.status) {
      response.data.forEach((item, index) => {
        item.index = index;
        Object.keys(item).forEach((key) => {
          if (item[key] == null) {
            item[key] = '';
          }
        });
      });

      if (response.data.length > 0) {
        setData(response.data);
        console.log(response.data);
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const validationSchema = yup.array().of(
    yup.object().shape({
      title: yup.string('Enter the name').required('Title is required'),
      associatedWith: yup
        .string('Enter the Associated With')
        .required('Associated With is required'),
      testDate: yup
        .string('Enter the Publication Date')
        .required('Test Date is required'),
      score: yup.string('Enter the Description').required('Score is required'),
    })
  );

  const handleFormSubmit = async (values, setSubmitting) => {
    const response = await apiClient.postTestScore(user.id, values);

    if (response.status) {
      toast.success('Update Successful');
      setSubmitting(false);
    } else {
      toast.error(response.message);
      setSubmitting(false);
    }
  };

  const handleAddEntry = (setFieldValue) => {
    const array = formRef.current.values;
    array.push({
      id: '',
      index: array.length,
      title: '',
      score: '',
      testDate: moment().toDate(),
      associatedWith: '',
      description: '',
    });
    setFieldValue(``, array, false);
  };

  const handleDeleteEntry = (index, setFieldValue) => {
    const array = formRef.current.values;
    array.splice(index, 1);
    setFieldValue(``, array, false);
  };

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, []);

  return (
    <MainContent>
      {!isLoading && (
        <Box>
          <Container id="form-section" className="form-container">
            <Formik
              innerRef={formRef}
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={data}
              onSubmit={(values, { setSubmitting }) => {
                handleFormSubmit(values, setSubmitting);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  {values.map((item, index) => (
                    <Box key={`${item.index}`}>
                      <Box className="item-section">
                        <p className="input-title">Test Score #{index + 1}</p>
                        <Box className="input-section">
                          <p className="input-label">Title</p>
                          <IconButton
                            aria-label="delete"
                            component="span"
                            disableFocusRipple
                            disableRipple
                            className="delete-button"
                            onClick={() =>
                              handleDeleteEntry(index, setFieldValue)
                            }
                          >
                            <DeleteIcon />
                            <span className="remove-text">Remove</span>
                          </IconButton>
                          <FormControl
                            className="form-container"
                            variant="standard"
                          >
                            <BootstrapInput
                              placeholder="Enter Title"
                              id="text-input"
                              value={item.title}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name={`[${index}].title`}
                              error={
                                getIn(errors, `[${index}].title`) &&
                                getIn(touched, `[${index}].title`)
                              }
                            />
                            {getIn(errors, `[${index}].title`) &&
                            getIn(touched, `[${index}].title`) ? (
                              <Box className="error-text">
                                {getIn(errors, `[${index}].title`)}
                              </Box>
                            ) : null}
                          </FormControl>
                        </Box>

                        <Box className="input-section">
                          <p className="input-label">Associated With</p>
                          <FormControl
                            className="form-container"
                            variant="standard"
                          >
                            <BootstrapInput
                              placeholder="E.g. Software Development"
                              id="text-input"
                              value={item.associatedWith}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name={`[${index}].associatedWith`}
                              error={
                                getIn(errors, `[${index}].associatedWith`) &&
                                getIn(touched, `[${index}].associatedWith`)
                              }
                            />
                            {getIn(errors, `[${index}].associatedWith`) &&
                            getIn(touched, `[${index}].associatedWith`) ? (
                              <Box className="error-text">
                                {getIn(errors, `[${index}].associatedWith`)}
                              </Box>
                            ) : null}
                          </FormControl>
                        </Box>

                        <Box className="input-section">
                          <p className="input-label">Score</p>
                          <FormControl
                            className="form-container"
                            variant="standard"
                          >
                            <BootstrapInput
                              placeholder="e.g. 1100"
                              id="text-input"
                              value={item.score}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name={`[${index}].score`}
                              error={
                                getIn(errors, `[${index}].score`) &&
                                getIn(touched, `[${index}].score`)
                              }
                            />
                            {getIn(errors, `[${index}].score`) &&
                            getIn(touched, `[${index}].score`) ? (
                              <Box className="error-text">
                                {getIn(errors, `[${index}].score`)}
                              </Box>
                            ) : null}
                          </FormControl>
                        </Box>

                        <Box className="input-section">
                          <p className="input-label">Test Date</p>

                          <FormControl
                            className="form-container"
                            variant="standard"
                          >
                            <MobileDatePicker
                              inputFormat="MM/YYYY"
                              views={['year', 'month']}
                              clearable
                              placeholder="Enter Test Date"
                              value={item.testDate}
                              onChange={(value) =>
                                setFieldValue(`[${index}].testDate`, value)
                              }
                              onBlur={handleBlur}
                              error={
                                getIn(errors, `[${index}].testDate`) &&
                                getIn(touched, `[${index}].testDate`)
                              }
                              name={`[${index}].testDate`}
                              renderInput={(params) => (
                                <TextField className="date-input" {...params} />
                              )}
                            />

                            {getIn(errors, `[${index}].testDate`) &&
                            getIn(touched, `[${index}].testDate`) ? (
                              <Box className="error-text">
                                {getIn(errors, `[${index}].testDate`)}
                              </Box>
                            ) : null}
                          </FormControl>
                        </Box>

                        <Box className="input-section">
                          <p className="input-label">Description</p>
                          <FormControl
                            className="form-container"
                            variant="standard"
                          >
                            <BootstrapInput
                              placeholder="Enter Description"
                              id="text-input"
                              value={item.description}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name={`[${index}].description`}
                              error={
                                getIn(errors, `[${index}].description`) &&
                                getIn(touched, `[${index}].description`)
                              }
                            />
                            {getIn(errors, `[${index}].description`) &&
                            getIn(touched, `[${index}].description`) ? (
                              <Box className="error-text">
                                {getIn(errors, `[${index}].description`)}
                              </Box>
                            ) : null}
                          </FormControl>
                        </Box>
                      </Box>
                    </Box>
                  ))}

                  <Box className="add-item-section">
                    <Button
                      variant="contained"
                      disableFocusRipple
                      disableRipple
                      disableElevation
                      className="form_next_btn"
                      onClick={() => handleAddEntry(setFieldValue)}
                      startIcon={<AddIcon />}
                    >
                      Add Test Score
                    </Button>
                  </Box>

                  <Box className="button-section">
                    {!isSubmitting && (
                      <Box>
                        <button
                          type="submit"
                          onClick={handleSubmit}
                          className="form_next_btn inline"
                        >
                          Save
                        </button>
                      </Box>
                    )}
                    {isSubmitting && <CircularProgress />}
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        </Box>
      )}
      {isLoading && (
        <Container className="loading-container">
          <CircularProgress size={60} />
        </Container>
      )}
    </MainContent>
  );
};

export default TestScores;
