import React from 'react';
import Wrapper from './layout/Wrapper';
import Heading from './components/Heading&Caption';
import AccomplishmentForm from './components/forms/accomplishments';

const Onboarding = () => (
  <Wrapper>
    <Heading caption="Have any accomplishments you’d like to add?" skipBtn />
    <AccomplishmentForm formShown name="good" />
  </Wrapper>
);

export default Onboarding;
