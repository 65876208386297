import React from 'react';
import {
  Section,
  SectionHeading,
  SectionBody,
  Paragraph,
  List,
  ListItem,
  IndentedBlock,
  BlockHeading,
  BlockBody,
} from '../styles';
import { SectionSubheading } from '../../termsAndConditions/styles';

const PersonalData = () => (
  <Section>
    <SectionHeading>Collecting and Using Your Personal Data</SectionHeading>
    <SectionHeading className="heading-two">
      Types of Data Collected
    </SectionHeading>
    <SectionBody>
      <SectionSubheading>Personal Data</SectionSubheading>
      <Paragraph>
        While using Our Service, We may ask You to provide Us with certain
        personally identifiable information that can be used to contact or
        identify You. Personally identifiable information may include, but is
        not limited to:
      </Paragraph>
      <List>
        <ListItem>Email address</ListItem>
        <ListItem>First name and last name</ListItem>
        <ListItem>Phone number</ListItem>
        <ListItem>Usage Data</ListItem>
      </List>
    </SectionBody>
    <SectionBody>
      <SectionSubheading>Usage Data</SectionSubheading>
      <Paragraph>
        Usage Data is collected automatically when using the Service.
      </Paragraph>
      <Paragraph>
        Usage Data may include information such as Your Device&apos;s Internet
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that You visit, the time and date of Your visit,
        the time spent on those pages, unique device identifiers and other
        diagnostic data.
      </Paragraph>
      <Paragraph>
        When You access the Service by or through a mobile device, We may
        collect certain information automatically, including, but not limited
        to, the type of mobile device You use, Your mobile device unique ID, the
        IP address of Your mobile device, Your mobile operating system, the type
        of mobile Internet browser You use, unique device identifiers and other
        diagnostic data.
      </Paragraph>
      <Paragraph>
        We may also collect information that Your browser sends whenever You
        visit our Service or when You access the Service by or through a mobile
        device.
      </Paragraph>
    </SectionBody>

    <SectionBody>
      <SectionSubheading>
        Information from Third-Party Social Media Services
      </SectionSubheading>
      <Paragraph>
        The Company allows You to create an account and log in to use the
        Service through the following Third-party Social Media Services:
      </Paragraph>
      <List>
        <ListItem>Google</ListItem>
        <ListItem>LinkedIn</ListItem>
      </List>
      <Paragraph>
        If You decide to register through or otherwise grant us access to a
        Third-Party Social Media Service, We may collect Personal data that is
        already associated with Your Third-Party Social Media Service&apos;s
        account, such as Your name, Your email address, Your activities or Your
        contact list associated with that account.
      </Paragraph>
      <Paragraph>
        You may also have the option of sharing additional information with the
        Company through Your Third-Party Social Media Service&apos;s account. If
        You choose to provide such information and Personal Data, during
        registration or otherwise, You are giving the Company permission to use,
        share, and store it in a manner consistent with this Privacy Policy.
      </Paragraph>
    </SectionBody>

    <SectionBody>
      <SectionSubheading>
        Information Collected while Using the Application
      </SectionSubheading>
      <Paragraph>
        While using Our Application, in order to provide features of Our
        Application, We may collect, with Your prior permission:
      </Paragraph>
      <List>
        <ListItem>Information regarding your location</ListItem>
        <ListItem>
          Pictures and other information from your Device&apos;s camera and
          photo library
        </ListItem>
      </List>
      <Paragraph>
        We use this information to provide features of Our Service, to improve
        and customize Our Service. The information may be uploaded to the
        Company&apos;s servers and/or a Service Provider&apos;s server or it may
        be simply stored on Your device.
      </Paragraph>
      <Paragraph>
        You can enable or disable access to this information at any time,
        through Your Device settings.
      </Paragraph>
    </SectionBody>

    <SectionBody>
      <SectionSubheading>Tracking Technologies and Cookies</SectionSubheading>
      <Paragraph>
        We use Cookies and similar tracking technologies to track the activity
        on Our Service and store certain information. Tracking technologies used
        are beacons, tags, and scripts to collect and track information and to
        improve and analyze Our Service. The technologies We use may include:
      </Paragraph>
      <List>
        <ListItem>
          <strong> Cookies or Browser Cookies.</strong> A cookie is a small file
          placed on Your Device. You can instruct Your browser to refuse all
          Cookies or to indicate when a Cookie is being sent. However, if You do
          not accept Cookies, You may not be able to use some parts of our
          Service. Unless you have adjusted Your browser setting so that it will
          refuse Cookies, our Service may use Cookies.
        </ListItem>
        <ListItem>
          <strong> Flash Cookies.</strong> Certain features of our Service may
          use local stored objects (or Flash Cookies) to collect and store
          information about Your preferences or Your activity on our Service.
          Flash Cookies are not managed by the same browser settings as those
          used for Browser Cookies. For more information on how You can delete
          Flash Cookies, please read &quot;Where can I change the settings for
          disabling, or deleting local shared objects?&quot; available at &nbsp;
          <a
            href="
          https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_"
            target="_blank"
          >
            Adobe Flash Player EOL General Information Page
          </a>
        </ListItem>
        <ListItem>
          <strong> Web Beacons.</strong> Certain sections of our Service and our
          emails may contain small electronic files known as web beacons (also
          referred to as clear gifs, pixel tags, and single-pixel gifs) that
          permit the Company, for example, to count users who have visited those
          pages or opened an email and for other related website statistics (for
          example, recording the popularity of a certain section and verifying
          system and server integrity).
        </ListItem>
      </List>
      <Paragraph>
        We use both Session and Persistent Cookies for the purposes set out
        below:
      </Paragraph>
      <IndentedBlock>
        <BlockBody>
          <BlockHeading>Necessary / Essential Cookies</BlockHeading>
          <Paragraph>
            Type: Session Cookies
            <br /> Administered by: Us <br />
            Purpose: These Cookies are essential to provide You with services
            available through the Website and to enable You to use some of its
            features. They help to authenticate users and prevent fraudulent use
            of user accounts. Without these Cookies, the services that You have
            asked for cannot be provided, and We only use these Cookies to
            provide You with those services.
          </Paragraph>
        </BlockBody>
        <BlockBody>
          <BlockHeading>
            Cookies Policy / Notice Acceptance Cookies
          </BlockHeading>
          <Paragraph>
            Type: Persistent Cookies <br />
            Administered by: Us <br />
            Purpose: These Cookies identify if users have accepted the use of
            cookies on the Website.
          </Paragraph>
        </BlockBody>
        <BlockBody>
          <BlockHeading>Functionality Cookies</BlockHeading>
          <Paragraph>
            Type: Persistent Cookies <br />
            Administered by: Us <br />
            Purpose: These Cookies allow us to remember choices You make when
            You use the Website, such as remembering your login details or
            language preference. The purpose of these Cookies is to provide You
            with a more personal experience and to avoid You having to re-enter
            your preferences every time You use the Website.
          </Paragraph>
        </BlockBody>
      </IndentedBlock>
      <Paragraph>
        For more information about the cookies we use and your choices regarding
        cookies, please visit our Cookies Policy or the Cookies section of our
        Privacy Policy.
      </Paragraph>
    </SectionBody>
    <SectionBody>
      <SectionSubheading>Use of Your Personal Data</SectionSubheading>
      <Paragraph>
        The Company may use Personal Data for the following purposes:
      </Paragraph>
      <IndentedBlock>
        <Paragraph>
          <strong>To provide and maintain our Service</strong>, including to
          monitor the usage of our Service.
        </Paragraph>
        <Paragraph>
          <strong>To manage Your Account:</strong> to manage Your registration
          as a user of the Service. The Personal Data You provide can give You
          access to different functionalities of the Service that are available
          to You as a registered user.
        </Paragraph>
        <Paragraph>
          <strong> For the performance of a contract:</strong> the development,
          compliance and undertaking of the purchase contract for the products,
          items or services You have purchased or of any other contract with Us
          through the Service.
        </Paragraph>
        <Paragraph>
          <strong> To contact You:</strong> To contact You by email, telephone
          calls, SMS, or other equivalent forms of electronic communication,
          such as a mobile application&apos;s push notifications regarding
          updates or informative communications related to the functionalities,
          products or contracted services, including the security updates, when
          necessary or reasonable for their implementation.
        </Paragraph>
        <Paragraph>
          <strong>To provide You with news,</strong> special offers and general
          information about other goods, services and events which we offer that
          are similar to those that you have already purchased or enquired about
          unless You have opted not to receive such information.
        </Paragraph>
        <Paragraph>
          <strong>To manage Your requests: </strong>To attend and manage Your
          requests to Us.
        </Paragraph>
        <Paragraph>
          <strong>For business transfers: </strong>We may use Your information
          to evaluate or conduct a merger, divestiture, restructuring,
          reorganization, dissolution, or other sale or transfer of some or all
          of Our assets, whether as a going concern or as part of bankruptcy,
          liquidation, or similar proceeding, in which Personal Data held by Us
          about our Service users is among the assets transferred.
        </Paragraph>
        <Paragraph>
          <strong> For other purposes:</strong> We may use Your information for
          other purposes, such as data analysis, identifying usage trends,
          determining the effectiveness of our promotional campaigns and to
          evaluate and improve our Service, products, services, marketing and
          your experience.
        </Paragraph>
      </IndentedBlock>
      <Paragraph>
        We may share Your personal information in the following situations:
      </Paragraph>
      <List>
        <ListItem>
          <strong>With Service Providers:</strong> We may share Your personal
          information with Service Providers to monitor and analyze the use of
          our Service, to contact You.
        </ListItem>
        <ListItem>
          <strong>For business transfers: </strong> We may share or transfer
          Your personal information in connection with, or during negotiations
          of, any merger, sale of Company assets, financing, or acquisition of
          all or a portion of Our business to another company.
        </ListItem>
        <ListItem>
          <strong>With Affiliates:</strong> We may share Your information with
          Our affiliates, in which case we will require those affiliates to
          honor this Privacy Policy. Affiliates include Our parent company and
          any other subsidiaries, joint venture partners or other companies that
          We control or that are under common control with Us.
        </ListItem>
        <ListItem>
          <strong>With business partners:</strong> We may share Your information
          with Our business partners to offer You certain products, services or
          promotions.
        </ListItem>
        <ListItem>
          <strong>With other users:</strong> when You share personal information
          or otherwise interact in the public areas with other users, such
          information may be viewed by all users and may be publicly distributed
          outside. If You interact with other users or register through a
          Third-Party Social Media Service, Your contacts on the Third-Party
          Social Media Service may see Your name, profile, pictures and
          description of Your activity. Similarly, other users will be able to
          view descriptions of Your activity, communicate with You and view Your
          profile.
        </ListItem>
        <ListItem>
          <strong>With Your consent:</strong> We may disclose Your personal
          information for any other purpose with Your consent.
        </ListItem>
      </List>
    </SectionBody>
    <SectionBody>
      <SectionSubheading>Retention of Your Personal Data</SectionSubheading>
      <Paragraph>
        The Company will retain Your Personal Data only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use Your Personal Data to the extent necessary to comply with
        our legal obligations (for example, if we are required to retain your
        data to comply with applicable laws), resolve disputes, and enforce our
        legal agreements and policies.
      </Paragraph>
      <Paragraph>
        The Company will also retain Usage Data for internal analysis purposes.
        Usage Data is generally retained for a shorter period of time, except
        when this data is used to strengthen the security or to improve the
        functionality of Our Service, or We are legally obligated to retain this
        data for longer time periods.
      </Paragraph>
    </SectionBody>
    <SectionBody>
      <SectionSubheading>Transfer of Your Personal Data</SectionSubheading>
      <Paragraph>
        Your information, including Personal Data, is processed at the
        Company&apos;s operating offices and in any other places where the
        parties involved in the processing are located. It means that this
        information may be transferred to — and maintained on — computers
        located outside of Your state, province, country or other governmental
        jurisdiction where the data protection laws may differ from those from
        Your jurisdiction.
      </Paragraph>
      <Paragraph>
        Your consent to this Privacy Policy followed by Your submission of such
        information represents Your agreement to that transfer.
      </Paragraph>
      <Paragraph>
        The Company will take all steps reasonably necessary to ensure that Your
        data is treated securely and in accordance with this Privacy Policy and
        no transfer of Your Personal Data will take place to an organization or
        a country unless there are adequate controls in place including the
        security of Your data and other personal information.
      </Paragraph>

      <strong>Business Transactions</strong>
      <Paragraph>
        If the Company is involved in a merger, acquisition or asset sale, Your
        Personal Data may be transferred. We will provide notice before Your
        Personal Data is transferred and becomes subject to a different Privacy
        Policy.
      </Paragraph>

      <strong>Law enforcement</strong>
      <Paragraph>
        Under certain circumstances, the Company may be required to disclose
        Your Personal Data if required to do so by law or in response to valid
        requests by public authorities (e.g. a court or a government agency).
      </Paragraph>

      <strong> Other legal requirements</strong>
      <Paragraph>
        The Company may disclose Your Personal Data in the good faith belief
        that such action is necessary to:
      </Paragraph>
      <List>
        <ListItem>Comply with a legal obligation</ListItem>
        <ListItem>
          Protect and defend the rights or property of the Company
        </ListItem>
        <ListItem>
          Prevent or investigate possible wrongdoing in connection with the
          Service
        </ListItem>
        <ListItem>
          Protect the personal safety of Users of the Service or the public
        </ListItem>
        <ListItem>Protect against legal liability</ListItem>
      </List>
    </SectionBody>
    <SectionBody>
      <SectionSubheading>Security of Your Personal Data</SectionSubheading>
      <Paragraph>
        The security of Your Personal Data is important to Us, but remember that
        no method of transmission over the Internet, or method of electronic
        storage is 100% secure. While We strive to use commercially acceptable
        means to protect Your Personal Data, We cannot guarantee its absolute
        security
      </Paragraph>
    </SectionBody>
  </Section>
);

export default PersonalData;
