import React from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';

export const CustomCard = ({ img, imgAlt, title, text, link, action }) => (
  <Card sx={{ boxShadow: 'none' }}>
    <CardMedia component="img" image={img} alt={imgAlt} height={200} />
    <CardContent>
      <Typography
        as="h5"
        sx={{ fontSize: '20px', fontWeight: '600', margin: '18px 0' }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          height: '150px',
          marginTop: '0.5rem',
          fontSize: '16px',
          lineHeight: '32px',
          color: '#131936',
          fontWeight: '600',
        }}
      >
        {text}
      </Typography>

      {link && (
        <Link to={link}>
          <Button
            sx={{
              background: '#5c70cd',
              margin: '20px auto 0',
              textTransform: 'unset',
            }}
            type="button"
            variant="contained"
          >
            {action}
          </Button>
        </Link>
      )}
      {action.toLowerCase() === 'coming soon' && (
        <Box
          sx={{
            backgroundColor: '#83a91729',
            width: 'fit-content',
            padding: '10px 12px',
            borderRadius: '16px',
            margin: '20px auto 0',
            color: '#131936',
          }}
        >
          {action}
        </Box>
      )}
    </CardContent>
  </Card>
);
