import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import FormWrapper from '../../FormWrapper';
import Capsule from '../../../../../components/Capsule';
import Publications from './publication/Publications';
import Projects from './projects/projects';
import Honors from './honors/honors';
import TestScore from './testScores/tests';
import Patents from './patents/Patents';
import Courses from './courses/course';
import Language from './languages/language';
import Organization from './organizations/Organization';
// import { GOTO_NEXT_ONBOARDINGROUTE } from '../../../../../redux/actionTypes';

const AccomplishmentForm = ({
  noFooter,
  formShown,
  formName,
  user,
  back,
  next,
}) => {
  const [fieldsShown, setFieldsShown] = useState({
    publicationsShown: false,
    patentShown: false,
    coursesShown: false,
    projectShown: false,
    honorsShown: false,
    testShown: false,
    languagesShown: false,
    organisationShown: false,
  });
  const history = useHistory();
  const location = useLocation();
  /**
   * Toggle Dropdown accordingly
   *
   * @function
   * @param {Sting} payload The Drowpdown to toggle
   * @return {void}
   */
  const toggleFieldShown = (fieldName) => {
    setFieldsShown({
      ...fieldsShown,
      [fieldName]: !fieldsShown[fieldName],
    });
  };

  return (
    <FormWrapper
      formShown={formShown}
      noFooter={noFooter}
      formName={formName}
      handleSubmit={() => {
        if (location.pathname === '/accomplishments') {
          history.push('skills');
        }
      }}
    >
      <div className="single__accomplishment_wrapper">
        <Capsule
          title="Publications"
          show={fieldsShown.publicationsShown}
          toggleFieldShown={() => toggleFieldShown('publicationsShown')}
        />
        <Publications formShown={fieldsShown.publicationsShown} noFooter />
        <Capsule
          title="Patents"
          show={fieldsShown.patentShown}
          toggleFieldShown={() => toggleFieldShown('patentShown')}
        />
        <Patents formShown={fieldsShown.patentShown} noFooter />
        <Capsule
          title="Courses"
          show={fieldsShown.coursesShown}
          toggleFieldShown={() => toggleFieldShown('coursesShown')}
        />
        <Courses formShown={fieldsShown.coursesShown} noFooter />
        <Capsule
          title="Projects"
          show={fieldsShown.projectShown}
          toggleFieldShown={() => toggleFieldShown('projectShown')}
        />

        <Projects formShown={fieldsShown.projectShown} noFooter />

        <Capsule
          title="Honours &amp; Awards"
          show={fieldsShown.honorsShown}
          toggleFieldShown={() => toggleFieldShown('honorsShown')}
        />

        <Honors formShown={fieldsShown.honorsShown} noFooter />

        <Capsule
          title="Test scores"
          show={fieldsShown.testShown}
          toggleFieldShown={() => toggleFieldShown('testShown')}
        />

        <TestScore formShown={fieldsShown.testShown} noFooter />

        <Capsule
          title="Languages"
          show={fieldsShown.languagesShown}
          toggleFieldShown={() => toggleFieldShown('languagesShown')}
        />

        <Language formShown={fieldsShown.languagesShown} noFooter />
        <Capsule
          title="Organisations"
          show={fieldsShown.organisationShown}
          toggleFieldShown={() => toggleFieldShown('organisationShown')}
        />
        <Organization formShown={fieldsShown.organisationShown} noFooter />

        <br />
        <br />
        <br />
      </div>
    </FormWrapper>
  );
};

export default AccomplishmentForm;
