import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Avatar, Menu, MenuItem, Box } from '@mui/material';
import styled from 'styled-components';
import logo from '../assets/images/rezzie_logo.png';
import { logout } from '../redux/actions';

const Header = styled.header`
  position: sticky;
  top: 0;
  min-height: 66px;
  z-index: 100;
  background: #fbfbff;
  padding: 13px 45px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;

  .logo {
    margin-right: 2.5rem;
    object-fit: contain;
  }

  @media (max-width: 414px) {
    padding: 13px 20px;
  }
`;

const Nav = styled.ul`
  list-style: none;
  font-family: 'Circular Std Medium';
  z-index: 100;
  color: ${({ theme }) => theme.baseHeadingDark};
  display: flex;
  align-items: center;

  li {
    display: inline-block;
    padding: 11px 15px;
    margin-right: 15px;
    a {
      text-decoration: none;
      color: ${({ theme }) => theme.baseHeadingDark};
    }
    @media (max-width: 414px) {
      display: none;
    }
    @media (min-width: 900px) {
      margin-right: 25px;
    }
  }
  li.active {
    background: #f3f3ff;
    border-radius: 6px;
  }
`;

// const Logo = styled.span`
// `;

const AvatarWrapper = styled.div`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
`;

// const OptionsWrapper = styled.div`
//   position: absolute;
//   top: 70px;
//   right: 0;
//   width: 192px;
//   padding: 9px 16px;
//   background: #ffffff;
//   box-shadow: 0px 4px 8px rgba(39, 40, 51, 0.12);
//   border-radius: 4px;

//   span {
//     color: #6b6c7e;
//   }
// `;
const HeaderNav = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const avatar = useSelector((state) => state.auth.user.avatar);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleLink = (route) => {
    history.push(`/${route}`);
    handleClose();
  };

  const handleLogout = () => {
    localStorage.clear();
    dispatch(logout());
  };

  return (
    <Header>
      <Box display="flex">
        <img className="logo" src={logo} alt="rezzie logo" />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          sx={{
            marginTop: '10px',
          }}
        >
          <MenuItem
            sx={{
              fontFamily: 'Circular Std Medium',
              color: '#131936',
            }}
            onClick={() => handleLink('resume')}
          >
            {t('Resume Builder')}
          </MenuItem>
          <MenuItem
            sx={{
              fontFamily: 'Circular Std Medium',
              color: '#131936',
            }}
            onClick={() => handleLink('coverletter')}
          >
            {t('Cover Letter Builder')}
          </MenuItem>
          {/* <MenuItem
            sx={{
              fontFamily: 'Circular Std Medium',
              color: '#131936',
            }}
            onClick={() => handleLink('profile')}
          >
            Your Profile
          </MenuItem> */}

          <MenuItem
            sx={{
              fontFamily: 'Circular Std Medium',
              color: '#131936',
            }}
            onClick={handleLogout}
          >
            {t('Log Out')}
          </MenuItem>
        </Menu>
        <Nav>
          <li>
            <Link to="/resume"> {t('Resume Builder')}</Link>
          </li>
          <li>
            <Link to="/coverletter">{t('Cover Letter Builder')}</Link>
          </li>
          {/* <li>
            <Link to="/profile">Your Profile</Link>
          </li> */}
        </Nav>
      </Box>
      <AvatarWrapper onClick={handleClick}>
        <Avatar src={avatar} />
        <DownArrow />
      </AvatarWrapper>
    </Header>
  );
};
export default HeaderNav;

const DownArrow = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginLeft: '9px' }}
  >
    <path
      d="M18 10.0001L16.59 8.59009L12 13.1701L7.41 8.59009L6 10.0001L12 16.0001L18 10.0001Z"
      fill="black"
    />
  </svg>
);
