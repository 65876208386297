import { combineReducers } from 'redux';
import { authReducer } from './reducers/authReducer';
import { onboardingReducer } from './reducers/onboardingReducer';
import { templateReducer } from './reducers/templateReducer';

export const rootReducer = combineReducers({
  auth: authReducer,
  onboarding: onboardingReducer,
  template: templateReducer,
});
