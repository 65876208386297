import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';
import { rootReducer } from './rootReducer';

const { persistReducer } = require('redux-persist');
const storage = require('redux-persist/lib/storage/session').default;

const persistConfig = {
  key: 'rezzie',
  //   blacklist: ['soleproprietor', 'corporation', 'ngo'],
  storage,
};

const initStore = createStore(
  persistReducer(persistConfig, rootReducer),
  composeWithDevTools(applyMiddleware(thunkMiddleware)),
);

initStore.__PERSISTOR = persistStore(initStore);

export const store = initStore;
