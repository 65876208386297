import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';

import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import apiClient from '../../api/server';
import Layout from '../layout/Layout';
import star from '../../assets/images/star 1.svg';
// import Capsule from '../../components/Capsule';
// import ContactForm from './components/forms/contact/ContactForm';
// import Eduform from './components/forms/education/Eduform';
// import ExperienceForm from './components/forms/workexperience/ExperienceForm';
// import VolunteerForm from './components/forms/volunteer/VolunteerForm';
// import LicenseForm from './components/forms/licenseandcertification/LicenseForm';
// import AccomplishmentForm from './components/forms/accomplishments';
// import HeadlineForm from './components/forms/headline/headlineForm';
// import Skillform from './components/forms/skills/skillForm';
import eyeWhite from '../../assets/images/eye_white.png';
import downloadWhite from '../../assets/images/download_white.png';
import changeWhite from '../../assets/images/change_template_white.png';
import ContactInformation from './resumeBuilder/forms/ContactInformation';
import Headline from './resumeBuilder/forms/Headline';
import Education from './resumeBuilder/forms/Education';
import Experience from './resumeBuilder/forms/Experience';
import VolunteerHistory from './resumeBuilder/forms/VolunteerHistory';
import LicensesCertifications from './resumeBuilder/forms/LicensesCertifications';
import Accomplishments from './resumeBuilder/forms/Accomplishments';
import Skills from './resumeBuilder/forms/Skills';

const MainContent = styled.main`
  width: 100%;
  max-width: 672px;

  .profile_name {
    font-family: 'Circular Std Bold';
    font-size: 32px;
    line-height: 32px;
    letter-spacing: 0em;
    /* text-align: left; */
    color: #131936;
    margin-bottom: 30px;
  }

  .accordion {
    box-shadow: none;
  }
  .accordion-summary {
    font-size: 20px;
    line-height: 20px;
    font-family: 'Circular Std Bold';
    border-bottom: 1px solid rgb(235, 235, 235);
  }
  .summary {
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .accordion-details {
    margin-top: 50px;
  }

  .button-section {
    margin-top: 40px;
    text-align: center;
  }
  .form_next_btn {
    min-width: 147px;
    height: 40px;
    padding: 0 16px;
    border-radius: 4px;
    background: #5c70cd;
    font-size: 16px;
    line-height: 29px;
    letter-spacing: 0em;
    border: none;
    color: white;
    cursor: pointer;
    text-transform: capitalize;
    font-family: 'Circular Std Book' !important;

    :hover {
      background: #5c70cd;
    }
  }
`;

const TopHeader = styled.header`
  height: 372px;
  border-radius: 8px;
  background: #131936;
  border: 1px solid #efefef;
  /* color: white !important; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .heading_caption {
    font-family: 'Circular Std Medium';
    font-size: 20px;
    letter-spacing: 0em;
    color: white;
    margin-bottom: 26px;
  }

  .info-text {
    font-size: 18px;
    line-height: 30px;
    color: white;
    margin-top: 40px;
  }

  footer {
    margin-top: 30px;
    button {
      padding-left: 20px;
      border: none;
      height: 40px;
      min-width: 147px;
      border-radius: 4px;
      color: #ffffff;
      font-family: 'Circular Std Medium';
      font-size: 16px;
      line-height: 29px;
      letter-spacing: 0em;
      cursor: pointer;

      // &:first-child {
      //   margin-right: 19px;
      // }
    }

    .preview_btn {
      background: #5c70cd url(${eyeWhite}) no-repeat 20px 8px;
      margin-right: 19px;
    }

    .download_btn {
      background: #5c70cd url(${downloadWhite}) no-repeat 18px 8px;
    }

    .change_btn {
      width: 210px;
      background: transparent url(${changeWhite}) no-repeat 18px 8px;
      margin-top: 20px;
    }
  }
`;

// const NeedHelp = styled.button`
//   border: none;
//   color: #ffffff;
//   font-size: 16px;
//   background: #5c70cd;
//   border-radius: 20px;
//   padding: 7px 16px;
//   position: absolute;
//   right: 50px;
//   cursor: pointer;
// `;

const Profile = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const { activeTemplate } = useSelector((state) => state.template);
  const [starState, setStarState] = useState(0);

  const [userData, setUserData] = useState({});

  const [fieldsShown, setFieldsShown] = useState({
    contactShown: false,
    experienceShown: false,
    eduShown: false,
    volunterrShown: false,
    licenseShown: false,
    accShown: false,
  });

  const getProfile = async () => {
    const res = await apiClient.getUserProfile(user.id);
    if (res.status) {
      setUserData(res.data);
      if (res?.data?.headline?.details !== ''?.length > 0) {
        setStarState((i) => i + 5);
      }
      if (res?.data?.educations?.length > 0) {
        setStarState((i) => i + 5);
      }
      if (res?.data?.workExperiences?.length > 0) {
        setStarState((i) => i + 5);
      }
      if (res?.data?.volunteerHistories?.length > 0) {
        setStarState((i) => i + 5);
      }
      if (res?.data?.skills?.length > 0) {
        setStarState((i) => i + 5);
      }
      if (res?.data?.contactInformation?.id?.length > 0) {
        setStarState((i) => i + 5);
      }
      if (res?.data?.licenseAndCertificates?.length > 0) {
        setStarState((i) => i + 5);
      }
      if (res?.data?.publications) {
        setStarState((i) => i + 1);
      }
      if (res?.data?.publications) {
        setStarState((i) => i + 1);
      }
      if (res?.data?.courses) {
        setStarState((i) => i + 1);
      }
      if (res?.data?.projects) {
        setStarState((i) => i + 1);
      }
      if (res?.data?.honours) {
        setStarState((i) => i + 1);
      }
      if (res?.data?.tests) {
        setStarState((i) => i + 1);
      }
      if (res?.data?.languages) {
        setStarState((i) => i + 1);
      }
      if (res?.data?.organisations) {
        setStarState((i) => i + 1);
      }
    }
  };

  function dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  const downloadURI = (base64, name) => {
    const iOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isChrome =
      navigator.userAgent.toLowerCase().indexOf('CriOS') > -1 ||
      navigator.vendor.toLowerCase().indexOf('google') > -1;
    let iOSVersion = [];
    if (iOS) {
      iOSVersion = navigator.userAgent
        .match(/OS [\d_]+/i)[0]
        .substr(3)
        .split('_')
        .map((n) => Number(n));
    }

    const attachmentData = base64;
    let contentType = 'application/pdf';

    const binary = atob(attachmentData.replace(/\s/g, ''));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);

    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }

    let hrefUrl = '';
    let blob = '';

    if (iOS && !isChrome && iOSVersion[0] <= 12) {
      blob = `data:application/pdf;base64,${attachmentData}`;
      hrefUrl = blob;
    } else {
      if (iOS && !isChrome) {
        contentType = 'application/octet-stream';
      }
      blob = new Blob([view], { type: contentType });
      hrefUrl = window.URL.createObjectURL(blob);
    }

    const a = document.createElement('a');

    a.setAttribute('href', hrefUrl);
    a.setAttribute('target', '_blank');
    if ((iOS && (iOSVersion[0] > 12 || isChrome)) || !iOS) {
      a.setAttribute('download', name);
    }

    const clickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: false,
    });

    a.dispatchEvent(clickEvent);
  };

  const downloadTemplate = async (templateId) => {
    if (userData.templateId) {
      const res = await apiClient.getTemplatePdf(user.id, userData.templateId);

      if (res.status) {
        toast.success('Template downloaded successfully');
        const isMobile = window.matchMedia(
          'only screen and (max-width: 1024px)'
        ).matches;

        if (isMobile) {
          downloadURI(res.data.base64Data, 'resume.pdf');
        } else {
          window.open(res.data.url, '_blank');
        }
      }
    } else {
      toast.error('You have not chosen a template yet!');
    }
  };

  const previewTemplate = async (templateId) => {
    if (userData.templateId) {
      history.push(`/resumesample/${userData.templateId}`);
    } else {
      toast.error('You have not chosen a template yet!');
    }
  };

  /**
   * Toggle Dropdown accordingly
   *
   * @function
   * @param {Sting} payload The Drowpdown to toggle
   * @return {void}
   */
  const toggleFieldShown = (fieldName) => {
    setFieldsShown({
      ...fieldsShown,
      [fieldName]: !fieldsShown[fieldName],
    });
  };
  useEffect(() => {
    getProfile();
  }, []);

  return (
    <Layout>
      <MainContent>
        <p className="profile_name">Hi {user.firstName},</p>
        <TopHeader>
          <p
            className="heading_caption"
            style={{
              textAlign: 'center',
            }}
          >
            {t('Your resume has')} {Math.round(starState * 0.125)} {t('stars')}
            🎉
          </p>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            {Math.round(starState * 0.125) === 5 && (
              <div>
                <img src={star} alt="stars" style={{ marginRight: '10px' }} />
                <img src={star} alt="stars" style={{ marginRight: '10px' }} />
                <img src={star} alt="stars" style={{ marginRight: '10px' }} />
                <img src={star} alt="stars" style={{ marginRight: '10px' }} />
                <img src={star} alt="stars" style={{ marginRight: '10px' }} />
              </div>
            )}
            {Math.round(starState * 0.125) === 4 && (
              <div>
                <img src={star} alt="stars" style={{ marginRight: '10px' }} />
                <img src={star} alt="stars" style={{ marginRight: '10px' }} />
                <img src={star} alt="stars" style={{ marginRight: '10px' }} />
                <img src={star} alt="stars" style={{ marginRight: '10px' }} />
              </div>
            )}
            {Math.round(starState * 0.125) === 3 && (
              <div>
                <img src={star} alt="stars" style={{ marginRight: '10px' }} />
                <img src={star} alt="stars" style={{ marginRight: '10px' }} />
                <img src={star} alt="stars" style={{ marginRight: '10px' }} />
              </div>
            )}
            {Math.round(starState * 0.125) === 2 && (
              <div>
                <img src={star} alt="stars" style={{ marginRight: '10px' }} />
                <img src={star} alt="stars" style={{ marginRight: '10px' }} />
              </div>
            )}
            {Math.round(starState * 0.125) === 1 && (
              <div>
                <img src={star} alt="stars" style={{ marginRight: '10px' }} />
              </div>
            )}
          </div>
          <br />
          <p className="info-text">{t('Fill all sections')}</p>

          <footer>
            <button
              type="button"
              className="preview_btn"
              onClick={() => {
                previewTemplate(activeTemplate);
              }}
            >
              {t('Preview')}
            </button>

            <button
              type="button"
              className="download_btn"
              onClick={() => {
                downloadTemplate(activeTemplate);
              }}
            >
              {t('Download')}
            </button>

            <br />
            <Link to="/selecttemplate">
              <button type="button" className="change_btn">
                {t('Change template')}
              </button>
            </Link>
          </footer>
        </TopHeader>

        <Accordion
          square
          disableGutters
          elevation={0}
          sx={{
            '&:before': {
              display: 'none',
              boxShadow: 'none',
              border: 'none !important',
            },
          }}
        >
          <AccordionSummary
            className="accordion-summary"
            expandIcon={<ExpandMoreIcon />}
          >
            <Box className="summary">{t('Contact Information')}</Box>
          </AccordionSummary>
          <AccordionDetails className="accordion-details">
            <ContactInformation user={user} formOnly />
          </AccordionDetails>
        </Accordion>

        <Accordion
          square
          disableGutters
          elevation={0}
          sx={{
            '&:before': {
              display: 'none',
              boxShadow: 'none',
              border: 'none !important',
            },
          }}
        >
          <AccordionSummary
            className="accordion-summary"
            expandIcon={<ExpandMoreIcon />}
          >
            <Box className="summary">{t('Headline')}</Box>
          </AccordionSummary>
          <AccordionDetails className="accordion-details">
            <Headline user={user} formOnly />
          </AccordionDetails>
        </Accordion>

        <Accordion
          square
          disableGutters
          elevation={0}
          sx={{
            '&:before': {
              display: 'none',
              boxShadow: 'none',
              border: 'none !important',
            },
          }}
        >
          <AccordionSummary
            className="accordion-summary"
            expandIcon={<ExpandMoreIcon />}
          >
            <Box className="summary">{t('Work Experience')}</Box>
          </AccordionSummary>
          <AccordionDetails className="accordion-details">
            <Experience user={user} formOnly />
          </AccordionDetails>
        </Accordion>

        <Accordion
          square
          disableGutters
          elevation={0}
          sx={{
            '&:before': {
              display: 'none',
              boxShadow: 'none',
              border: 'none !important',
            },
          }}
        >
          <AccordionSummary
            className="accordion-summary"
            expandIcon={<ExpandMoreIcon />}
          >
            <Box className="summary">{t('Education')}</Box>
          </AccordionSummary>
          <AccordionDetails className="accordion-details">
            <Education user={user} formOnly />
          </AccordionDetails>
        </Accordion>

        <Accordion
          square
          disableGutters
          elevation={0}
          sx={{
            '&:before': {
              display: 'none',
              boxShadow: 'none',
              border: 'none !important',
            },
          }}
        >
          <AccordionSummary
            className="accordion-summary"
            expandIcon={<ExpandMoreIcon />}
          >
            <Box className="summary">{t('Volunteer History')}</Box>
          </AccordionSummary>
          <AccordionDetails className="accordion-details">
            <VolunteerHistory user={user} formOnly />
          </AccordionDetails>
        </Accordion>

        <Accordion
          square
          disableGutters
          elevation={0}
          sx={{
            '&:before': {
              display: 'none',
              boxShadow: 'none',
              border: 'none !important',
            },
          }}
        >
          <AccordionSummary
            className="accordion-summary"
            expandIcon={<ExpandMoreIcon />}
          >
            <Box className="summary">{t('Licenses and Certifications')}</Box>
          </AccordionSummary>
          <AccordionDetails className="accordion-details">
            <LicensesCertifications user={user} formOnly />
          </AccordionDetails>
        </Accordion>

        <Accordion
          square
          disableGutters
          elevation={0}
          sx={{
            '&:before': {
              display: 'none',
              boxShadow: 'none',
              border: 'none !important',
            },
          }}
        >
          <AccordionSummary
            className="accordion-summary"
            expandIcon={<ExpandMoreIcon />}
          >
            <Box className="summary">{t('Accomplishments')}</Box>
          </AccordionSummary>
          <AccordionDetails className="accordion-details">
            <Accomplishments user={user} formOnly />
          </AccordionDetails>
        </Accordion>

        <Accordion
          square
          disableGutters
          elevation={0}
          sx={{
            '&:before': {
              display: 'none',
              boxShadow: 'none',
              border: 'none !important',
            },
          }}
        >
          <AccordionSummary
            className="accordion-summary"
            expandIcon={<ExpandMoreIcon />}
          >
            <Box className="summary">{t('Skills')}</Box>
          </AccordionSummary>
          <AccordionDetails className="accordion-details">
            <Skills user={user} formOnly />
          </AccordionDetails>
        </Accordion>
        <Box className="button-section">
          <Link to="selecttemplate">
            <button type="submit" className="form_next_btn inline">
              {t('Proceed to select a template')}
            </button>
          </Link>
        </Box>
      </MainContent>
    </Layout>
  );
};

export default Profile;
