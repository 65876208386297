import React from 'react';
import Wrapper from './layout/Wrapper';
import Heading from './components/Heading&Caption';
import HeadlineForm from './components/forms/headline/headlineForm';

const Headline = () => (
  <Wrapper>
    <Heading
      caption="Headline"
      skipBtn
      smallCaption="Tell us a compelling summary of your experiences and interests."
    />
    <HeadlineForm formShown />
  </Wrapper>
);

export default Headline;
