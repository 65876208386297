import React from 'react';
import Wrapper from './layout/Wrapper';
import Heading from './components/Heading&Caption';
import Eduform from './components/forms/education/Eduform';

const Education = () => (
  <Wrapper>
    <Heading
      caption="Education"
      skipBtn
      smallCaption="Here we want to hear your education history."
    />
    {/* Education Form */}
    <Eduform formShown />
  </Wrapper>
);

export default Education;
