import React, { useEffect } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material';
import axios from 'axios';
import Routes from './routes';
import utility from './utils/index';
import { logout } from './redux/actions';

const { isMyObjectEmpty } = utility;

const App = () => {
  const theme = createTheme({
    typography: {
      fontFamily: 'QuickSand',
    },
  });
  /* Get current user */
  const { user } = useSelector((state) => state.auth);

  /* Check if logged in */
  const isLoggedIn = !isMyObjectEmpty(user);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { pathname } = location;

  /* Direct to the previous page if the user enters an invalid path */
  // useEffect(() => {
  //   let checkValidPath;

  //   if (
  //     pathname !== '/' &&
  //     pathname !== '/resume' &&
  //     pathname !== '/resume-builder' &&
  //     pathname !== '/resumeandcoverletterbuilder/signup' &&
  //     pathname !== '/resumeandcoverletterbuilder/login' &&
  //     pathname !== '/forgotpassword' &&
  //     pathname !== '/termsandconditions' &&
  //     pathname !== '/privacypolicy' &&
  //     isLoggedIn
  //   ) {
  //     toast.error('Ooops, you entered an invalid path');
  //     checkValidPath = setTimeout(() => {
  //       history.goBack();
  //     }, 1500);
  //   }

  //   return () => clearTimeout(checkValidPath);
  // }, [pathname]);

  /* Direct to Login if user not loggged in */
  useEffect(() => {
    let checkAuth;

    if (
      pathname !== '/' &&
      pathname !== '/resume-builder' &&
      pathname !== '/resumeandcoverletterbuilder/signup' &&
      pathname !== '/resumeandcoverletterbuilder/login' &&
      pathname !== '/forgotpassword' &&
      pathname !== '/termsandconditions' &&
      pathname !== '/privacypolicy' &&
      pathname !== '/resources' &&
      pathname !== '/resources/questionnaire' &&
      !isLoggedIn
    ) {
      checkAuth = setTimeout(() => {
        history.push('/resumeandcoverletterbuilder/login');
      }, 1500);
    }

    return () => clearTimeout(checkAuth);
  });

  axios.interceptors.request.use(
    (req) => {
      if (
        req.url !== '/auth/register' ||
        req.url !== '/auth/google/login' ||
        req.url !== '/auth/linkedin/login' ||
        req.url !== '/auth/login'
      ) {
        const token = localStorage.getItem('rezzie_token');
        if (!token) {
          localStorage.clear();
          dispatch(logout());
        }
      }
      req.time = { startTime: new Date() };
      return req;
    },
    (err) => {
      console.log(err);
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    (res) => {
      res.config.time.endTime = new Date();
      res.duration = res.config.time.endTime - res.config.time.startTime;
      return res;
    },
    (err) => {
      console.log(err);
      return Promise.reject(err);
    }
  );

  return (
    <ThemeProvider theme={theme}>
      <Switch>
        {Routes.map(({ component, exact, path }) => (
          <Route
            key={component}
            component={component}
            exact={exact}
            path={path}
          />
        ))}
      </Switch>
    </ThemeProvider>
  );
};

export default App;
