import React from 'react';
import styled from 'styled-components';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const Capsule = styled.div`
  width: 100%;
  padding: 28px 0;
  border-bottom: 1px solid #ebebeb;
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: 'Circular Std Medium';
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0em;
  /* text-align: left; */
  color: #131936;
  cursor: pointer;
`;

const Caps = ({ title, show, toggleFieldShown }) => (
  <Capsule onClick={toggleFieldShown}>
    <p className="capsule-caption">{title}</p>
    {!show && <ChevronRightIcon />}
    {show && <KeyboardArrowDownIcon />}
  </Capsule>
);

export default Caps;

// const RightArrow = () => (
//   <svg
//     width="8"
//     height="12"
//     viewBox="0 0 8 12"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <path
//       d="M1.99984 0L0.589844 1.41L5.16984 6L0.589844 10.59L1.99984 12L7.99984 6L1.99984 0Z"
//       fill="black"
//     />
//   </svg>
// );
