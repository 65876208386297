import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import resumeBuilderImg from '../../../../assets/images/resume-review.jpeg';
import coverletterImg from '../../../../assets/images/cover-letter-review.svg';
import jobBoardImg from '../../../../assets/images/job-board.svg';
import { CustomCard } from './styles';

function Aboutpage() {
  return (
    <Box
      as="section"
      className=" aboutpage"
      sx={{
        margin: '7rem 0',
        textAlign: 'center',
        padding: {
          xs: '3rem 1.05rem',
          md: '3rem 2.5rem',
        },
      }}
    >
      <Typography
        sx={{ fontWeight: '600', fontSize: '35px', lineHeight: '40px' }}
      >
        Browse through our array of best in class applicant tools and enterprise
        hiring software as part of our efforts to make hiring an arduous free
        experience.
      </Typography>

      <Grid container rowSpacing={8} sx={{ marginTop: '0' }}>
        <Grid className="feature-card" item sm={4} position="relative">
          <CustomCard
            title="Resume Builder"
            img={resumeBuilderImg}
            alt="resume builder"
            text="Our resume builder provides templates, tools, and proven tips for
            building winning resumes in diverse fields."
            action="Try for free"
            link="/resumeandcoverletterbuilder/login"
          />
        </Grid>

        <Grid item sm={4}>
          <CustomCard
            title="Resume and Cover Letter Review"
            img={coverletterImg}
            imgAlt="cover letter"
            text="Let's get your resume and/or cover letter reviewed by industry
            experts and improve your job application outcomes."
            action="Coming soon"
            link=""
          />
        </Grid>
        <Grid item sm={4}>
          <CustomCard
            title="Job Board"
            img={jobBoardImg}
            imgAlt="Job board"
            text="Create an ethical job post and improve your applicant's
            experience of the hiring process."
            action="Coming soon"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Aboutpage;
