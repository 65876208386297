import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';
import {
  Container,
  InputBase,
  FormControl,
  Box,
  CircularProgress,
  Button,
  Chip,
} from '@mui/material';
import apiClient from '../../../../api/server';

const MainContent = styled.main`
  width: 100%;
  max-width: 775px;

  .title {
    font-family: 'Circular Std Bold';
    font-size: 32px;
    line-height: 32px;
    letter-spacing: 0em;
    /* text-align: left; */
    color: #131936;
    margin-bottom: 10px;
  }

  .description {
    font-family: 'Circular Std Book';
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0em;
    /* text-align: left; */
    color: #131936;
    margin-bottom: 30px;
  }

  .form-container {
    padding: 0px;
    width: 100%;
  }

  .input-label {
    margin-bottom: 10px;
    font-family: 'Circular Std Book' !important;
    font-style: normal;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #272833;
  }

  .tip-section {
    display: flex;
    flex-direction: row;
    margin-top: 80px;
  }

  .tip-img-section {
    flex: 1;
  }

  .tip-content-section {
    flex: 14;
  }

  .tip-icon {
    width: 34px;
  }

  .tip-title {
    font-family: 'Circular Std Bold';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }

  .tip-description {
    font-family: 'Circular Std Book';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
  }

  .words-left {
    font-family: 'Circular Std Book';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #838383;
  }

  .button-section {
    margin-top: 40px;
    text-align: right;
  }
  .inline {
    margin-right: 45px;
    display: inline;

    :last-child {
      margin-right: 0px;
    }
  }
  .form_next_btn {
    min-width: 147px;
    height: 40px;
    padding: 0 16px;
    border-radius: 4px;
    background: #5c70cd;
    font-size: 16px;
    line-height: 29px;
    letter-spacing: 0em;
    border: none;
    color: white;
    cursor: pointer;
    text-transform: capitalize;
    font-family: 'Circular Std Book' !important;

    :hover {
      background: #5c70cd;
    }
  }

  .back-button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  .input-section {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .loading-container {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
  }
  .chip-section {
    margin-top: 15px;
  }
  .chip {
    margin: 5px;
  }

  .skip-button {
    font-family: Circular Std Medium !important;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0em;
    float: right;
    color: rgb(92, 112, 205);
    cursor: pointer;
    outline: none;
    background: none;
    border: none;
  }
`;

const BootstrapSelectInput = styled(InputBase)(() => ({
  'label + &': {
    marginTop: 40,
  },
  '.MuiInputBase-root': {
    fontFamily: 'Circular Std Book',
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#F1F2F5',
    border: '1px solid #E7E7ED',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    fontFamily: 'Circular Std Book',
    '&:focus': {
      borderColor: 'rgba(92, 112, 205, 0.13)',
    },
  },
}));

const BootstrapInput = styled(InputBase)(() => ({
  'label + &': {
    marginTop: 40,
  },
  '& .MuiInputBase-root': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#F1F2F5',
    border: '1px solid #E7E7ED',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    fontFamily: 'Circular Std Book',
    '&:focus': {
      borderColor: 'rgba(92, 112, 205, 0.13)',
    },
  },
  '& .MuiInputBase-input': {
    fontFamily: 'Circular Std Book',
    width: '100%',
    color: 'rgb(39, 40, 51)',
  },
}));

const Skills = React.forwardRef(({ user, back, next, formOnly }, ref) => {
  const formRef = useRef();
  const [data, setData] = useState({
    allSkills: [],
    professionalSkills: [],
    singleSkill: '',
    proSkill: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [fromNav, setFromNav] = useState(false);

  const [_proSkill, _setProSkill] = useState([]);

  useImperativeHandle(ref, () => ({
    async submit() {
      if (formRef.current) {
        setFromNav(true);
        await formRef.current.handleSubmit();
      }
    },
  }));

  const getData = async () => {
    const response = await apiClient.getSkills(user.id);
    if (response.status) {
      let professionalSkills = response.data.filter(
        (i) => i.type === 'professional'
      )[0]?.skills;

      let allSkills = response.data?.filter((i) => i.type === 'others')[0]
        ?.skills;

      if (!allSkills) {
        allSkills = [];
      }

      if (!professionalSkills) {
        professionalSkills = [];
      }

      setData({
        professionalSkills,
        allSkills,
      });
      setIsLoading(false);
    } else {
      setData({
        allSkills: [],
        professionalSkills: [],
        singleSkill: '',
        proSkill: '',
      });
      setIsLoading(false);
    }
  };

  const validationSchema = yup.object({});

  const handleFormSubmit = async (values, setSubmitting) => {
    const response = await apiClient.postSkills(user.id, values);

    if (response.status) {
      if (!formOnly) {
        if (fromNav) {
          next(true);
        } else {
          next();
        }
      } else {
        toast.success('Update Successful');
        setSubmitting(false);
      }
    } else {
      toast.error(response.message);
      setSubmitting(false);
    }
  };

  const handleBack = () => {
    back();
  };

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, []);

  const handleAddProfessionalSkill = (setFieldValue) => {
    data.professionalSkills.push(formRef.current.values.proSkill);
    setFieldValue(`proSkill`, '', false);
  };

  const handleDeleteProfessionalSkill = (name) => {
    const newArr = data.professionalSkills.filter((n) => n !== name);
    setData({ ...data, professionalSkills: newArr });
  };

  const handleAddSingleSkill = (setFieldValue) => {
    data.allSkills.push(formRef.current.values.singleSkill);
    setFieldValue(`singleSkill`, '', false);
  };

  const handleDeleteSingleSkill = (name) => {
    const newArr = data.allSkills.filter((n) => n !== name);
    setData({...data, allSkills: newArr });
  };

  return (
    <MainContent>
      {!isLoading && (
        <Box>
          {!formOnly && (
            <Box>
              <button
                type="button"
                className="skip-button"
                onClick={() => next()}
              >
                Skip This
              </button>
              <p className="title">Add Skills</p>
              <p className="description">Add all skills that best suit you.</p>
            </Box>
          )}

          <Container id="form-section" className="form-container">
            <Formik
              innerRef={formRef}
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={data}
              onSubmit={(values, { setSubmitting }) => {
                handleFormSubmit(values, setSubmitting);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box className="input-section">
                    <p className="input-label">Professional Skills</p>
                    <FormControl className="form-container" variant="standard">
                      <BootstrapInput
                        placeholder="e.g. Excel, Javascript, Photoshop"
                        id="text-input"
                        value={values.proSkill}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="proSkill"
                      />
                    </FormControl>
                  </Box>

                  <Box className="add-achievement-section">
                    <Button
                      variant="contained"
                      disableFocusRipple
                      disableRipple
                      disableElevation
                      className="form_next_btn"
                      onClick={() => handleAddProfessionalSkill(setFieldValue)}
                    >
                      Add
                    </Button>
                  </Box>

                  <Box className="chip-section">
                    {data.professionalSkills &&
                      data.professionalSkills.map((proSkill, proSkillIndex) => (
                        <Chip
                          className="chip"
                          key={proSkill}
                          label={proSkill}
                          onDelete={() => {
                            handleDeleteProfessionalSkill(
                              proSkill
                            );
                          }}
                        />
                      ))}
                  </Box>

                  <Box className="input-section">
                    <p className="input-label">Other Skills</p>
                    <FormControl className="form-container" variant="standard">
                      <BootstrapInput
                        placeholder="e.g. Excel, Javascript, Photoshop"
                        id="text-input"
                        value={values.singleSkill}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="singleSkill"
                      />
                    </FormControl>
                  </Box>

                  <Box className="add-achievement-section">
                    <Button
                      variant="contained"
                      disableFocusRipple
                      disableRipple
                      disableElevation
                      className="form_next_btn"
                      onClick={() => handleAddSingleSkill(setFieldValue)}
                    >
                      Add
                    </Button>
                  </Box>

                  <Box className="chip-section">
                    {values.allSkills &&
                      values.allSkills.map((singleSkill, singleSkillIndex) => (
                        <Chip
                          className="chip"
                          key={singleSkill}
                          label={singleSkill}
                          onDelete={() =>
                            handleDeleteSingleSkill(singleSkill)
                          }
                        />
                      ))}
                  </Box>

                  <Box className="button-section">
                    {!isSubmitting && (
                      <Box>
                        {!formOnly && (
                          <button
                            type="button"
                            className="back-button inline"
                            onClick={handleBack}
                          >
                            Back
                          </button>
                        )}

                        <button
                          type="submit"
                          onClick={handleSubmit}
                          className="form_next_btn inline"
                        >
                          {formOnly ? 'Save' : 'Next Step'}
                        </button>
                      </Box>
                    )}
                    {isSubmitting && <CircularProgress />}
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        </Box>
      )}

      {isLoading && (
        <Container className="loading-container">
          <CircularProgress size={60} />
        </Container>
      )}
    </MainContent>
  );
});
Skills.displayName = 'Skills';
export default Skills;
