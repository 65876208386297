import {
  FORM_UPDATE_REQUEST_STARTED,
  FORM_UPDATE_REQUEST_FINISHED,
  GOTO_NEXT_ONBOARDINGROUTE,
  RESET_GOTO_NEXT_ONBOARDINGROUTE,
} from '../actionTypes';

const initialState = {
  isFormRequesting: false,
  gotoNextOnboardingRoute: false,
};

export function onboardingReducer(state = initialState, { type }) {
  //  Auth
  switch (type) {
    case FORM_UPDATE_REQUEST_STARTED:
      return {
        ...state,
        isFormRequesting: true,
      };

    case FORM_UPDATE_REQUEST_FINISHED:
      return {
        ...state,
        isFormRequesting: false,
      };

    case GOTO_NEXT_ONBOARDINGROUTE:
      return {
        ...state,
        gotoNextOnboardingRoute: true,
      };

    case RESET_GOTO_NEXT_ONBOARDINGROUTE:
      return {
        ...state,
        gotoNextOnboardingRoute: false,
      };

    default:
      return state;
  }
}
