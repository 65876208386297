import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import {
  Container,
  InputBase,
  Box,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

import { useTranslation } from 'react-i18next';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Publications from './accomplishmentForms/Publications';

import Projects from './accomplishmentForms/Projects';
import Honors from './accomplishmentForms/Honors';
import TestScore from './accomplishmentForms/TestScores';
import Patents from './accomplishmentForms/Patents';
import Courses from './accomplishmentForms/Courses';
import Language from './accomplishmentForms/Languages';
import Organization from './accomplishmentForms/Organizations';

const MainContent = styled.main`
  width: 100%;
  max-width: 775px;

  .title {
    font-family: 'Circular Std Bold';
    font-size: 32px;
    line-height: 32px;
    letter-spacing: 0em;
    /* text-align: left; */
    color: #131936;
  }

  .description {
    font-family: 'Circular Std Book';
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0em;
    /* text-align: left; */
    color: #131936;
    margin-bottom: 30px;
  }

  .form-container {
    padding: 0px;
    width: 100%;
  }

  .input-title {
    margin-bottom: 20px;
    font-family: 'Circular Std Bold' !important;
    font-style: normal;
    font-size: 16px !important;
    color: #272833;
  }

  .input-label {
    margin-bottom: 10px;
    font-family: 'Circular Std Book' !important;
    font-style: normal;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #272833;
  }

  .tip-section {
    display: flex;
    flex-direction: row;
    margin-top: 80px;
  }

  .tip-img-section {
    flex: 1;
  }

  .tip-content-section {
    flex: 14;
  }

  .tip-icon {
    width: 34px;
  }

  .tip-title {
    font-family: 'Circular Std Bold';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }

  .tip-description {
    font-family: 'Circular Std Book';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
  }

  .words-left {
    font-family: 'Circular Std Book';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #838383;
  }

  .button-section {
    margin-top: 40px;
    text-align: right;
  }
  .inline {
    margin-right: 45px;
    display: inline;

    :last-child {
      margin-right: 0px;
    }
  }

  .add-achievement-section {
    width: 100%;
    text-align: right;
  }

  .add-item-section {
    width: 100%;
    text-align: center;
  }

  .form_next_btn {
    min-width: 147px;
    height: 40px;
    padding: 0 16px;
    border-radius: 4px;
    background: #5c70cd;
    font-size: 16px;
    line-height: 29px;
    letter-spacing: 0em;
    border: none;
    color: white;
    cursor: pointer;
    text-transform: capitalize;
    font-family: 'Circular Std Book' !important;

    :hover {
      background: #5c70cd;
    }
  }
  .checkbox {
    .MuiFormControlLabel-label {
      font-family: 'Circular Std Book' !important;
      font-style: normal;
      font-size: 14px !important;
      font-weight: 500 !important;
    }
  }

  .back-button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  .input-section {
    margin-bottom: 25px;
  }

  .achievement-section {
    position: relative;
    margin-bottom: 25px;
    border: 1px solid #5c70cd;
    border-radius: 4px;
    padding: 5%;
    background: rgba(92, 112, 205, 0.01);
  }

  .loading-container {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
  }

  .item-section {
    position: relative;
    border: 1px solid #5c70cd;
    border-radius: 4px;
    padding: 4% 5% 5% 5%;
    margin-bottom: 25px;
    background: rgba(92, 112, 205, 0.05);
  }

  .Mui-error {
    border-radius: 4px;
    border: 1px solid red;
  }

  .error-text {
    color: red;
    font-size: 12px;
    padding: 5px;
  }

  .date-input {
    background-color: #f1f2f5 !important;
    .MuiOutlinedInput-input {
      font-family: Circular Std Book !important;
      width: 100% !important;
      font-size: 16px !important;
      color: rgb(39, 40, 51) !important;
    }
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #e7e7ed;
    }
  }

  .delete-button {
    position: absolute;
    right: 10px;
    top: 10px;
    color: red !important;
  }

  .accordion {
    box-shadow: none;
  }
  .accordion-summary {
    font-size: 20px;
    line-height: 20px;
    font-family: 'Circular Std Bold';
    border-bottom: 1px solid rgb(235, 235, 235);
  }
  .summary {
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .accordion-details {
    margin-top: 50px;
  }

  .skip-button {
    font-family: Circular Std Medium !important;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0em;
    float: right;
    color: rgb(92, 112, 205);
    cursor: pointer;
    outline: none;
    background: none;
    border: none;
  }
`;

//   border-bottom: 1px solid rgb(235, 235, 235);
const CustomTextArea = styled(InputBase)(() => ({
  'label + &': {
    marginTop: 40,
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#F1F2F5',
    border: '1px solid #E7E7ED',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    color: 'rgb(39, 40, 51)',
  },
  fontFamily: 'Circular Std Book !important',
  '&:focus': {
    borderColor: 'rgba(92, 112, 205, 0.13)',
  },
}));

const BootstrapInput = styled(InputBase)(() => ({
  'label + &': {
    marginTop: 40,
  },

  '& .MuiInputBase-root': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#F1F2F5',
    border: '1px solid #E7E7ED',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    fontFamily: 'Circular Std Book !important',
    '&:focus': {
      borderColor: 'rgba(92, 112, 205, 0.13)',
    },
  },
  '& .MuiInputBase-input': {
    fontFamily: 'Circular Std Book !important',
    width: '100%',
    color: 'rgb(39, 40, 51)',
  },
}));

const BootstrapSelectInput = styled(InputBase)(() => ({
  'label + &': {
    marginTop: 40,
  },
  '.MuiInputBase-root': {
    fontFamily: 'Circular Std Book !important',
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#F1F2F5',
    border: '1px solid #E7E7ED',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    fontFamily: 'Circular Std Book !important',
    '&:focus': {
      borderColor: 'rgba(92, 112, 205, 0.13)',
    },
  },
}));

const Accomplishments = React.forwardRef(
  ({ user, back, next, formOnly }, ref) => {
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);

    const [fromNav, setFromNav] = useState(false);

    useImperativeHandle(ref, () => ({
      async submit() {
        setFromNav(true);
      },
    }));

    const handleBack = () => {
      back();
    };

    const handleNext = () => {
      next();
    };

    useEffect(() => {}, []);

    return (
      <MainContent>
        {!isLoading && (
          <Box>
            {!formOnly && (
              <Box>
                <button
                  type="button"
                  className="skip-button"
                  onClick={() => next()}
                >
                  {t('Skip This')}
                </button>
                <p className="title">
                  {t('Have any accomplishments you’d like to add?')}
                </p>
              </Box>
            )}

            <Container id="form-section" className="form-container">
              <Accordion
                square
                disableGutters
                elevation={0}
                sx={{
                  '&:before': {
                    display: 'none',
                    boxShadow: 'none',
                    border: 'none !important',
                  },
                }}
              >
                <AccordionSummary
                  className="accordion-summary"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Box className="summary">{t('Publications')}</Box>
                </AccordionSummary>
                <AccordionDetails className="accordion-details">
                  <Publications
                    user={user}
                    back={handleBack}
                    next={handleNext}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion
                square
                disableGutters
                elevation={0}
                sx={{
                  '&:before': {
                    display: 'none',
                    boxShadow: 'none',
                    border: 'none !important',
                  },
                }}
              >
                <AccordionSummary
                  className="accordion-summary"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Box className="summary">{t('Patents')}</Box>
                </AccordionSummary>
                <AccordionDetails className="accordion-details">
                  <Patents user={user} back={handleBack} next={handleNext} />
                </AccordionDetails>
              </Accordion>
              <Accordion
                square
                disableGutters
                elevation={0}
                sx={{
                  '&:before': {
                    display: 'none',
                    boxShadow: 'none',
                    border: 'none !important',
                  },
                }}
              >
                <AccordionSummary
                  className="accordion-summary"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Box className="summary">{t('Courses')}</Box>
                </AccordionSummary>
                <AccordionDetails className="accordion-details">
                  <Courses user={user} back={handleBack} next={handleNext} />
                </AccordionDetails>
              </Accordion>
              <Accordion
                square
                disableGutters
                elevation={0}
                sx={{
                  '&:before': {
                    display: 'none',
                    boxShadow: 'none',
                    border: 'none !important',
                  },
                }}
              >
                <AccordionSummary
                  className="accordion-summary"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Box className="summary">{t('Projects')}</Box>
                </AccordionSummary>
                <AccordionDetails className="accordion-details">
                  <Projects user={user} back={handleBack} next={handleNext} />
                </AccordionDetails>
              </Accordion>
              <Accordion
                square
                disableGutters
                elevation={0}
                sx={{
                  '&:before': {
                    display: 'none',
                    boxShadow: 'none',
                    border: 'none !important',
                  },
                }}
              >
                <AccordionSummary
                  className="accordion-summary"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Box className="summary">{t('Honours & Awards')}</Box>
                </AccordionSummary>
                <AccordionDetails className="accordion-details">
                  <Honors user={user} back={handleBack} next={handleNext} />
                </AccordionDetails>
              </Accordion>
              <Accordion
                square
                disableGutters
                elevation={0}
                sx={{
                  '&:before': {
                    display: 'none',
                    boxShadow: 'none',
                    border: 'none !important',
                  },
                }}
              >
                <AccordionSummary
                  className="accordion-summary"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Box className="summary">{t('Test Scores')}</Box>
                </AccordionSummary>
                <AccordionDetails className="accordion-details">
                  <TestScore user={user} back={handleBack} next={handleNext} />
                </AccordionDetails>
              </Accordion>
              <Accordion
                square
                disableGutters
                elevation={0}
                sx={{
                  '&:before': {
                    display: 'none',
                    boxShadow: 'none',
                    border: 'none !important',
                  },
                }}
              >
                <AccordionSummary
                  className="accordion-summary"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Box className="summary">{t('Languages')}</Box>
                </AccordionSummary>
                <AccordionDetails className="accordion-details">
                  <Language user={user} back={handleBack} next={handleNext} />
                </AccordionDetails>
              </Accordion>
              <Accordion
                square
                disableGutters
                elevation={0}
                sx={{
                  '&:before': {
                    display: 'none',
                    boxShadow: 'none',
                    border: 'none !important',
                  },
                }}
              >
                <AccordionSummary
                  className="accordion-summary"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Box className="summary">{t('Organisations')}</Box>
                </AccordionSummary>
                <AccordionDetails className="accordion-details">
                  <Organization
                    user={user}
                    back={handleBack}
                    next={handleNext}
                  />
                </AccordionDetails>
              </Accordion>
              <Box className="button-section">
                {!isLoading && (
                  <Box>
                    {!formOnly && (
                      <Box>
                        <button
                          type="button"
                          className="back-button inline"
                          onClick={handleBack}
                        >
                          {t('Back')}
                        </button>
                        <button
                          type="submit"
                          onClick={handleNext}
                          className="form_next_btn inline"
                        >
                          {t('Next Step')}
                        </button>
                      </Box>
                    )}
                  </Box>
                )}
                {isLoading && <CircularProgress />}
              </Box>
            </Container>
          </Box>
        )}
        {isLoading && (
          <Container className="loading-container">
            <CircularProgress size={60} />
          </Container>
        )}
      </MainContent>
    );
  }
);
Accomplishments.displayName = 'Accomplishments';
export default Accomplishments;
