import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Formik, getIn } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';
import {
  Container,
  InputBase,
  Box,
  CircularProgress,
  FormControl,
  TextField,
  Select,
  MenuItem,
  Button,
  IconButton,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import tipIcon from '../../../../assets/images/tip_icon.png';
import apiClient from '../../../../api/server';

const _ = require('lodash');

const MainContent = styled.main`
  width: 100%;
  max-width: 775px;

  .title {
    font-family: 'Circular Std Bold';
    font-size: 32px;
    line-height: 32px;
    letter-spacing: 0em;
    /* text-align: left; */
    color: #131936;
    margin-bottom: 10px;
  }

  .description {
    font-family: 'Circular Std Book';
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0em;
    /* text-align: left; */
    color: #131936;
    margin-bottom: 30px;
  }

  .form-container {
    padding: 0px;
    width: 100%;
  }

  .input-title {
    margin-bottom: 20px;
    font-family: 'Circular Std Bold' !important;
    font-style: normal;
    font-size: 16px !important;
    color: #272833;
  }

  .input-label {
    margin-bottom: 10px;
    font-family: 'Circular Std Book' !important;
    font-style: normal;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #272833;
  }

  .tip-section {
    display: flex;
    flex-direction: row;
    margin-top: 80px;
  }

  .tip-img-section {
    flex: 1;
  }

  .tip-content-section {
    flex: 14;
  }

  .tip-icon {
    width: 34px;
  }

  .tip-title {
    font-family: 'Circular Std Bold';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }

  .tip-description {
    font-family: 'Circular Std Book';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
  }

  .words-left {
    font-family: 'Circular Std Book';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #838383;
  }

  .button-section {
    margin-top: 40px;
    text-align: right;
  }
  .inline {
    margin-right: 45px;
    display: inline;

    :last-child {
      margin-right: 0px;
    }
  }

  .add-achievement-section {
    width: 100%;
    text-align: right;
  }

  .add-experience-section {
    width: 100%;
    text-align: center;
  }

  .form_next_btn {
    min-width: 147px;
    height: 40px;
    padding: 0 16px;
    border-radius: 4px;
    background: #5c70cd;
    font-size: 16px;
    line-height: 29px;
    letter-spacing: 0em;
    border: none;
    color: white;
    cursor: pointer;
    text-transform: capitalize;
    font-family: 'Circular Std Book' !important;

    :hover {
      background: #5c70cd;
    }
  }

  .checkbox {
    .MuiFormControlLabel-label {
      font-family: 'Circular Std Book' !important;
      font-style: normal;
      font-size: 14px !important;
      font-weight: 500 !important;
    }
  }

  .back-button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  .input-section {
    margin-bottom: 25px;
  }

  .achievement-section {
    position: relative;
    margin-bottom: 25px;
    border: 1px solid #5c70cd;
    border-radius: 4px;
    padding: 5%;
    background: rgba(92, 112, 205, 0.01);
  }

  .loading-container {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
  }

  .item-section {
    position: relative;
    border: 1px solid #5c70cd;
    border-radius: 4px;
    padding: 4% 5% 5% 5%;
    margin-bottom: 25px;
    background: rgba(92, 112, 205, 0.05);
  }

  .Mui-error {
    border-radius: 4px;
    border: 1px solid red;
  }

  .error-text {
    color: red;
    font-size: 12px;
    padding: 5px;
  }

  .date-input {
    background-color: #f1f2f5 !important;
    .MuiOutlinedInput-input {
      font-family: Circular Std Book !important;
      width: 100% !important;
      font-size: 16px !important;
      color: rgb(39, 40, 51) !important;
    }
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #e7e7ed;
    }
  }

  .delete-button {
    position: absolute;
    right: 10px;
    top: 10px;
    color: red !important;
  }

  .remove-text {
    font-size: 14px !important;
    font-family: Circular Std Medium !important;
    margin-top: 3px;
  }

  .skip-button {
    font-family: Circular Std Medium !important;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0em;
    float: right;
    color: rgb(92, 112, 205);
    cursor: pointer;
    outline: none;
    background: none;
    border: none;
  }
`;

const CustomTextArea = styled(InputBase)(() => ({
  'label + &': {
    marginTop: 40,
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#F1F2F5',
    border: '1px solid #E7E7ED',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    color: 'rgb(39, 40, 51)',
  },
  fontFamily: 'Circular Std Book !important',
  '&:focus': {
    borderColor: 'rgba(92, 112, 205, 0.13)',
  },
}));

const BootstrapInput = styled(InputBase)(() => ({
  'label + &': {
    marginTop: 40,
  },

  '& .MuiInputBase-root': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#F1F2F5',
    border: '1px solid #E7E7ED',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    fontFamily: 'Circular Std Book !important',
    '&:focus': {
      borderColor: 'rgba(92, 112, 205, 0.13)',
    },
  },
  '& .MuiInputBase-input': {
    fontFamily: 'Circular Std Book !important',
    width: '100%',
    color: 'rgb(39, 40, 51)',
  },
}));

const BootstrapSelectInput = styled(InputBase)(() => ({
  'label + &': {
    marginTop: 40,
  },
  '.MuiInputBase-root': {
    fontFamily: 'Circular Std Book !important',
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#F1F2F5',
    border: '1px solid #E7E7ED',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    fontFamily: 'Circular Std Book !important',
    '&:focus': {
      borderColor: 'rgba(92, 112, 205, 0.13)',
    },
  },
}));

const Experience = React.forwardRef(({ user, back, next, formOnly }, ref) => {
  const formRef = useRef();
  const [data, setData] = useState([
    {
      id: '',
      index: 0,
      title: '',
      employmentType: '',
      companyName: '',
      companyLocation: '',
      startDate: moment('MMM YYYY').toDate(),
      endDate: moment('MMM YYYY').toDate(),
      active: false,
      achievement: [
        {
          id: '0-0',
          value: '',
        },
      ],
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [fromNav, setFromNav] = useState(false);

  useImperativeHandle(ref, () => ({
    async submit() {
      if (formRef.current) {
        setFromNav(true);
        await formRef.current.handleSubmit();
      }
    },
  }));

  const getData = async () => {
    const response = await apiClient.getExperience(user.id);
    if (response.status) {
      response.data.forEach((item, index) => {
        item.index = index;
        item.endDate = item.endDate ? item.endDate : moment().toDate();
        try {
          const parsedDetails = JSON.parse(item.achievement);
          item.achievement = parsedDetails;
        } catch (e) {
          if (item.achievement) {
            item.achievement = [
              {
                id: item.id ? `${item.id}-${item.index}` : `${item.index}-0`,
                value: item.achievement,
              },
            ];
          } else {
            item.achievement = [
              {
                id: item.id ? `${item.id}-${item.index}` : `${item.index}-0`,
                value: '',
              },
            ];
          }
        }

        item.achievement.forEach((achievement, achievementIndex) => {
          achievement.index = achievementIndex;
        });
      });

      if (response.data.length > 0) {
        setData(response.data);
      }

      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const validationSchema = yup.array().of(
    yup.object().shape({
      title: yup.string('Enter your Title').required('Title is required'),
      employmentType: yup
        .string('Select your Employment Type')
        .required('Employment Type is required'),
      companyName: yup
        .string('Enter your Company Name')
        .required('Company Name is required'),
      companyLocation: yup
        .string('Enter your Company Location')
        .required('Company Location is required'),
      startDate: yup
        .string('Enter your Start Date')
        .required('Start Date is required'),
      achievement: yup.array().of(
        yup.object().shape({
          value: yup
            .string('Enter your Achievement')
            .required('Achievement cannot be empty'),
        })
      ),
    })
  );

  const handleFormSubmit = async (values, setSubmitting) => {
    let response = '';

    const model = _.cloneDeep(values);

    model.forEach((item) => {
      item.achievement = JSON.stringify(item.achievement);
    });

    response = await apiClient.postExperience(user.id, model);

    if (response.status) {
      if (!formOnly) {
        if (fromNav) {
          next(true);
        } else {
          next();
        }
      } else {
        toast.success('Update Successful');
        setSubmitting(false);
      }
    } else {
      toast.error(response.message);
      setSubmitting(false);
    }
  };

  const handleBack = () => {
    back();
  };

  const handleAddAchievement = (index, setFieldValue) => {
    const array = formRef.current.values[index].achievement;
    array.push({
      id: `${index}-${formRef.current.values[index].achievement.length}`,
      value: '',
    });
    setFieldValue(`[${index}].achievement`, array, false);
  };

  const handleDeleteAchievement = (index, achievementIndex, setFieldValue) => {
    const array = formRef.current.values[index].achievement;
    array.splice(achievementIndex, 1);
    setFieldValue(`[${index}].achievement`, array, false);
  };

  const handleAddExperience = (setFieldValue) => {
    const array = formRef.current.values;
    array.push({
      id: '',
      index: array.length,
      title: '',
      employmentType: '',
      companyName: '',
      companyLocation: '',
      startDate: moment().toDate(),
      endDate: moment().toDate(),
      active: false,
      achievement: [
        {
          id: `${array.length}-0`,
          value: '',
        },
      ],
    });
    setFieldValue(``, array, false);
  };

  const handleDeleteExperience = (index, setFieldValue) => {
    const array = formRef.current.values;
    array.splice(index, 1);
    setFieldValue(``, array, false);
  };

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, []);

  return (
    <MainContent>
      {!isLoading && (
        <Box>
          {!formOnly && (
            <Box>
              <button
                type="button"
                className="skip-button"
                onClick={() => next()}
              >
                Skip This
              </button>
              <p className="title">Work Experience</p>
              <p className="description">
                Give us all the details on the work experiences you&apos;ve had
                over time. It&apos;ll be nice to tailor this section to the
                role(s) you will be applying to with this resume.
              </p>
            </Box>
          )}

          <Container id="form-section" className="form-container">
            <Formik
              innerRef={formRef}
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={data}
              onSubmit={(values, { setSubmitting }) => {
                handleFormSubmit(values, setSubmitting);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  {values.map((item, index) => (
                    <Box key={item.index}>
                      <Box className="item-section">
                        <p className="input-title">Experience #{index + 1}</p>
                        <Box className="input-section">
                          <p className="input-label">Title</p>
                          <IconButton
                            aria-label="delete"
                            component="span"
                            disableFocusRipple
                            className="delete-button"
                            onClick={() =>
                              handleDeleteExperience(index, setFieldValue)
                            }
                          >
                            <DeleteIcon />
                            <span className="remove-text">Remove</span>
                          </IconButton>
                          <FormControl
                            className="form-container"
                            variant="standard"
                          >
                            <BootstrapInput
                              placeholder="Title"
                              id="text-input"
                              value={item.title}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name={`[${index}].title`}
                              error={
                                getIn(errors, `[${index}].title`) &&
                                getIn(touched, `[${index}].title`)
                              }
                            />
                            {getIn(errors, `[${index}].title`) &&
                            getIn(touched, `[${index}].title`) ? (
                              <Box className="error-text">
                                {getIn(errors, `[${index}].title`)}
                              </Box>
                            ) : null}
                          </FormControl>
                        </Box>

                        <Box className="input-section">
                          <p className="input-label">Employment Type</p>
                          <FormControl className="form-container">
                            <Select
                              id="employment-type-select"
                              value={item.employmentType}
                              onChange={handleChange}
                              input={<BootstrapSelectInput />}
                              displayEmpty
                              name={`[${index}].employmentType`}
                              error={
                                getIn(errors, `[${index}].employmentType`) &&
                                getIn(touched, `[${index}].employmentType`)
                              }
                            >
                              <MenuItem value="" disabled>
                                Select Employment Type
                              </MenuItem>
                              <MenuItem value="student">Student</MenuItem>
                              <MenuItem value="parttime">Part Time</MenuItem>
                              <MenuItem value="fulltime">Full Time</MenuItem>
                              <MenuItem value="selfemployed">
                                Self Employed
                              </MenuItem>
                              <MenuItem value="unemployed">Unemployed</MenuItem>
                            </Select>
                            {getIn(errors, `[${index}].employmentType`) &&
                            getIn(touched, `[${index}].employmentType`) ? (
                              <Box className="error-text">
                                {getIn(errors, `[${index}].employmentType`)}
                              </Box>
                            ) : null}
                          </FormControl>
                        </Box>

                        <Box className="input-section">
                          <p className="input-label">Company Name</p>
                          <FormControl
                            className="form-container"
                            variant="standard"
                          >
                            <BootstrapInput
                              placeholder="Company Name"
                              id="text-input"
                              value={item.companyName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name={`[${index}].companyName`}
                              error={
                                getIn(errors, `[${index}].companyName`) &&
                                getIn(touched, `[${index}].companyName`)
                              }
                            />
                            {getIn(errors, `[${index}].companyName`) &&
                            getIn(touched, `[${index}].companyName`) ? (
                              <Box className="error-text">
                                {getIn(errors, `[${index}].companyName`)}
                              </Box>
                            ) : null}
                          </FormControl>
                        </Box>

                        <Box className="input-section">
                          <p className="input-label">
                            Where is this company based?
                          </p>
                          <FormControl
                            className="form-container"
                            variant="standard"
                          >
                            <BootstrapInput
                              placeholder="Where is this company based?"
                              id="text-input"
                              value={item.companyLocation}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name={`[${index}].companyLocation`}
                              error={
                                getIn(errors, `[${index}].companyLocation`) &&
                                getIn(touched, `[${index}].companyLocation`)
                              }
                            />
                            {getIn(errors, `[${index}].companyLocation`) &&
                            getIn(touched, `[${index}].companyLocation`) ? (
                              <Box className="error-text">
                                {getIn(errors, `[${index}].companyLocation`)}
                              </Box>
                            ) : null}
                          </FormControl>
                        </Box>

                        <Box className="input-section">
                          <p className="input-label">
                            When did you start at this role?
                          </p>

                          <FormControl
                            className="form-container"
                            variant="standard"
                          >
                            <MobileDatePicker
                              clearable
                              inputFormat="MM/YYYY"
                              views={['year', 'month']}
                              placeholder="When did you start at this role?"
                              value={item.startDate}
                              onChange={(value) =>
                                setFieldValue(`[${index}].startDate`, value)
                              }
                              onBlur={handleBlur}
                              error={
                                getIn(errors, `[${index}].startDate`) &&
                                getIn(touched, `[${index}].startDate`)
                              }
                              name={`[${index}].startDate`}
                              renderInput={(params) => (
                                <TextField className="date-input" {...params} />
                              )}
                            />

                            {getIn(errors, `[${index}].startDate`) &&
                            getIn(touched, `[${index}].startDate`) ? (
                              <Box className="error-text">
                                {getIn(errors, `[${index}].startDate`)}
                              </Box>
                            ) : null}
                          </FormControl>
                        </Box>
                        <Box className="input-section">
                          <FormControlLabel
                            className="checkbox"
                            control={
                              <Checkbox
                                checked={item.active}
                                onChange={handleChange}
                                sx={{
                                  '&.Mui-checked': {
                                    color: '#5c70cd',
                                  },
                                }}
                                name={`[${index}].active`}
                              />
                            }
                            label="I currently work here"
                          />
                        </Box>
                        {!item.active && (
                          <Box className="input-section">
                            <p className="input-label">
                              When did you end this role?
                            </p>

                            <FormControl
                              className="form-container"
                              variant="standard"
                              error={false}
                            >
                              <MobileDatePicker
                                clearable
                                inputFormat="MM/YYYY"
                                views={['year', 'month']}
                                placeholder="When did you end at this role?"
                                value={item.endDate}
                                onChange={(value) =>
                                  setFieldValue(`[${index}].endDate`, value)
                                }
                                onBlur={handleBlur}
                                name={`[${index}].endDate`}
                                error={false}
                                renderInput={(params) => (
                                  <TextField
                                    error={false}
                                    className="date-input"
                                    {...params}
                                  />
                                )}
                              />
                            </FormControl>
                          </Box>
                        )}

                        <p className="input-label">
                          Share any achievements and what your role entailed
                          here
                        </p>

                        <br />

                        {item.achievement &&
                          item.achievement.map(
                            (achievement, achievementIndex) => (
                              <Box
                                className="achievement-section"
                                key={`${achievement.id}`}
                              >
                                <p className="input-label">
                                  Achievement #{achievementIndex + 1}
                                </p>
                                <IconButton
                                  aria-label="delete"
                                  component="span"
                                  disableFocusRipple
                                  className="delete-button"
                                  onClick={() =>
                                    handleDeleteAchievement(
                                      index,
                                      achievementIndex,
                                      setFieldValue
                                    )
                                  }
                                >
                                  <DeleteIcon />
                                  <span className="remove-text">Remove</span>
                                </IconButton>
                                <FormControl
                                  className="form-container"
                                  variant="standard"
                                >
                                  <CustomTextArea
                                    placeholder="Share any achievement and what your role entailed here"
                                    id="custom-textarea-input"
                                    multiline
                                    rows={3}
                                    value={achievement.value}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name={`[${index}].achievement.[${achievementIndex}].value`}
                                    error={
                                      getIn(
                                        errors,
                                        `[${index}].achievement.[${achievementIndex}].value`
                                      ) &&
                                      getIn(
                                        touched,
                                        `[${index}].achievement.[${achievementIndex}].value`
                                      )
                                    }
                                  />
                                  {getIn(
                                    errors,
                                    `[${index}].achievement.[${achievementIndex}].value`
                                  ) &&
                                  getIn(
                                    touched,
                                    `[${index}].achievement.[${achievementIndex}].value`
                                  ) ? (
                                    <Box className="error-text">
                                      {getIn(
                                        errors,
                                        `[${index}].achievement.[${achievementIndex}].value`
                                      )}
                                    </Box>
                                  ) : null}
                                </FormControl>
                              </Box>
                            )
                          )}
                        <Box className="add-achievement-section">
                          <Button
                            variant="contained"
                            disableFocusRipple
                            disableRipple
                            disableElevation
                            className="form_next_btn"
                            onClick={() =>
                              handleAddAchievement(index, setFieldValue)
                            }
                            startIcon={<AddIcon />}
                          >
                            Add Achievement
                          </Button>
                        </Box>
                      </Box>
                      {/* {(index === values.length - 1) && (
                        <Box className="add-experience-section">
                          <Button
                            variant="contained"
                            disableFocusRipple
                            disableRipple
                            disableElevation
                            className="form_next_btn"
                            onClick={() =>
                              handleAddExperience(setFieldValue)
                            }
                            startIcon={<AddIcon />}
                          >
                            Add Experience
                          </Button>
                        </Box>
                      )} */}
                    </Box>
                  ))}
                  <Box className="add-experience-section">
                    <Button
                      variant="contained"
                      disableFocusRipple
                      disableRipple
                      disableElevation
                      className="form_next_btn"
                      onClick={() => handleAddExperience(setFieldValue)}
                      startIcon={<AddIcon />}
                    >
                      Add Experience
                    </Button>
                  </Box>
                  <Box className="button-section">
                    {!isSubmitting && (
                      <Box>
                        {!formOnly && (
                          <button
                            type="button"
                            className="back-button inline"
                            onClick={handleBack}
                          >
                            Back
                          </button>
                        )}

                        <button
                          type="submit"
                          onClick={handleSubmit}
                          className="form_next_btn inline"
                        >
                          {formOnly ? 'Save' : 'Next Step'}
                        </button>
                      </Box>
                    )}
                    {isSubmitting && <CircularProgress />}
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        </Box>
      )}
      <Container id="tip-section" className="tip-section">
        <Box className="tip-img-section">
          <img className="tip-icon" src={tipIcon} alt="bulb icon" />
        </Box>
        <Box className="tip-content-section">
          <p className="tip-title">Tips to help you</p>
          <p className="tip-description">
            Give us all the details on the work experiences you&apos;ve had over
            time. It&apos;ll be nice to tailor this section to the role(s) you
            will be applying to with this resume. Use numbers to add context and
            showcase some accomplishments.{' '}
            <em>
              E.g. Improved user experience by reducing system downtime to 1%.{' '}
            </em>
          </p>
        </Box>
      </Container>
      {isLoading && (
        <Container className="loading-container">
          <CircularProgress size={60} />
        </Container>
      )}
    </MainContent>
  );
});
Experience.displayName = 'Experience';
export default Experience;
