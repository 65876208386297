import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Navbar from '../../../components/Navbar';
import { forgotpassword } from '../../../redux/actions';
import {
  MainWrapper,
  FormWrapper,
  ForgotPasswordText,
  MainLoginButton,
} from '../styles';

const ForgotPassword = () => {
  const [emailAddress, setEmailAddress] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const handleForgotPasswordReq = async (e) => {
    setIsSubmitting(true);
    e.preventDefault();
    if (newPassword === confirmNewPassword) {
      await dispatch(forgotpassword({ email: emailAddress, newPassword }));
      setIsSubmitting(false);
    } else {
      toast.error('Passwords do not match.');
      setIsSubmitting(false);
    }
  };

  // const dispatch = useDispatch();

  return (
    <>
      <Navbar />
      <MainWrapper>
        <FormWrapper>
          <form onSubmit={handleForgotPasswordReq}>
            <p className="login-caption">Forgot password</p>
            <label htmlFor="forgotpassword">
              Email Address
              <input
                type="email"
                name="forgotpassword"
                id="forgotpassword"
                placeholder="Enter your email address"
                // className={errors.email && touched.email && 'rez_input_error'}
                onChange={(e) => setEmailAddress(e.target.value)}
                value={emailAddress}
                required
              />
            </label>
            <label htmlFor="forgotpassword">
              New Password
              <input
                type="password"
                name="newpassword"
                id="newpassword"
                placeholder="Enter your new password"
                // className={errors.email && touched.email && 'rez_input_error'}
                onChange={(e) => setNewPassword(e.target.value)}
                value={newPassword}
                required
              />
            </label>
            <label htmlFor="forgotpassword">
              Confirm New Password
              <input
                type="password"
                name="confirmNewPassword"
                id="confirmNewPassword"
                placeholder="Confirm your new password"
                // className={errors.email && touched.email && 'rez_input_error'}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                value={confirmNewPassword}
                required
              />
            </label>
            <MainLoginButton type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Updating...' : 'Update Password'}
            </MainLoginButton>
            <ForgotPasswordText>
              <Link to="/resumeandcoverletterbuilder/login">
                Exit password recovery
              </Link>
            </ForgotPasswordText>
          </form>
        </FormWrapper>
      </MainWrapper>
    </>
  );
};

export default ForgotPassword;
