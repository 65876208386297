export default {
  isMyObjectEmpty(myObject) {
    return myObject && !Object.keys(myObject).length;
  },

  formatter(dateVal) {
    return `${dateVal.getFullYear()}-${
      dateVal.getMonth() + 1 < 10
        ? `0${dateVal.getMonth() + 1}`
        : dateVal.getMonth() + 1
    }-${dateVal.getDate() < 10 ? `0${dateVal.getDate()}` : dateVal.getDate()}`;
  },

  hoursDifference(start, finish) {
    const difference = (Math.abs(
      new Date(start) - new Date(finish),
    ) / 3600000).toFixed(2);

    return difference;
  },
};
