import React from 'react';

const SingleOrganization = ({
  values,
  OrganizationIndex,
  touched,
  initialValues,
  setinitialValues,
  indexToSubmit,
  setIndexToSubmit,
  isFormRequesting,
}) => {
  const updateArray = (e) => {
    const caret = e.target.selectionStart;
    const element = e.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });
    const newArr = [...initialValues]; // copying the old datas array
    if (e.target.type === 'checkbox') {
      newArr[OrganizationIndex] = {
        ...values[OrganizationIndex],
        [e.target.name]: e.target.checked,
      }; // replace e.target.value with whatever you want to change it to
    } else
      newArr[OrganizationIndex] = {
        ...values[OrganizationIndex],
        [e.target.name]: e.target.value,
      }; // replace e.target.value with whatever you want to change it to

    setinitialValues(newArr);
  };
  return (
    <div className="single__Publication_wrapper">
      <label>
        <span className="important-field">Title</span>
        <br />
        <input
          type="text"
          name="name"
          value={values[OrganizationIndex]?.name}
          className={
            values[OrganizationIndex]?.name?.length === 0 &&
            touched[OrganizationIndex] &&
            'rez_input_error'
          }
          required
          placeholder="Name of Organizaion"
          onChange={updateArray}
        />
      </label>
      <label>
        <span className="">Position Held</span>
        <br />
        <input
          type="text"
          placeholder="e.g  Director"
          name="positionHeld"
          value={values[OrganizationIndex]?.positionHeld}
          onChange={updateArray}
        />
      </label>
      <label>
        <span className="important-field">Associated with</span>
        <br />
        <input
          type="text"
          name="associatedWith"
          className={
            values[OrganizationIndex]?.associatedWith?.length === 0 &&
            touched[OrganizationIndex]
              ? 'rez_input_error'
              : ''
          }
          onChange={updateArray}
          value={values[OrganizationIndex]?.associatedWith}
          required
        />
      </label>
      <label>
        <span className="important-field">
          When did you join this organization?
        </span>
        <br />
        <input
          type="date"
          name="startDate"
          className={
            values[OrganizationIndex]?.startDate?.length === 0 &&
            touched[OrganizationIndex] &&
            'rez_input_error'
          }
          value={
            values[OrganizationIndex]?.startDate &&
            new Date(values[OrganizationIndex].startDate)
              .toISOString()
              .substring(0, 10)
          }
          required
          onChange={updateArray}
        />
      </label>
      <input
        type="checkbox"
        name="active"
        onChange={updateArray}
        checked={values[OrganizationIndex].active}
      />
      &nbsp;&nbsp; I am still a member here
      <br />
      <br />
      {!values[OrganizationIndex].active && (
        <label>
          When did you leave this association?
          <br />
          <input
            type="date"
            name="endDate"
            value={
              values[OrganizationIndex]?.endDate &&
              new Date(values[OrganizationIndex].endDate)
                .toISOString()
                .substring(0, 10)
            }
            required={!values[OrganizationIndex].active}
            className={
              !values[OrganizationIndex].active &&
              values[OrganizationIndex]?.endDate?.length === 0 &&
              touched[OrganizationIndex] &&
              'rez_input_error'
            }
            onChange={updateArray}
          />
        </label>
      )}
      <label>
        Description
        <br />
        <textarea
          type="text"
          placeholder="E.g Microsoft"
          name="description"
          value={values[OrganizationIndex]?.description}
          className={
            values[OrganizationIndex]?.description?.length === 0 &&
            touched[OrganizationIndex] &&
            'rez_input_error'
          }
          onChange={updateArray}
        />
      </label>
      {/* {values.length > 1 && (
      <div>
        <button
          type="submit"
          className="form_next_btn"
          onClick={() => setIndexToSubmit(OrganizationIndex)}
          disabled={isFormRequesting && indexToSubmit === OrganizationIndex}
        >
          {isFormRequesting && indexToSubmit === OrganizationIndex
            ? 'Saving...'
            : 'Save Changesd'}
        </button>
        <div className="clearfix" />
      </div> */}
      {/* )} */}
      <button
        type="submit"
        className="form_next_btn"
        onClick={() => setIndexToSubmit(OrganizationIndex)}
        disabled={isFormRequesting && indexToSubmit === OrganizationIndex}
      >
        {isFormRequesting && indexToSubmit === OrganizationIndex
          ? 'Saving...'
          : 'Save Changes'}
      </button>
    </div>
  );
};

export default SingleOrganization;
