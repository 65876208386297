import React from 'react';
import { Grid } from '@mui/material';
import { SectionHeading, AboutText, Wrapper, ImageWrapper } from './styles';
import svg from '../../../../assets/images/landing-3.png';

function Aboutpage() {
  return (
    <Wrapper>
      <Grid container rowSpacing={{xs: 8, md: 4}}>
        <Grid item sm={6}>
          <SectionHeading>
            Don&apos;t miss out on good job opportunities
          </SectionHeading>
          <AboutText>
            You shouldn&apos;t miss out on the right opportunities because you
            have the wrong resume. Sadly, that happens more often than we&apos;d
            like. A bad resume can cost you an opportunity that you are
            qualified for. Our job is to ensure that doesn&apos;t happen
            (again). So give yourself the chance to impress recruiters. Use our
            well-tailored resume templates to get your feet into the door.
          </AboutText>
        </Grid>
        <Grid item sm={6}>
          <ImageWrapper>
            <img src={svg} alt="svg" />
          </ImageWrapper>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Aboutpage;
