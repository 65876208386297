import React from 'react';
// import { Field } from 'formik';
// import { CheckLabel } from '../../components/styles';

const SingleLicense = ({
  values,
  licenseIndex,
  touched,
  indexToSubmit,
  setIndexToSubmit,
  isFormRequesting,
  initialValues,
  setinitialValues,
}) => {
  const updateArray = (e) => {
    const caret = e.target.selectionStart;
    const element = e.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });
    const newArr = [...initialValues]; // copying the old datas array
    newArr[licenseIndex] = {
      ...values[licenseIndex],
      [e.target.name]: e.target.value,
    }; // replace e.target.value with whatever you want to change it to

    setinitialValues(newArr);
  };
  return (
    <div className="single__license_wrapper">
      <label>
        <span className="important-field">Name</span>
        <br />
        <input
          type="text"
          placeholder="E.g microsoft certification"
          name="nameOfCertificate"
          value={values[licenseIndex]?.nameOfCertificate}
          onChange={updateArray}
          className={
            values[licenseIndex]?.nameOfCertificate?.length === 0 &&
            touched[licenseIndex] &&
            'rez_input_error'
          }
          required
        />
      </label>
      <label>
        <span className="important-field">Issuing organisation</span>
        <br />
        <input
          type="text"
          placeholder="E.g Microsoft"
          name="issuingOrganization"
          value={values[licenseIndex]?.issuingOrganization}
          onChange={updateArray}
          className={
            values[licenseIndex]?.issuingOrganization?.length === 0 &&
            touched[licenseIndex] &&
            'rez_input_error'
          }
          required
        />
      </label>

      <label>
        <input
          type="checkbox"
          name="canExpire"
          value={values[licenseIndex]?.canExpire}
          onChange={updateArray}
        />
        {`  `}
        This credential does not expire
        <br />
        <br />
      </label>

      <label>
        <span className="important-field">Issue Date</span>
        <br />
        <input
          type="date"
          name="issueDate"
          onChange={updateArray}
          className={
            values[licenseIndex]?.issueDate?.length === 0 &&
            touched[licenseIndex] &&
            'rez_input_error'
          }
          value={
            values[licenseIndex]?.issueDate &&
            new Date(values[licenseIndex].issueDate)
              .toISOString()
              .substring(0, 10)
          }
          required
        />
      </label>

      {!values[licenseIndex].canExpire && (
        <label>
          Expiration Date
          <br />
          <input
            type="date"
            name="expirationDate"
            onChange={updateArray}
            value={
              values[licenseIndex]?.expirationDate &&
              new Date(values[licenseIndex].expirationDate)
                .toISOString()
                .substring(0, 10)
            }
            required={values[licenseIndex].canExpire}
            className={
              values[licenseIndex]?.canExpire &&
              values[licenseIndex]?.expirationDate?.length === 0 &&
              touched[licenseIndex] &&
              'rez_input_error'
            }
          />
        </label>
      )}

      <label>
        Credential ID
        <br />
        <input
          type="text"
          placeholder="E.g Microsoft"
          name="credentialId"
          value={values[licenseIndex]?.credentialId}
          onChange={updateArray}
          className={
            values[licenseIndex]?.credentialId?.length === 0 &&
            touched[licenseIndex] &&
            'rez_input_error'
          }
        />
      </label>

      <label>
        Credential URL
        <br />
        <input
          type="text"
          placeholder="E.g Microsoft"
          name="credentialUrl"
          value={values[licenseIndex]?.credentialUrl}
          onChange={updateArray}
        />
      </label>

      <div>
        <button
          type="submit"
          className="form_next_btn"
          onClick={() => setIndexToSubmit(licenseIndex)}
          disabled={isFormRequesting && indexToSubmit === licenseIndex}
        >
          {isFormRequesting && indexToSubmit === licenseIndex
            ? 'Saving...'
            : 'Save Changes'}
        </button>
        <div className="clearfix" />
      </div>
    </div>
  );
};

export default SingleLicense;
