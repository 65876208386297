import {
  SET_ACTIVE_TEMPLATE,
} from '../actionTypes';

const initialState = {
  activeTemplate: null,
};

export function templateReducer(state = initialState, { type, payload }) {
  //  Auth
  switch (type) {
    case SET_ACTIVE_TEMPLATE:
      return {
        ...state,
        activeTemplate: payload,
      };

    default:
      return state;
  }
}
