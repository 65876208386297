// Auth Types
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const FORM_UPDATE_REQUEST_STARTED = 'FORM_UPDATE_REQUEST_STARTED';
export const FORM_UPDATE_REQUEST_FINISHED = 'FORM_UPDATE_REQUEST_FINISHED';
export const GOTO_NEXT_ONBOARDINGROUTE = 'GOTO_NEXT_ONBOARDINGROUTE';
export const RESET_GOTO_NEXT_ONBOARDINGROUTE = 'RESET_GOTO_NEXT_ONBOARDINGROUTE';
export const SET_ACTIVE_TEMPLATE = 'SET_ACTIVE_TEMPLATE';
export const LOG_OUT = 'LOG_OUT';
