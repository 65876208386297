import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Fab } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import apiClient from '../../api/server';
import Wrapper from './layout/Wrapper';

const FileSaver = require('file-saver');

const ViewTemplate = () => {
  const { t, i18n } = useTranslation();
  const [image, setImage] = useState('');
  const { user } = useSelector((state) => state.auth);
  const params = useParams();

  const getPreview = async () => {
    const res = await apiClient.getTemplatePreview(user.id, params.slug);
    if (res.status) {
      setImage(res.data);
    }
  };

  // function dataURItoBlob(dataURI) {
  //   const byteString = atob(dataURI.split(',')[1]);
  //   const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  //   const ab = new ArrayBuffer(byteString.length);
  //   const ia = new Uint8Array(ab);

  //   for (let i = 0; i < byteString.length; i += 1) {
  //     ia[i] = byteString.charCodeAt(i);
  //   }

  //   const blob = new Blob([ab], { type: mimeString });
  //   return blob;
  // }
  const downloadURI = (base64, name) => {
    const iOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isChrome =
      navigator.userAgent.toLowerCase().indexOf('CriOS') > -1 ||
      navigator.vendor.toLowerCase().indexOf('google') > -1;
    let iOSVersion = [];
    if (iOS) {
      iOSVersion = navigator.userAgent
        .match(/OS [\d_]+/i)[0]
        .substr(3)
        .split('_')
        .map((n) => Number(n));
    }

    const attachmentData = base64;
    let contentType = 'application/pdf';

    const binary = atob(attachmentData.replace(/\s/g, ''));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);

    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }

    let hrefUrl = '';
    let blob = '';

    if (iOS && !isChrome && iOSVersion[0] <= 12) {
      blob = `data:application/pdf;base64,${attachmentData}`;
      hrefUrl = blob;
    } else {
      if (iOS && !isChrome) {
        contentType = 'application/octet-stream';
      }
      blob = new Blob([view], { type: contentType });
      hrefUrl = window.URL.createObjectURL(blob);
    }

    const a = document.createElement('a');

    a.setAttribute('href', hrefUrl);
    a.setAttribute('target', '_blank');
    if ((iOS && (iOSVersion[0] > 12 || isChrome)) || !iOS) {
      a.setAttribute('download', name);
    }

    const clickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: false,
    });

    a.dispatchEvent(clickEvent);
  };

  const downloadTemplate = async () => {
    const res = await apiClient.getTemplatePdf(user.id, params.slug);

    if (res.status) {
      toast.success('Template downloaded successfully');
      const isMobile = window.matchMedia(
        'only screen and (max-width: 1024px)'
      ).matches;

      // FileSaver.saveAs(res.data, 'resume.pdf', {
      //   type: 'application/octet-stream',
      // });

      if (isMobile) {
        downloadURI(res.data.base64Data, 'resume.pdf');
      } else {
        window.open(res.data.url, '_blank');
      }
    }
  };

  useEffect(() => {
    getPreview();
  }, []);

  return (
    <Wrapper>
      <Fab
        onClick={downloadTemplate}
        variant="extended"
        size="medium"
        sx={{
          background: '#5c70cd',
          '&:hover': {
            backgroundColor: '#5c70cd',
            color: '#FFFFFF',
          },
          color: '#FFFFFF',
          position: 'fixed',
          borderRadius: '4px',
          bottom: 25,
          left: 25,
        }}
      >
        <DownloadIcon sx={{ marginRight: '5px' }} />
        {t('Download this template')}
      </Fab>
      <Box>
        {image && (
          <Box
            sx={{
              maxWidth: 900,
            }}
          >
            <img style={{ width: '100%' }} src={image} alt="" />
          </Box>
        )}
        {!image && <CircularProgress />}
      </Box>
      <Link to="/selecttemplate">
        <Fab
          variant="extended"
          size="medium"
          sx={{
            background: '#5c70cd',
            '&:hover': {
              backgroundColor: '#5c70cd',
              color: '#FFFFFF',
            },
            color: '#FFFFFF',
            position: 'fixed',
            borderRadius: '4px',
            top: {
              xs: 70,
              md: 'unset',
            },
            bottom: { md: 25 },
            left: { xs: 25, md: 'unset' },
            right: { md: 25 },
          }}
        >
          <ChangeCircleOutlinedIcon sx={{ marginRight: '5px' }} />
          {t('Change this template')}
        </Fab>
      </Link>
    </Wrapper>
  );
};

export default ViewTemplate;
