import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import apiClient from '../../api/server';
import Layout from '../layout/Layout';
import Heading from './components/Heading&Caption';
import star from '../../assets/images/star 1.svg';
import Capsule from '../../components/Capsule';
import ContactForm from './components/forms/contact/ContactForm';
import HeadlineForm from './components/forms/headline/headlineForm';
import Eduform from './components/forms/education/Eduform';
import ExperienceForm from './components/forms/workexperience/ExperienceForm';
import VolunteerForm from './components/forms/volunteer/VolunteerForm';
import LicenseForm from './components/forms/licenseandcertification/LicenseForm';
import AccomplishmentForm from './components/forms/accomplishments';
import Skillform from './components/forms/skills/skillForm';

const MainContent = styled.main`
  width: 100%;
  max-width: 672px;

  .heading_caption {
    font-family: 'Circular Std Medium';
    font-size: 38px;
    line-height: 38px;
    letter-spacing: 0em;
    /* text-align: left; */
    color: ${({ theme }) => theme.baseHeadingDark};
    margin-bottom: 37px;
  }

  .info-text {
    font-size: 18px;
    line-height: 30px;
    color: #4b5377;
    margin-top: 40px;
  }
`;

const Footer = styled.footer`
  margin-top: 50px;
  display: flex;
  align-items: center;

  span {
    font-size: 18px;
    letter-spacing: 0em;
    color: #131936;
    margin-right: 30px;
  }

  .form_next_btn {
    height: 40px;
    padding: 0 16px;
    border-radius: 4px;
    background: #5c70cd;
    font-size: 16px;
    line-height: 29px;
    letter-spacing: 0em;
    border: none;
    color: white;
    cursor: pointer;
  }
`;

const Preview = () => {
  const { t, i18n } = useTranslation();
  const [fieldsShown, setFieldsShown] = useState({
    contactShown: false,
    headineShown: false,
    experienceShown: false,
    eduShown: false,
    volunterrShown: false,
    licenseShown: false,
    accShown: false,
    skillShown: false,
  });
  const { user } = useSelector((state) => state.auth);
  const [starState, setStarState] = useState(0);
  const [userData, setUserData] = useState({});

  const getProfile = async () => {
    const res = await apiClient.getUserProfile(user.id);
    if (res.status) {
      setUserData(res.data);
      if (res?.data?.headline?.details !== ''?.length > 0) {
        setStarState((i) => i + 5);
      }
      if (res?.data?.educations?.length > 0) {
        setStarState((i) => i + 5);
      }
      if (res?.data?.workExperiences?.length > 0) {
        setStarState((i) => i + 5);
      }
      if (res?.data?.volunteerHistories?.length > 0) {
        setStarState((i) => i + 5);
      }
      if (res?.data?.skills?.length > 0) {
        setStarState((i) => i + 5);
      }
      if (res?.data?.contactInformation?.id?.length > 0) {
        setStarState((i) => i + 5);
      }
      if (res?.data?.licenseAndCertificates?.length > 0) {
        setStarState((i) => i + 5);
      }
      if (res?.data?.publications) {
        setStarState((i) => i + 1);
      }
      if (res?.data?.patents) {
        setStarState((i) => i + 1);
      }
      if (res?.data?.courses) {
        setStarState((i) => i + 1);
      }
      if (res?.data?.projects) {
        setStarState((i) => i + 1);
      }
      if (res?.data?.honours) {
        setStarState((i) => i + 1);
      }
      if (res?.data?.tests) {
        setStarState((i) => i + 1);
      }
      if (res?.data?.languages) {
        setStarState((i) => i + 1);
      }
      if (res?.data?.organisations) {
        setStarState((i) => i + 1);
      }
    }
  };
  const toggleFieldShown = (fieldName) => {
    setFieldsShown({
      ...fieldsShown,
      [fieldName]: !fieldsShown[fieldName],
    });
  };

  useEffect(() => {
    getProfile();
  }, []);

  // console.log('data', userData);

  return (
    <Layout>
      <MainContent>
        <p
          className="heading_caption"
          style={{
            textAlign: 'center',
          }}
        >
          {t('Your resume has')} {Math.round(starState * 0.125)} {t('stars')} 🎉
        </p>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          {Math.round(starState * 0.125) === 5 && (
            <div>
              <img src={star} alt="stars" style={{ marginRight: '10px' }} />
              <img src={star} alt="stars" style={{ marginRight: '10px' }} />
              <img src={star} alt="stars" style={{ marginRight: '10px' }} />
              <img src={star} alt="stars" style={{ marginRight: '10px' }} />
              <img src={star} alt="stars" style={{ marginRight: '10px' }} />
            </div>
          )}
          {Math.round(starState * 0.125) === 4 && (
            <div>
              <img src={star} alt="stars" style={{ marginRight: '10px' }} />
              <img src={star} alt="stars" style={{ marginRight: '10px' }} />
              <img src={star} alt="stars" style={{ marginRight: '10px' }} />
              <img src={star} alt="stars" style={{ marginRight: '10px' }} />
            </div>
          )}
          {Math.round(starState * 0.125) === 3 && (
            <div>
              <img src={star} alt="stars" style={{ marginRight: '10px' }} />
              <img src={star} alt="stars" style={{ marginRight: '10px' }} />
              <img src={star} alt="stars" style={{ marginRight: '10px' }} />
            </div>
          )}
          {Math.round(starState * 0.125) === 2 && (
            <div>
              <img src={star} alt="stars" style={{ marginRight: '10px' }} />
              <img src={star} alt="stars" style={{ marginRight: '10px' }} />
            </div>
          )}
          {Math.round(starState * 0.125) === 1 && (
            <img src={star} alt="stars" style={{ marginRight: '10px' }} />
          )}
        </div>
        <br />
        <p className="info-text">{t('Fill all sections')}</p>
        <Heading
          caption={t('You made it')}
          smallCaption={t('Preview all sections')}
        />
        {userData?.contactInformation?.id !== '' ? (
          <Capsule
            title={t('You made it')}
            toggleFieldShown={() => toggleFieldShown('contactShown')}
          />
        ) : (
          ''
        )}
        <ContactForm
          formShown={fieldsShown.contactShown}
          noFooter
          formName={t('Contact Information')}
        />
        {userData?.headline?.details?.length > 0 ? (
          <Capsule
            title="Headline"
            toggleFieldShown={() => toggleFieldShown('headineShown')}
          />
        ) : (
          ''
        )}
        <HeadlineForm
          formShown={fieldsShown.headineShown}
          noFooter
          formName="Headline"
        />
        {userData?.workExperiences?.length > 0 ? (
          <Capsule
            title={t('Work Experience')}
            toggleFieldShown={() => toggleFieldShown('experienceShown')}
          />
        ) : (
          ''
        )}
        <ExperienceForm formShown={fieldsShown.experienceShown} noFooter />
        {userData?.educations?.length > 0 ? (
          <Capsule
            title={t('Education')}
            toggleFieldShown={() => toggleFieldShown('eduShown')}
          />
        ) : (
          ''
        )}
        <Eduform formShown={fieldsShown.eduShown} noFooter />
        {userData?.volunteerHistories?.length > 0 ? (
          <Capsule
            title={t('Volunteer History')}
            toggleFieldShown={() => toggleFieldShown('volunterrShown')}
          />
        ) : (
          ''
        )}
        <VolunteerForm formShown={fieldsShown.volunterrShown} noFooter />
        {userData?.licenseAndCertificates?.length > 0 ? (
          <Capsule
            title={t('Licenses and Certifications')}
            toggleFieldShown={() => toggleFieldShown('licenseShown')}
          />
        ) : (
          ''
        )}
        <LicenseForm formShown={fieldsShown.licenseShown} noFooter />
        <Capsule
          title={t('Accomplishments')}
          toggleFieldShown={() => toggleFieldShown('accShown')}
        />
        <AccomplishmentForm formShown={fieldsShown.accShown} noFooter />
        {userData?.skills?.length > 0 ? (
          <Capsule
            title={t('Skills')}
            toggleFieldShown={() => toggleFieldShown('skillShown')}
          />
        ) : (
          ''
        )}
        <Skillform formShown={fieldsShown.skillShown} noFooter />
        <Footer>
          <Link to="skills">
            <span>{t('Back')}</span>
          </Link>
          <Link to="selecttemplate">
            <button type="button" className="form_next_btn">
              {t('Proceed to select a template')}
            </button>
          </Link>
        </Footer>
      </MainContent>
    </Layout>
  );
};

export default Preview;
