import React from 'react';
// import { Field } from 'formik';
// import { CheckLabel } from '../../components/styles';

const SingleVolunteer = ({
  values,
  volunteerIndex,
  touched,
  indexToSubmit,
  setIndexToSubmit,
  isFormRequesting,
  initialValues,
  setinitialValues,
}) => {
  const updateArray = (e) => {
    const caret = e.target.selectionStart;
    const element = e.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });
    const newArr = [...initialValues]; // copying the old datas array
    if (e.target.type === 'checkbox') {
      newArr[volunteerIndex] = {
        ...values[volunteerIndex],
        [e.target.name]: e.target.checked,
      }; // replace e.target.value with whatever you want to change it to
    } else
      newArr[volunteerIndex] = {
        ...values[volunteerIndex],
        [e.target.name]: e.target.value,
      }; // replace e.target.value with whatever you want to change it to

    setinitialValues(newArr);
  };
  return (
    <div className="single__volunteer_wrapper">
      <label>
        <span className="important-field">Name of Organisation</span>
        <br />
        <input
          type="text"
          placeholder="Enter the name of the organisation"
          name="nameOfOrganization"
          value={values[volunteerIndex]?.nameOfOrganization}
          onChange={updateArray}
          className={
            values[volunteerIndex]?.nameOfOrganization.length === 0 &&
            touched[volunteerIndex] &&
            'rez_input_error'
          }
          required
        />
      </label>
      <label>
        <span className="important-field">Role</span>
        <br />
        <input
          type="text"
          name="role"
          value={values[volunteerIndex]?.role}
          onChange={updateArray}
          className={
            values[volunteerIndex]?.role.length === 0 &&
            touched[volunteerIndex] &&
            'rez_input_error'
          }
          required
        />
      </label>

      <label>
        <span className="important-field">Date Started</span>
        <br />
        <input
          type="date"
          name="startDate"
          onChange={updateArray}
          className={
            values[volunteerIndex]?.startDate?.length === 0 &&
            touched[volunteerIndex] &&
            'rez_input_error'
          }
          value={
            values[volunteerIndex]?.startDate &&
            new Date(values[volunteerIndex].startDate)
              .toISOString()
              .substring(0, 10)
          }
          required
        />
      </label>

      <label>
        <input
          type="checkbox"
          name="active"
          checked={values[volunteerIndex]?.active}
          onChange={updateArray}
        />
        {'  '}
        I currently volunteer here
        <br />
        <br />
      </label>

      {!values[volunteerIndex].active && (
        <label>
          Date Ended
          <br />
          <input
            type="date"
            name="endDate"
            value={
              values[volunteerIndex]?.endDate &&
              new Date(values[volunteerIndex].endDate)
                .toISOString()
                .substring(0, 10)
            }
            onChange={updateArray}
            required={values[volunteerIndex].canExpire}
            className={
              values[volunteerIndex].canExpire &&
              values[volunteerIndex]?.endDate.length === 0 &&
              touched[volunteerIndex] &&
              'rez_input_error'
            }
          />
        </label>
      )}
      <label>
        Let’s know what this role entailed
        <br />
        <textarea
          // as="textarea"
          placeholder="Share any achievements and what your role entailed here"
          name="roleDetails"
          value={values[volunteerIndex]?.roleDetails}
          onChange={updateArray}
          className={
            values[volunteerIndex]?.roleDetails.length === 0 &&
            touched[volunteerIndex] &&
            'rez_input_error'
          }
          required
        />
      </label>

      <div>
        <button
          type="submit"
          className="form_next_btn"
          onClick={() => setIndexToSubmit(volunteerIndex)}
          disabled={isFormRequesting && indexToSubmit === volunteerIndex}
        >
          {isFormRequesting && indexToSubmit === volunteerIndex
            ? 'Saving...'
            : 'Save Changes'}
        </button>
        <div className="clearfix" />
      </div>
    </div>
  );
};

export default SingleVolunteer;
