import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';

import Form from '../styles';
import { routes } from '../onboardingRoutes';

import { RESET_GOTO_NEXT_ONBOARDINGROUTE } from '../../../redux/actionTypes';

const capsuleList = ['Contact Information', 'Headline'];

const Wrapper = ({
  children,
  noFooter,
  formShown,
  handleSubmit,
  isCheckBoxActive,
  indexToSubmit,
  formName,
  setSubmitFromWrapper = () => {},
}) => {
  const { isFormRequesting, gotoNextOnboardingRoute } = useSelector(
    (state) => state?.onboarding
  );
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [prevPath, setPrevPath] = useState('#0');
  const [nextPath, setNextPath] = useState('#0');

  /**
   * Get Prev Path link to be navigated to when a user clicks "Back" on the Form
   *
   * @function
   * @return {void}
   */
  const getPrevPath = () => {
    if (location.pathname !== '/previewsummary') {
      if (location.pathname === '/onboarding') {
        setPrevPath('#0');
        return;
      }
      const curRouteIndex = routes.findIndex(
        (item) => item.path === location?.pathname
      );

      const prevRouteObject = routes[curRouteIndex - 1];
      setPrevPath(prevRouteObject?.path);
    }
  };

  /**
   * Get Next Path link to be navigated to when a user clicks "Nect" on the Form
   *
   * @function
   * @return {void}
   */
  const getNextPath = () => {
    if (location.pathname !== '/previewsummary') {
      if (location.pathname === '/skills') {
        setNextPath('/previewsummary');
        return;
      }

      const curRouteIndex = routes.findIndex(
        (item) => item.path === location?.pathname
      );
      const nextRouteObject = routes[curRouteIndex + 1];
      setNextPath(nextRouteObject?.path);
    }
  };

  useEffect(() => {
    /* Call getPrevPath and getNextPath Only when Form Footer is present, those buttons are inside Form Footer If form footer is not rendered there is no point calling those functions */
    if (!noFooter) {
      getPrevPath();
      getNextPath();
      dispatch({ type: RESET_GOTO_NEXT_ONBOARDINGROUTE });
    }
  }, []);

  useEffect(() => {
    /* Handle go to next onboarding route */
    if (gotoNextOnboardingRoute && !noFooter) {
      history.push(nextPath);
    }
  }, [gotoNextOnboardingRoute]);

  /* Prevent Form submission on Enter key  */
  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  return (
    <Form
      formShown={formShown}
      onSubmit={handleSubmit}
      isCheckBoxActive={isCheckBoxActive}
      onKeyDown={onKeyDown}
    >
      {children}
      {!noFooter && (
        <footer>
          <Link to={prevPath}>
            <span>Back</span>
          </Link>
          <button
            type="submit"
            className="form_next_btn"
            disabled={isFormRequesting && indexToSubmit === 0}
            onClick={() => setSubmitFromWrapper(true)}
          >
            {isFormRequesting && indexToSubmit === 0
              ? 'Saving...'
              : 'Next Step'}
          </button>
          <div className="clearfix" />
        </footer>
      )}
      {noFooter && capsuleList.includes(formName) && (
        <footer>
          <button
            type="submit"
            className="form_next_btn"
            disabled={isFormRequesting && indexToSubmit === 0}
            onClick={() => setSubmitFromWrapper(false)}
          >
            {isFormRequesting && indexToSubmit === 0
              ? 'Saving...'
              : 'Save Changes'}
          </button>
          <div className="clearfix" />
        </footer>
      )}
    </Form>
  );
};

export default Wrapper;
