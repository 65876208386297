import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { toast } from 'react-toastify';
import useSWR from 'swr';
import { Formik } from 'formik';
import apiClient from '../../../../../api/server';
import {
  postVolunteer,
  // postVolunteerWNextRoute,
} from '../../../../../redux/actions';
import FormWrapper from '../../FormWrapper';
import SingleVolunteer from './components/SingleVolunteer';
import { GOTO_NEXT_ONBOARDINGROUTE } from '../../../../../redux/actionTypes';

const Volform = ({ noFooter, formShown }) => {
  const { user } = useSelector((state) => state?.auth);
  const { isFormRequesting } = useSelector((state) => state?.onboarding);
  const dispatch = useDispatch();

  const { data, mutate } = useSWR(
    () => `/users/${user.id}/volunteerHistory`,
    () => apiClient.getVolunteer(user.id)
  );

  const [initialValues, setinitialValues] = useState([]);
  const [indexToSubmit, setIndexToSubmit] = useState(0);
  const [submitFromWrapper, setSubmitFromWrapper] = useState(false);
  const newVolunteerInstance = {
    id: Math.floor(Math.random() * 106),
    nameOfOrganization: '',
    role: '',
    startDate: '',
    endDate: '',
    active: false,
    roleDetails: '',
  };
  useEffect(() => {
    if (data?.data && data.data.length > 0) {
      const userVolunteerDetails = data.data;
      setinitialValues(userVolunteerDetails);
    } else {
      setinitialValues([newVolunteerInstance]);
    }
  }, [data]);

  const handleNewVolunteerInstance = () =>
    setinitialValues((i) => [...i, newVolunteerInstance]);
  const handleDelVolunteerInstance = (id) => {
    setinitialValues(initialValues.filter((item) => item?.id !== id));
    const dataExists = data?.data.some((dt) => dt.id === id);
    if (dataExists) {
      dispatch(postVolunteer(user.id, [], 'delete', id));
      mutate();
    }
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        const dataExists = data?.data.some(
          (dt) => dt.id === values[indexToSubmit].id
        );
        if (dataExists) {
          dispatch(
            postVolunteer(
              user.id,
              values[indexToSubmit],
              'patch',
              values[indexToSubmit].id
            )
          );
        } else if (!dataExists) {
          dispatch(postVolunteer(user.id, values[indexToSubmit]));
        }

        if (submitFromWrapper) {
          dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
        }
      }}
    >
      {({ values, handleSubmit, touched }) => (
        <FormWrapper
          formShown={formShown}
          noFooter={noFooter}
          handleSubmit={handleSubmit}
          indexToSubmit={indexToSubmit}
          setSubmitFromWrapper={setSubmitFromWrapper}
        >
          {values.map((item, index) => (
            <>
              <SingleVolunteer
                key={`${item.id}`}
                values={values}
                touched={touched}
                volunteerIndex={index}
                indexToSubmit={indexToSubmit}
                setIndexToSubmit={setIndexToSubmit}
                isFormRequesting={isFormRequesting}
                initialValues={initialValues}
                setinitialValues={setinitialValues}
              />
              <span
                role="button"
                tabIndex="-9"
                className="textarea_add_experience"
                onClick={handleNewVolunteerInstance}
                onKeyPress={(e) =>
                  e.key === 'Enter' && handleNewVolunteerInstance
                }
              >
                <Plus />
                &nbsp;&nbsp; Add volunteer experience
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span
                role="button"
                tabIndex="-9"
                className="textarea_delete_experience"
                onClick={() => handleDelVolunteerInstance(item?.id)}
                onKeyPress={(e) =>
                  e.key === 'Delete' && handleDelVolunteerInstance(item?.id)
                }
              >
                <Delete />
                &nbsp;&nbsp; Remove volunteering experience
              </span>
            </>
          ))}
        </FormWrapper>
      )}
    </Formik>
  );
};

export default Volform;

const Plus = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9944 7.00012H8.98156V1.00293C8.98156 0.45293 8.53156 0.00292969 7.98156 0.00292969C7.43156 0.00292969 6.98156 0.45293 6.98156 1.00293V7.00012H1C0.45 7.00012 0 7.45012 0 8.00012C0 8.55012 0.45 9.00012 1 9.00012H6.98156V14.9973C6.98156 15.5473 7.43156 15.9973 7.98156 15.9973C8.53156 15.9973 8.98156 15.5473 8.98156 14.9973V9.00015H14.9944C15.5444 9.00015 15.9944 8.55015 15.9944 8.00015C15.9944 7.45015 15.5444 7.00015 14.9944 7.00015V7.00012Z"
      fill="#5C70CD"
    />
  </svg>
);

const Delete = () => (
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 6V16H3V6H11ZM9.5 0H4.5L3.5 1H0V3H14V1H10.5L9.5 0ZM13 4H1V16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4Z"
      fill="#E03232"
    />
  </svg>
);
