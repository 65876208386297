import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { ResourcesCareerIcon } from '../../../../assets';
import BlueButton from '../common-components/BlueButton';

function CareerPath() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      as="section"
      sx={{
        padding: { xs: '3rem 1.25rem', md: '4rem 2.5rem' },
        '& .section-img': {
          width: { xs: '100%', md: '45%' },
          mb: { xs: '1rem', md: '0' },
        },
      }}
    >
      <Box>
        <Box textAlign="center">
          <Typography fontSize={['1.75rem', '2rem']} fontWeight={600} mb={3}>
            Explore Career Paths
          </Typography>
          <Typography fontSize="20px" maxWidth={['475px']} mx="auto">
            Having trouble figuring out your career path? Maybe this will help
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection={['column', 'row']}
        alignItems="center"
        gap={[2, 6]}
      >
        <img
          className="section-img"
          src={ResourcesCareerIcon}
          alt="career paths"
        />
        <Box textAlign={['center', 'left']} mt={[0, 6]} width={['100%', '50%']}>
          <Typography fontSize={['1.5rem']} fontWeight={600} mb={[1, 3]}>
            Career Assessment Tool
          </Typography>
          <Typography fontSize="20px" maxWidth={['450px']}>
            Answer the questionnaire to help you figure out your career path.
          </Typography>

          <Box mt={[2, 4]}>
            <Link to="/resources/questionnaire" target="_blank">
              <BlueButton>Try for free</BlueButton>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default CareerPath;
