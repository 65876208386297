import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/Footer';
import Homepage from './Homepage';
import Aboutpage from './Aboutpage';
import Aboutpage2 from './Aboutpage/index2';
import Aboutpage3 from './Aboutpage/index3';
import Enrolpage from './Enrolpage';
import { request } from '../../../api/server';

export default function index() {
  const [email, setEmail] = useState('');
  const [count, setCount] = useState([]);

  const handleCounter = async () => {
    const res = await request('post', `/wishlist/counter`, {});
    // console.log('>>', res);
    if (res.status) {
      // do something
    }
  };

  const handleWaitlistClick = () => {
    const el = document.getElementById('enrolpage');
    el.scrollIntoView();
  };

  const handleWishList = async () => {
    if (!email) {
      toast.error('Input email to subscribe');
      return;
    }
    const res = await request('post', `/wishlist?email=${email}`, {});
    // console.log('>>', res);
    if (res.status) {
      // do something
      setEmail('');
      toast.success('You have successfully subscribed to our newsletter.');
    }
  };

  function commaSeparateNumber(val) {
    while (/(\d+)(\d{1})/.test(val)) {
      val = val.replace(/(\d+)(\d{1})/, '$1' + ',' + '$2');
    }
    return val;
  }

  const handleCountDisplay = (countNum) => {
    const numString = `${countNum}`;
    if (numString.length === 4) {
      const str = `${numString}`;
      setCount(commaSeparateNumber(str).split(','));
    } else if (numString.length === 3) {
      const str = `0${numString}`;
      console.log(str);
      setCount(commaSeparateNumber(str).split(','));
    } else if (numString.length === 2) {
      const str = `00${numString}`;
      setCount(commaSeparateNumber(str).split(','));
    } else if (numString.length === 1) {
      const str = `000${numString}`;
      setCount(commaSeparateNumber(str).split(','));
    }
  };

  // const handleGetCounter = async () => {
  //   const res = await request('get', `/wishlist/counter`);
  //   // console.log('>>', res);
  //   if (res.status) {
  //     // do something
  //     handleCountDisplay(res.data);
  //   }
  // };

  // useEffect(() => {
  //   handleGetCounter();
  // }, []);

  return (
    <Box sx={{maxWidth: '1200px', margin: 'auto'}}>
        <Navbar handleWaitlistClick={handleWaitlistClick} />
        <Homepage
          handleWishList={handleWishList}
          handleCounter={handleCounter}
          setEmail={setEmail}
          email={email}
        />
        {/* <Aboutpage /> */}
        <Aboutpage2 />
        <Aboutpage3 />
        <Enrolpage
          handleWishList={handleWishList}
          handleCounter={handleCounter}
          setEmail={setEmail}
          email={email}
          count={count}
        />
      <Footer />
    </Box>
  );
}
