import { toast } from 'react-toastify';
import {
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SET_USER_DETAILS,
  FORM_UPDATE_REQUEST_STARTED,
  FORM_UPDATE_REQUEST_FINISHED,
  GOTO_NEXT_ONBOARDINGROUTE,
  LOG_OUT,
} from '../actionTypes';
import apiClient from '../../api/server';
import { hideLoader } from '../../utils/loader';

/* handle register */
export const register = (payload) => async (dispatch) => {
  dispatch({ type: REGISTER_REQUEST });
  try {
    const { status, message, data } = await apiClient.register(payload);
    if (status === false) {
      dispatch({ type: REGISTER_FAILURE });
      toast.error(message);
    } else if (status) {
      localStorage.setItem('rezzie_token', data.token);
      dispatch({ type: REGISTER_SUCCESS });
      dispatch({ type: SET_USER_DETAILS, payload: data.user });
    }
  } catch (error) {
    dispatch({ type: REGISTER_FAILURE });
    // toast.error('Internal server error');
    // console.log('in register action error', error);
  }
};

/* Handle login */
export const login = (payload) => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });
  try {
    const { status, message, data } = await apiClient.login(payload);
    if (status === false) {
      dispatch({ type: LOGIN_FAILURE });
      toast.error(message);
    } else if (status) {
      localStorage.setItem('rezzie_token', data.token);
      dispatch({ type: LOGIN_SUCCESS });
      dispatch({ type: SET_USER_DETAILS, payload: data.user });
    }
  } catch (error) {
    dispatch({ type: LOGIN_FAILURE });
    // toast.error('Internal server error');
    // console.log('in action login error', error);
  }
};

/* Handle login */
export const linkedinLogin = (payload) => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });
  try {
    const { status, message, data } = await apiClient.linkedinLogin(payload);
    if (status === false) {
      dispatch({ type: LOGIN_FAILURE });
      toast.error(message);
      hideLoader();
      window.location.href = '/resumeandcoverletterbuilder/login';
    } else if (status) {
      localStorage.setItem('rezzie_token', data.token);
      dispatch({ type: LOGIN_SUCCESS });
      dispatch({ type: SET_USER_DETAILS, payload: data.user });
    }
  } catch (error) {
    dispatch({ type: LOGIN_FAILURE });
    // toast.error('Internal server error');
    // console.log('in action login error', error);
  }
};

export const googleLogin = (payload) => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });
  try {
    const { status, message, data } = await apiClient.googleLogin(payload);
    if (status === false) {
      dispatch({ type: LOGIN_FAILURE });
      toast.error(message);
      hideLoader();
      window.location.href = '/resumeandcoverletterbuilder/login';
    } else if (status) {
      localStorage.setItem('rezzie_token', data.token);
      dispatch({ type: LOGIN_SUCCESS });
      dispatch({ type: SET_USER_DETAILS, payload: data.user });
    }
  } catch (error) {
    dispatch({ type: LOGIN_FAILURE });
    // toast.error('Internal server error');
    // console.log('in action login error', error);
  }
};

// handle forgotpassword
export const forgotpassword = (payload) => async () => {
  try {
    const { status, message } = await apiClient.forgotPassword(payload);
    if (status === false) toast.error(message);
    else if (status) {
      toast.success(message);
      setTimeout(() => {
        window.location.href = '/resumeandcoverletterbuilder/login';
      }, 1000);
    }
  } catch (error) {
    toast.error('Internal server error');
  }
};

/* Hadle logout */
export const logout = () => ({
  type: LOG_OUT,
});

/* Handle Submit contact information */
export const postContact =
  (id, data, type = 'post', exID = null) =>
  async (dispatch) => {
    if (type === 'post') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.postContact(id, data);
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    } else {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.editContact(id, data, exID);
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    }
  };

/* Handle submit headline */
export const postHeadline =
  (id, data, type = 'post', exID = null) =>
  async (dispatch) => {
    if (type === 'post') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.postHeadline(id, data);
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    } else {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.editHeadline(
          id,
          data,
          exID
        );
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    }
  };

/* Handle Submit Education */
export const postEducation =
  (userId, data, type = 'post', id = null) =>
  async (dispatch) => {
    if (type === 'post') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.postEducation(userId, data);
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    } else if (type === 'patch') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.editEducation(
          userId,
          data,
          id
        );
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    } else if (type === 'delete') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.deleteEducation(userId, id);
        if (status) toast.success('Deleted Successfully');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    }
  };

/* Handle submit Education and move to next route */
export const postEducationWNextRoute =
  (userId, data, type = 'post', id = null) =>
  async (dispatch) => {
    if (type === 'post') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.postEducation(userId, data);
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    } else if (type === 'patch') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.editEducation(
          userId,
          data,
          id
        );
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    } else if (type === 'delete') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.deleteEducation(userId, id);
        if (status) toast.success('Deleted Successfully');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    }
  };

/* Handle Submit experience */
export const postExperience =
  (userId, data, type = 'post', id = null) =>
  async (dispatch) => {
    if (type === 'post') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.postExperience(
          userId,
          data
        );
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    } else if (type === 'patch') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.editExperience(
          userId,
          data,
          id
        );
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        console.log(error);
        toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    } else if (type === 'delete') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.deleteExperience(
          userId,
          id
        );
        if (status) toast.success('Deleted Successfully');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    }
  };

/* Hanlde submit experience and move to next route */
export const postExperienceWNextRoute =
  (userId, data, type = 'post', id = null) =>
  async (dispatch) => {
    if (type === 'post') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.postExperience(
          userId,
          data
        );
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    } else if (type === 'patch') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.editExperience(
          userId,
          data,
          id
        );
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    } else if (type === 'delete') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.deleteExperience(
          userId,
          id
        );
        if (status) toast.success('Deleted Successfully');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    }
  };
/* Handle Submit publication */
export const postPublication =
  (userid, data, type = 'post', id) =>
  async (dispatch) => {
    if (type === 'post') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.postPublication(
          userid,
          data
        );
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    } else if (type === 'patch') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.editPublication(
          userid,
          id,
          data
        );
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    } else if (type === 'delete') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.deletePublication(
          userid,
          id
        );
        if (status) toast.success('Deleted Successfully');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    }
  };

/* Hanlde submit experience and move to next route */
export const postPublicationWNextRoute =
  (userid, data, type = 'post', id) =>
  async (dispatch) => {
    if (type === 'post') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.postPublication(
          userid,
          data
        );
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    } else if (type === 'patch') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.editPublication(
          userid,
          id,
          data
        );
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    } else if (type === 'delete') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.deletePublication(
          userid,
          id
        );
        if (status) toast.success('Deleted Successfully');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    }
  };
/* Handle Submit patent */
export const postPatent =
  (userid, data, type = 'post', id) =>
  async (dispatch) => {
    if (type === 'post') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.postPatent(userid, data);
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    } else if (type === 'patch') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.editPatent(
          userid,
          id,
          data
        );
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    } else if (type === 'delete') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.deletePatent(userid, id);
        if (status) toast.success('Deleted Successfully');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    }
  };

/* Hanlde submit experience and move to next route */
export const postPatentWNextRoute =
  (userid, data, type = 'post', id) =>
  async (dispatch) => {
    if (type === 'post') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.postPatent(userid, data);
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    } else if (type === 'patch') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.editPatent(
          userid,
          id,
          data
        );
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    } else if (type === 'delete') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.deletePatent(userid, id);
        if (status) toast.success('Deleted Successfully');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    }
  };

/* Handle Submit Projects */
export const postProject =
  (userid, data, type = 'post', id) =>
  async (dispatch) => {
    if (type === 'post') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.postProject(userid, data);
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    } else if (type === 'patch') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.editProject(
          userid,
          id,
          data
        );
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    } else if (type === 'delete') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.deleteProject(userid, id);
        if (status) toast.success('Deleted Successfully');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    }
  };

/* Hanlde submit experience and move to next route */
export const postProjectWNextRoute =
  (userid, data, type = 'post', id) =>
  async (dispatch) => {
    if (type === 'post') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.postProject(userid, data);
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    } else if (type === 'patch') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.editProject(
          userid,
          id,
          data
        );
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    } else if (type === 'delete') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.deleteProject(userid, id);
        if (status) toast.success('Deleted Successfully');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    }
  };

/* Handle Submit postHonor */
export const postHonor =
  (userid, data, type = 'post', id) =>
  async (dispatch) => {
    if (type === 'post') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.postHonor(userid, data);
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    } else if (type === 'patch') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.editHonor(userid, id, data);
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    } else if (type === 'delete') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.deleteHonor(userid, id);
        if (status) toast.success('Deleted Successfully');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    }
  };

/* Hanlde submit honor and move to next route */
export const postHonorWNextRoute =
  (userid, data, type = 'post', id) =>
  async (dispatch) => {
    if (type === 'post') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.postHonor(userid, data);
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    } else if (type === 'patch') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.editHonor(userid, id, data);
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    } else if (type === 'delete') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.deleteHonor(userid, id);
        if (status) toast.success('Deleted Successfully');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    }
  };

/* Handle Submit postTest */
export const postTest =
  (userid, data, type = 'post', id) =>
  async (dispatch) => {
    if (type === 'post') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.postTestScore(userid, data);
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    } else if (type === 'patch') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.editTestScore(
          userid,
          id,
          data
        );
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    } else if (type === 'delete') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });

        const { status, message } = await apiClient.deleteTestScore(userid, id);

        if (status) toast.success('Deleted Successfully');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    }
  };

/* Hanlde submit testscore and move to next route */
export const postTestWNextRoute =
  (userid, data, type = 'post', id) =>
  async (dispatch) => {
    if (type === 'post') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.postTestScore(userid, data);
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    } else if (type === 'patch') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.editTestScore(
          userid,
          id,
          data
        );
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    } else if (type === 'delete') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.deleteTestScore(userid, id);

        if (status) toast.success('Deleted Successfully');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    }
  };

/* Hanlde submit license information */
export const postLicense =
  (userId, data, type = 'post', id = null) =>
  async (dispatch) => {
    if (type === 'post') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.postLicense(userId, data);
        if (status) toast.success('Update Successful');
        else toast.error(message);
        // console.log('message');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        // console.log('Internal server error');

        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    } else if (type === 'patch') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.editLicense(
          userId,
          data,
          id
        );
        if (status) toast.success('Update Successful');
        else toast.error(message);
        // console.log('message');

        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        // console.log('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    } else if (type === 'delete') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.deleteLicense(userId, id);
        if (status) toast.success('Deleted Successfully');
        else toast.error(message);
        // console.log('message');

        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        // console.log('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    }
  };

/* Hanld esubmit license and move to next route */
export const postLicenseWNextRoute =
  (userId, data, type = 'post', id = null) =>
  async (dispatch) => {
    if (type === 'post') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.postLicense(userId, data);
        if (status) toast.success('Update Successful');
        else toast.error(message);
        // console.log('message');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        // console.log('Internal server error');

        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    } else if (type === 'patch') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.editLicense(
          userId,
          data,
          id
        );
        if (status) toast.success('Update Successful');
        else toast.error(message);
        // console.log('message');

        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        // console.log('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    } else if (type === 'delete') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.deleteLicense(
          userId,
          data,
          id
        );
        if (status) toast.success('Deleted Successfully');
        else toast.error(message);
        // console.log('message');

        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        // console.log('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    }
  };

/* Hanlde submit Volunteer Experience */
export const postVolunteer =
  (userId, data, type = 'post', id = null) =>
  async (dispatch) => {
    if (type === 'post') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.postVolunteer(userId, data);
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    } else if (type === 'patch') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.editVolunteer(
          userId,
          data,
          id
        );
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    } else if (type === 'delete') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.deleteVolunteer(userId, id);
        if (status) toast.success('Deleted Successfully');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    }
  };

/* Hanlde submit Volunteer Experience and move to next route */
export const postVolunteerWNextRoute =
  (userId, data, type = 'post', id = null) =>
  async (dispatch) => {
    if (type === 'post') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.postVolunteer(userId, data);
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    } else if (type === 'patch') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.editVolunteer(
          userId,
          data,
          id
        );
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    } else if (type === 'delete') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.deleteVolunteer(userId, id);
        if (status) toast.success('Deleted Successfully');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    }
  };
/* Hanlde submit Language Experience */
export const postlanguage =
  (userId, data, type = 'post', id = null) =>
  async (dispatch) => {
    if (type === 'post') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.postLanguage(userId, data);
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    } else if (type === 'patch') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.editLanguage(
          userId,
          data,
          id
        );
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    } else if (type === 'delete') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.deleteLanguage(userId, id);
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    }
  };

/* Hanlde submit language Experience and move to next route */
export const postlanguageWNextRoute =
  (userId, data, type = 'post', id = null) =>
  async (dispatch) => {
    if (type === 'post') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.postLanguage(userId, data);
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    } else if (type === 'patch') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.editLanguage(
          userId,
          data,
          id
        );
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    } else if (type === 'delete') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.deleteLanguage(userId, id);
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    }
  };
/* Hanlde submit Course Experience */
export const postCourse =
  (userId, data, type = 'post', id = null) =>
  async (dispatch) => {
    if (type === 'post') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.postCourse(userId, data);
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    } else if (type === 'patch') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.editCourse(
          userId,
          data,
          id
        );
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    } else if (type === 'delete') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.deleteCourse(userId, id);
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    }
  };

/* Hanlde submit Course Experience and move to next route */
export const postCourseWNextRoute =
  (userId, data, type = 'post', id = null) =>
  async (dispatch) => {
    if (type === 'post') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.postCourse(userId, data);
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    } else if (type === 'patch') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.editCourse(
          userId,
          data,
          id
        );
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    } else if (type === 'delete') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.deleteCourse(userId, id);
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    }
  };
/* Hanlde submit Course Organizaion */
export const postOrganization =
  (userId, data, type = 'post', id = null) =>
  async (dispatch) => {
    if (type === 'post') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.postOrganization(
          userId,
          data
        );
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    } else if (type === 'patch') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.editOrganization(
          userId,
          data,
          id
        );
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    } else if (type === 'delete') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.deleteOrganization(
          userId,
          id
        );
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    }
  };

/* Hanlde submit Organization  and move to next route */
export const postOrganizationWNextRoute =
  (userId, data, type = 'post', id = null) =>
  async (dispatch) => {
    if (type === 'post') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.postOrganization(
          userId,
          data
        );
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    } else if (type === 'patch') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.editOrganization(
          userId,
          data,
          id
        );
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    } else if (type === 'delete') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.deleteOrganization(
          userId,
          id
        );
        if (status) toast.success('Update Successful');
        else toast.error(message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
        dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
      }
    }
  };

/* Hanlde submit Skills */

export const postSkills =
  (id, data, type = 'post') =>
  async (dispatch) => {
    if (type === 'post') {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.postSkills(id, data);
        // if (status) toast.success('Update Successful');
        // else toast.error(message);
        console.log(status, message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    } else {
      try {
        dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
        const { status, message } = await apiClient.editSkills(id, data);
        console.log(status, message);
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      } catch (error) {
        // toast.error('Internal server error');
        dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
      }
    }
  };
/* Hanlde submit Skills and move to next route */

export const submitSkills = (id, data) => async (dispatch) => {
  try {
    dispatch({ type: FORM_UPDATE_REQUEST_STARTED });
    const { status, message } = await apiClient.postSkills(id, data);
    console.log(status, message);
    dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
    dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
  } catch (error) {
    // toast.error('Internal server error');
    dispatch({ type: FORM_UPDATE_REQUEST_FINISHED });
    dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
  }
};

// export const getTemplatePreview = (userId, templateId) => async (dispatch) => {
//   dispatch({ type: REGISTER_REQUEST });
//   try {
//     const { status, message, data } = await apiClient.getTemplatePreview(
//       userId,
//       templateId
//     );
//     if (status === false) {
//       toast.error(message);
//     } else if (status) {
//       localStorage.setItem('rezzie_token', data.token);
//     }
//   } catch (error) {
//     dispatch({ type: REGISTER_FAILURE });
//     // toast.error('Internal server error');
//     // console.log('in register action error', error);
//   }
// };

// const postPublication =()=>{}
