import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Container, Grid, Box, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useHistory } from 'react-router-dom';

import Layout from '../../layout/Layout';
import CoverLetterSample1 from '../../../assets/images/coverLetterSamples/cover-letter-sample-1.png';
import CoverLetterSample2 from '../../../assets/images/coverLetterSamples/cover-letter-sample-2.png';
import CoverLetterSample3 from '../../../assets/images/coverLetterSamples/cover-letter-sample-3.png';
import CoverLetterTemplateViewer from './CoverLetterTemplateViewer';
import apiClient from '../../../api/server';

const MainContent = styled.main`
  width: 100%;

  .title {
    font-family: 'Circular Std Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 100.5%;
    text-align: center;
    margin-bottom: 16px;
  }

  .description {
    font-family: 'Circular Std Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 57px;
  }

  .box-section {
    background: rgba(92, 112, 205, 0.04);
    border: 1px solid #efefef;
    border-radius: 10px;
    margin-bottom: 55px;
    height: 419px;
    text-align: center;
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .box-icon {
    max-width: 233px;
    margin-bottom: 18px;
  }

  .box-description {
    font-family: 'Circular Std Bold';
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0em;
    color: #131936;
  }

  .box-actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-family: 'Circular Std Book';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 30px;
    color: #5c70cd;
    align-items: center;
    justify-items: center;
  }

  .box-action {
    flex: 1;
  }

  .box-action-text {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .box-action-icon {
    margin-right: 15px;
  }
`;

const CoverLetterTemplate = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [userData, setUserData] = useState({});
  const [toPreview, setToPreview] = useState(0);
  const [warningMessage, setWarningMessage] = useState('');

  const templates = [
    {
      id: 1,
      src: CoverLetterSample1,
    },
    {
      id: 2,
      src: CoverLetterSample2,
    },
    {
      id: 3,
      src: CoverLetterSample3,
    },
  ];
  const getProfile = async () => {
    const res = await apiClient.getUserProfile(user.id);
    if (res.status) {
      setUserData(res.data);
    }
  };

  const openModal = (templateId) => {
    setToPreview(templateId);
    console.log(toPreview);
    setOpen(true);
  };

  const getCoverLetter = async () => {
    const response = await apiClient.getCoverLetter(user.id);

    // show the user a warning if a previous cover letter exists
    if (response.status) {
      setWarningMessage(
        'You have previously created a cover letter. Selecting any of these templates will clear your entry.'
      );
    }
  };

  const closeModal = () => setOpen(false);

  const chooseTemplate = async (templateId) => {
    const res = await apiClient.selectCoverLetterTemplate(user.id, templateId);
    if (res.status) {
      history.push(`/coverletter/review`);
    }
  };

  useEffect(() => {
    getProfile();
    getCoverLetter();
  }, []);

  return (
    <Layout>
      <MainContent>
        <p className="title">Pick a cover letter template</p>
        <p className="description">
          Struggling to get started on writing a cover letter? Pick one of our
          excellent samples
        </p>
        {open && (
          <CoverLetterTemplateViewer
            open={open}
            close={closeModal}
            templateId={toPreview}
          />
        )}
        <Container id="content-section">
          {!!warningMessage && (
            <Typography
              sx={{
                fontSize: '12px',
                color: 'red',
                marginBottom: '55px',
                textAlign: 'center',
              }}
            >
              {warningMessage}
            </Typography>
          )}
          <Grid container spacing={6}>
            {templates.map((item) => (
              <Grid key={item.id} item xs={12} sm={6} md={4}>
                <Box className="box-section">
                  <img className="box-icon" src={item.src} alt="" />
                  <Box className="box-actions">
                    <Box
                      className="box-action"
                      onClick={() => {
                        openModal(item.id);
                      }}
                    >
                      <span className="box-action-text">
                        <VisibilityIcon className="box-action-icon" /> Preview
                      </span>
                    </Box>
                    <Box
                      className="box-action"
                      onClick={() => {
                        chooseTemplate(item.id);
                      }}
                    >
                      <span className="box-action-text">Select Template</span>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </MainContent>
    </Layout>
  );
};

export default CoverLetterTemplate;
