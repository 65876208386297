import React from 'react';
// import { Field } from 'formik';
import styled from 'styled-components';
// import produce from 'immer';
// import { CheckLabel } from '../../components/styles';
const Card = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  padding: 0 8px;
  border-radius: 2px;
  border: 1px solid #a7a9bc;

  font-family: 'Circular Std Medium';
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0em;
  color: #6b6c7e;
  margin-right: 10px;
  svg {
    margin-left: 15px;
    cursor: pointer;
  }
`;
const SingleProject = ({
  values,
  ProjectIndex,
  touched,
  setAddAuthor,
  addAuthor,
  handleChange,
  initialValues,
  setinitialValues,
  indexToSubmit,
  setIndexToSubmit,
  isFormRequesting,
}) => {
  const removeProject = (pI, index, vd) => {
    const getArrayToUpdate = vd[pI];
    const updatedProjects = getArrayToUpdate.allAuthors.filter(
      (ft, indexc) => indexc !== index
    );
    vd[pI].allAuthors = updatedProjects;
    setinitialValues(vd);
  };
  // const addProject = (i, vd) => console.log(i, vd[i]);author
  const addProject = (e, i, vd) => {
    if (vd[i].author !== '') {
      if (vd[i].allAuthors === null) {
        vd[i].allAuthors = [];
        const updatedProjects = [...vd[i].allAuthors, vd[i].author];
        // console.log(updatedProjects);
        vd[i].allAuthors = updatedProjects;
        vd[i].author = '';
        setinitialValues(vd);
        // console.log(vd);
      } else {
        const updatedProjects = [...vd[i].allAuthors, vd[i].author];
        // console.log(updatedProjects);
        vd[i].allAuthors = updatedProjects;
        vd[i].author = '';
        setinitialValues(vd);
      }
    }
  };
  const updateArray = (e) => {
    const caret = e.target.selectionStart;
    const element = e.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });
    const newArr = [...initialValues]; // copying the old datas array
    if (e.target.type === 'checkbox') {
      newArr[ProjectIndex] = {
        ...values[ProjectIndex],
        [e.target.name]: e.target.checked,
      }; // replace e.target.value with whatever you want to change it to
    } else
      newArr[ProjectIndex] = {
        ...values[ProjectIndex],
        [e.target.name]: e.target.value,
      }; // replace e.target.value with whatever you want to change it to

    setinitialValues(newArr);
  };
  // console.log('values', values);
  return (
    <div className="single__Project_wrapper">
      <label>
        <span className="important-field">Project name</span>
        <br />
        <input
          type="text"
          name="name"
          value={values[ProjectIndex]?.name}
          className={
            values[ProjectIndex]?.name?.length === 0 &&
            touched[ProjectIndex] &&
            'rez_input_error'
          }
          required
          placeholder="E.g Global Warming"
          onChange={updateArray}
        />
      </label>

      <label>
        <span className="important-field">Start Date</span>
        <br />
        <input
          type="date"
          name="startDate"
          className={
            values[ProjectIndex]?.startDate?.length === 0 &&
            touched[ProjectIndex]
              ? 'rez_input_error'
              : ''
          }
          onChange={updateArray}
          value={
            values[ProjectIndex]?.startDate &&
            new Date(values[ProjectIndex].startDate)
              .toISOString()
              .substring(0, 10)
          }
          required
        />
      </label>
      <label style={{ display: 'flex' }}>
        <input
          style={{ marginTop: '5px', marginRight: '10px' }}
          type="checkbox"
          name="active"
          value={values[ProjectIndex]?.active}
          className={
            values[ProjectIndex]?.active?.length === 0 &&
            touched[ProjectIndex] &&
            'rez_input_error'
          }
          placeholder="E.g Global Warming"
          onChange={updateArray}
        />
        <span className="important-field">
          I am currently working on this project
        </span>
      </label>

      {!values[ProjectIndex].active && (
        <label>
          When did you end this project?
          <br />
          <input
            type="date"
            name="endDate"
            required={!values[ProjectIndex].endDate}
            value={
              values[ProjectIndex]?.endDate &&
              new Date(values[ProjectIndex].endDate)
                .toISOString()
                .substring(0, 10)
            }
            onChange={updateArray}
            className={
              !values[ProjectIndex].active &&
              values[ProjectIndex]?.endDate?.length === 0 &&
              touched[ProjectIndex] &&
              'rez_input_error'
            }
          />
        </label>
      )}

      {addAuthor ? (
        <>
          <label>
            <input
              type="text"
              placeholder="e.g John Doe"
              name={`[${ProjectIndex}].author`}
              onChange={handleChange}
              value={values[ProjectIndex]?.author}
              onKeyDown={(e) =>
                e.key === 'Enter' && addProject(e, ProjectIndex, values)
              }
            />
          </label>
        </>
      ) : null}
      <br />
      {values[ProjectIndex]?.allAuthors.length > 0 && (
        <label>
          Authors
          <br />
          {values[ProjectIndex]?.allAuthors?.map((item, index) => (
            <Card key={`${Date.now()}__${item}`}>
              {item}
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => removeProject(ProjectIndex, index, values)}
              >
                <path
                  d="M5.72515 5.01764L9.37144 1.37136C9.56686 1.17593 9.56686 0.859361 9.37144 0.664488C9.17601 0.469064 8.85942 0.469064 8.66457 0.664488L5.01828 4.31078L1.37199 0.663902C1.17714 0.468478 0.859995 0.468478 0.665122 0.663902C0.469699 0.859325 0.469699 1.1759 0.665122 1.37077L4.31141 5.01764L0.665122 8.66393C0.469699 8.85936 0.469699 9.17595 0.665122 9.3708C0.762265 9.46908 0.890273 9.51766 1.01826 9.51766C1.14625 9.51766 1.27426 9.46908 1.37199 9.37137L5.01828 5.7245L8.66457 9.37078C8.7623 9.46851 8.89029 9.51708 9.01828 9.51708C9.14627 9.51708 9.27428 9.4685 9.37199 9.37078C9.56741 9.17536 9.56741 8.85877 9.37199 8.66392L5.72511 5.01763L5.72515 5.01764Z"
                  fill="#272833"
                />
              </svg>
            </Card>
          ))}
        </label>
      )}
      <br />
      <label>
        {addAuthor
          ? ''
          : 'You are an author. Add others that contributed to this Project'}
        <br /> <br />
        <span
          role="button"
          tabIndex="-9"
          className="textarea_add_experience"
          onClick={() => setAddAuthor(!addAuthor)}
          onKeyPress={(e) => e.key === 'Enter' && setAddAuthor(!addAuthor)}
        >
          <Plus />
          &nbsp;&nbsp; {addAuthor ? 'Remove' : 'Add'}{' '}
          {addAuthor ? 'authors' : 'author'}
        </span>
        <br /> <br />
      </label>

      {/* <label>
      Associated with
        <br />
        <select
          name="associatedWith"
          value={values[ProjectIndex]?.associatedWith}
          onChange={updateArray}
          className={
            values[ProjectIndex]?.associatedWith?.length === 0 &&
            touched[ProjectIndex] &&
            'rez_input_error'
          }
        >

          <option value="">
            Please select
          </option>

          </select>
      </label> */}

      <label>
        <span className="important-field">Associated with</span>
        <br />
        <input
          type="text"
          placeholder="E.g Microsoft"
          name="associatedWith"
          value={values[ProjectIndex]?.associatedWith}
          onChange={updateArray}
          className={
            values[ProjectIndex]?.associatedWith?.length === 0 &&
            touched[ProjectIndex] &&
            'rez_input_error'
          }
        />
      </label>

      <label>
        Project Url
        <br />
        <input
          type="text"
          placeholder="E.g Microsoft"
          name="projectUrl"
          value={values[ProjectIndex]?.projectUrl}
          onChange={updateArray}
        />
      </label>

      <label>
        <span className="important-field">Description</span>
        <br />
        <textarea
          type="text"
          placeholder="E.g Microsoft"
          name="description"
          value={values[ProjectIndex]?.description}
          className={
            values[ProjectIndex]?.description?.length === 0 &&
            touched[ProjectIndex] &&
            'rez_input_error'
          }
          onChange={updateArray}
        />
      </label>

      {/* {values.length > 1 && (
      <div>
        <button
          type="submit"
          className="form_next_btn"
          onClick={() => setIndexToSubmit(ProjectIndex)}
          disabled={isFormRequesting && indexToSubmit === ProjectIndex}
        >
          {isFormRequesting && indexToSubmit === ProjectIndex
            ? 'Saving...'
            : 'Save Changesd'}
        </button>
        <div className="clearfix" />
      </div> */}
      {/* )} */}

      <button
        type="submit"
        className="form_next_btn"
        onClick={() => setIndexToSubmit(ProjectIndex)}
        disabled={isFormRequesting && indexToSubmit === ProjectIndex}
      >
        {isFormRequesting && indexToSubmit === ProjectIndex
          ? 'Saving...'
          : 'Save Changes'}
      </button>
    </div>
  );
};

export default SingleProject;

const Plus = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9944 7.00012H8.98156V1.00293C8.98156 0.45293 8.53156 0.00292969 7.98156 0.00292969C7.43156 0.00292969 6.98156 0.45293 6.98156 1.00293V7.00012H1C0.45 7.00012 0 7.45012 0 8.00012C0 8.55012 0.45 9.00012 1 9.00012H6.98156V14.9973C6.98156 15.5473 7.43156 15.9973 7.98156 15.9973C8.53156 15.9973 8.98156 15.5473 8.98156 14.9973V9.00015H14.9944C15.5444 9.00015 15.9944 8.55015 15.9944 8.00015C15.9944 7.45015 15.5444 7.00015 14.9944 7.00015V7.00012Z"
      fill="#5C70CD"
    />
  </svg>
);
