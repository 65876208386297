import React from 'react';

const SingleTest = ({
  values,
  TestIndex,
  touched,
  initialValues,
  setinitialValues,
  indexToSubmit,
  setIndexToSubmit,
  isFormRequesting,
}) => {
  const updateArray = (e) => {
    const caret = e.target.selectionStart;
    const element = e.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });
    const newArr = [...initialValues]; // copying the old datas array
    if (e.target.type === 'checkbox') {
      newArr[TestIndex] = {
        ...values[TestIndex],
        [e.target.name]: e.target.checked,
      }; // replace e.target.value with whatever you want to change it to
    } else
      newArr[TestIndex] = {
        ...values[TestIndex],
        [e.target.name]: e.target.value,
      }; // replace e.target.value with whatever you want to change it to

    setinitialValues(newArr);
  };

  // console.log('valxxx', values);
  return (
    <div className="single__Test_wrapper">
      <label>
        <span className="important-field">Title</span>
        <br />
        <input
          type="text"
          name="title"
          value={values[TestIndex]?.title}
          className={
            values[TestIndex]?.title?.length === 0 &&
            touched[TestIndex] &&
            'rez_input_error'
          }
          required
          placeholder="E.g Ielts"
          onChange={updateArray}
        />
      </label>

      <label>
        Associated with
        <br />
        <input
          type="text"
          placeholder="E.g English Language"
          name="associatedWith"
          value={values[TestIndex]?.associatedWith}
          onChange={updateArray}
          className={
            values[TestIndex]?.associatedWith?.length === 0 &&
            touched[TestIndex] &&
            'rez_input_error'
          }
        />
      </label>

      <label>
        Score
        <br />
        <input
          type="text"
          placeholder="E.g 1100"
          name="score"
          value={values[TestIndex]?.score}
          onChange={updateArray}
          className={
            values[TestIndex]?.score?.length === 0 &&
            touched[TestIndex] &&
            'rez_input_error'
          }
        />
      </label>

      <label>
        <span className="important-field">Test Date</span>
        <br />
        <input
          type="date"
          name="testDate"
          className={
            values[TestIndex]?.testDate?.length === 0 && touched[TestIndex]
              ? 'rez_input_error'
              : ''
          }
          onChange={updateArray}
          value={
            values[TestIndex]?.testDate &&
            new Date(values[TestIndex].testDate).toISOString().substring(0, 10)
          }
          required
        />
      </label>

      <label>
        Description
        <br />
        <textarea
          type="text"
          placeholder="E.g Microsoft"
          name="description"
          value={values[TestIndex]?.description}
          onChange={updateArray}
        />
      </label>

      <button
        type="submit"
        className="form_next_btn"
        onClick={() => setIndexToSubmit(TestIndex)}
        disabled={isFormRequesting && indexToSubmit === TestIndex}
      >
        {isFormRequesting && indexToSubmit === TestIndex
          ? 'Saving...'
          : 'Save Changes'}
      </button>
    </div>
  );
};

export default SingleTest;
