import React from 'react';
import { Section, SectionHeading, SectionBody, Paragraph } from '../styles';

const Feedback = () => (
  <>
    <Section>
      <SectionHeading>Your Feedback to Us</SectionHeading>
      <SectionBody>
        <Paragraph>
          You assign all rights, title and interest in any Feedback You provide
          the Company. If for any reason such assignment is ineffective, You
          agree to grant the Company a non-exclusive, perpetual, irrevocable,
          royalty free, worldwide right and license to use, reproduce, disclose,
          sub-license, distribute, modify and exploit such Feedback without
          restriction.
        </Paragraph>
      </SectionBody>
    </Section>
    <Section>
      <SectionHeading>Links to Other Websites</SectionHeading>
      <SectionBody>
        <Paragraph>
          Our Service may contain links to third-party web sites or services
          that are not owned or controlled by the Company.
        </Paragraph>
        <Paragraph>
          The Company has no control over, and assumes no responsibility for,
          the content, privacy policies, or practices of any third party web
          sites or services. You further acknowledge and agree that the Company
          shall not be responsible or liable, directly, or indirectly, for any
          damage or loss caused or alleged to be caused by or in connection with
          the use of or reliance on any such content, goods, or services
          available on or through any such web sites or services.
        </Paragraph>
        <Paragraph>
          We strongly advise You to read the terms and conditions and privacy
          policies of any third-party web sites or services that You visit.
        </Paragraph>
      </SectionBody>
    </Section>
  </>
);

export default Feedback;
