import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import svg from '../../../../assets/images/light-blue-bg.svg';

function Enrolpage({ email, setEmail, handleCounter, handleWishList }) {
  const { t, i18n } = useTranslation();
  return (
    <Box
      id="enrolpage"
      as="section"
      className=" aboutpage"
      sx={{
        margin: '7rem 0 9rem',
        textAlign: 'center',
        padding: '4rem 2.5rem 1.7rem',
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${svg})`,
          height: '345px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          padding: {
            xs: '1rem',
            md: '2rem',
          },
          width: '100%',
          maxWidth: '1000px',
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Stack>
            <Typography as="h4" className="enrol-heading" fontSize={["1.5rem", "2rem"]} fontWeight={500} mb={4}>
              {t('Get timely updates')}
              <br />
              {t('Delivered inbox')}
            </Typography>
            <div className="enrolForm">
              <input
                type="text"
                placeholder={t('Enter your Email')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                className="button subscribe-btn"
                type="button"
                onClick={() => {
                  handleCounter();
                  handleWishList();
                }}
              >
                {t('Subscribe to our newsletter')}
              </button>
            </div>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}

export default Enrolpage;
