import React from 'react';
import { Button } from '@mui/material';

const BlueButton = ({ onClick, children }) => (
  <Button
    sx={{
      background: '#19BCFE',
      color: 'white',
      borderRadius: '8px',
      textTransform: 'unset',
      fontSize: { md: '1.25rem' },
      width: '100%',
      maxWidth: '200px',
      boxShadow: '0 8px 5px rgba(0, 0, 0, 0.25)',
      '&:hover': {
        background: '#19BCFE',
        color: 'white',
        cursor: 'pointer',
      },
    }}
    onClick={onClick}
  >
    {children}
  </Button>
);

export default BlueButton;
