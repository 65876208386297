import React from 'react';
import {
  Section,
  SectionHeading,
  SectionSubheading,
  Paragraph,
  SectionBody,
  List,
  ListItem
} from '../styles';

const Interpretation = () => (
  <Section>
    <SectionHeading>Interpretation and Definitions</SectionHeading>
    <SectionBody>
      <SectionSubheading>Interpretation</SectionSubheading>
      <Paragraph>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </Paragraph>
    </SectionBody>
    <SectionBody>
      <SectionSubheading>Definitions</SectionSubheading>
      <Paragraph> For the purposes of these Terms and Conditions:</Paragraph>
      <List>
        <ListItem>
          <strong>&quot;Affiliate&quot;</strong> means an entity that controls,
          is controlled by or is under common control with a party, where
          &quot;control&quot; means ownership of 50% or more of the shares,
          equity interest or other securities entitled to vote for election of
          directors or other managing authority.
        </ListItem>
        <ListItem>
          <strong>&quot;Account&quot;</strong> means a unique account created
          for You to access our Service or parts of our Service.
        </ListItem>
        <ListItem>
          <strong>&quot;Company&quot;</strong> (referred to as either &quot;the
          Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in
          this Agreement) refers to Rezzie, Corn House, 10 Marshgate Lane
          &#8209; London, England
        </ListItem>
        <ListItem>
          <strong>&quot;Country&quot;</strong> refers to the United Kingdom
        </ListItem>
        <ListItem>
          <strong>&quot;Content&quot;</strong> refers to content such as text,
          images, or other information that can be posted, uploaded, linked to
          or otherwise made available by You, regardless of the form of that
          content.
        </ListItem>
        <ListItem>
          <strong>&quot;Device&quot;</strong> means any device that can access
          the Service such as a computer, a cell phone or a digital tablet.
        </ListItem>
        <ListItem>
          <strong>&quot;Feedback&quot;</strong> means feedback, innovations or
          suggestions sent by You regarding the attributes, performance or
          features of our Service.
        </ListItem>
        <ListItem>
          <strong>&quot;Service&quot;</strong> refers to the Website.
        </ListItem>
        <ListItem>
          <strong>&quot;Terms and Conditions&quot;</strong> (also referred as
          &quot;Terms&quot;) mean these Terms and Conditions that form the
          entire agreement between You and the Company regarding the use of the
          Service.
        </ListItem>
        <ListItem>
          <strong>&quot;Third-party Social Media Service&quot;</strong> means
          any services or content (including data, information, products or
          services) provided by a third-party that may be displayed, included or
          made available by the Service.
        </ListItem>
        <ListItem>
          <strong>&quot;Website&quot;</strong> refers to Rezzie accessible from
          Rezzie.co or any other affiliated web pages.
        </ListItem>
        <ListItem>
          <strong>&quot;You&quot;</strong> means the individual accessing or
          using the Service, or the company, or other legal entity on behalf of
          which such individual is accessing or using the Service, as
          applicable.
        </ListItem>
      </List>
    </SectionBody>
  </Section>
);

export default Interpretation;
