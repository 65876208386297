import React from 'react';
import styled from 'styled-components';

const SaveandContinue = styled.p`
  font-family: 'Circular Std Medium';
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #5c70cd;
  margin-top: 64px;
  cursor: pointer;
`;

const SaveButton = () => <SaveandContinue />;

export default SaveButton;
