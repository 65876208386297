import React from 'react';
import ContentLoader from 'react-content-loader';

const Loader = ({ extendSize }) => (
  <ContentLoader
    speed={2}
    width={extendSize ? '100%' : 211}
    height={extendSize ? '100%' : 228}
    viewBox="0 0 211 228"
    backgroundColor="#dbdbdb"
    foregroundColor="#ecebeb"
    // {...props}
  >
    <path d="M 0 0 h 94 v 14 H 0 z M 0 20 h 94 v 5 H 0 z M 0 29 h 94 v 5 H 0 z M 0 48 h 211 v 5 H 0 z M 0 84 h 60 v 5 H 0 z M 73 84 h 138 v 5 H 73 z M 73 114 h 138 v 5 H 73 z M 73 159 h 138 v 5 H 73 z M 73 203 h 138 v 5 H 73 z M 0 120 h 60 v 5 H 0 z M 0 156 h 60 v 5 H 0 z M 0 192 h 60 v 5 H 0 z M 0 58 h 211 v 5 H 0 z M 0 94 h 60 v 5 H 0 z M 73 94 h 138 v 5 H 73 z M 73 124 h 138 v 5 H 73 z M 73 169 h 138 v 5 H 73 z M 73 213 h 138 v 5 H 73 z M 0 130 h 60 v 5 H 0 z M 0 166 h 60 v 5 H 0 z M 0 202 h 60 v 5 H 0 z M 0 68 h 211 v 5 H 0 z M 0 104 h 60 v 5 H 0 z M 73 104 h 138 v 5 H 73 z M 73 150 h 138 v 5 H 73 z M 73 194 h 138 v 5 H 73 z M 73 134 h 138 v 5 H 73 z M 73 179 h 138 v 5 H 73 z M 73 223 h 138 v 5 H 73 z M 0 140 h 60 v 5 H 0 z M 0 176 h 60 v 5 H 0 z M 0 212 h 60 v 5 H 0 z M 0 222 h 60 v 5 H 0 z" />
  </ContentLoader>
);

export default Loader;
