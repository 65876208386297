import React from 'react';

const SingleCourse = ({
  values,
  CourseIndex,
  touched,
  initialValues,
  setinitialValues,
  indexToSubmit,
  setIndexToSubmit,
  isFormRequesting,
}) => {
  const updateArray = (e) => {
    const caret = e.target.selectionStart;
    const element = e.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });
    const newArr = [...initialValues]; // copying the old datas array
    if (e.target.type === 'checkbox') {
      newArr[CourseIndex] = {
        ...values[CourseIndex],
        [e.target.name]: e.target.checked,
      }; // replace e.target.value with whatever you want to change it to
    } else
      newArr[CourseIndex] = {
        ...values[CourseIndex],
        [e.target.name]: e.target.value,
      }; // replace e.target.value with whatever you want to change it to

    setinitialValues(newArr);
  };

  // console.log('valxxx', values);
  return (
    <div className="single__Test_wrapper">
      <label>
        <span className="important-field">Course Name</span>
        <br />
        <input
          type="text"
          name="name"
          value={values[CourseIndex]?.name}
          className={
            values[CourseIndex]?.name?.length === 0 &&
            touched[CourseIndex] &&
            'rez_input_error'
          }
          required
          placeholder="name of course"
          onChange={updateArray}
        />
      </label>

      <label>
        <span className="">Number</span>
        <br />
        <input
          type="text"
          placeholder=""
          name="number"
          value={values[CourseIndex]?.number}
          onChange={updateArray}
          className={
            values[CourseIndex]?.number?.length === 0 &&
            touched[CourseIndex] &&
            'rez_input_error'
          }
        />
      </label>

      <label>
        <span className="important-field">Associated with</span>
        <br />
        <input
          type="text"
          placeholder=""
          name="associatedWith"
          value={values[CourseIndex]?.associatedWith}
          onChange={updateArray}
          className={
            values[CourseIndex]?.associatedWith?.length === 0 &&
            touched[CourseIndex] &&
            'rez_input_error'
          }
        />
      </label>

      <button
        type="submit"
        className="form_next_btn"
        onClick={() => setIndexToSubmit(CourseIndex)}
        disabled={isFormRequesting && indexToSubmit === CourseIndex}
      >
        {isFormRequesting && indexToSubmit === CourseIndex
          ? 'Saving...'
          : 'Save Changes'}
      </button>
    </div>
  );
};

export default SingleCourse;
