import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Stack, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import homeImg from '../../../../assets/images/peeps.svg';

function Homepage() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      as="header"
      height="100vh"
      sx={{
        display: 'grid',
        gridTemplateColumns: { md: 'repeat(2, 1fr)' },
        alignItems: 'center',
        padding: { xs: '2rem 1.25rem 0.8rem', md: '4rem 2.5rem 1.75rem' },
      }}
    >
      <Stack>
        <Typography
          fontSize="41px"
          fontWeight="700"
          maxWidth="925px"
          lineHeight="1.2"
          mb="1.5rem"
        >
          {t('HR tech startup')}
        </Typography>
        <Typography fontSize="20px" fontWeight="600">
          {t('Leveraging Best')}
        </Typography>
      </Stack>{' '}
      <Box
        sx={{
          '& img': {
            width: '100%',
            maxWidth: '560px',
          },
        }}
      >
        <img className="homepage-img" src={homeImg} alt="3 people" />
      </Box>
      {/* <div>
        <input
          type="text"
          placeholder="Enter your Email"
          className="input"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        /> */}
    </Box>
  );
}

export default Homepage;
