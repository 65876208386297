import React from 'react';
import {
  Paragraph,
  Section,
  SectionBody,
  SectionHeading,
  SectionSubheading,
} from '../styles';

const Severability = () => (
  <>
    <Section>
      <SectionHeading>Severability and Waiver</SectionHeading>
      <SectionBody>
        <SectionSubheading>Severability</SectionSubheading>
        <Paragraph>
          If any provision of these Terms is held to be unenforceable or
          invalid, such provision will be changed and interpreted to accomplish
          the objectives of such provision to the greatest extent possible under
          applicable law and the remaining provisions will continue in full
          force and effect.
        </Paragraph>
      </SectionBody>
      <SectionBody>
        <SectionSubheading>Waiver</SectionSubheading>
        <Paragraph>
          Except as provided herein, the failure to exercise a right or to
          require performance of an obligation under these Terms shall not
          affect a party&apos;s ability to exercise such right or require such
          performance at any time thereafter nor shall the waiver of a breach
          constitute a waiver of any subsequent breach.
        </Paragraph>
      </SectionBody>
    </Section>
    <Section>
      <SectionHeading>Changes to These Terms and Conditions</SectionHeading>
      <Paragraph>
        We reserve the right, at our sole discretion, to modify or replace these
        Terms at any time. If a revision is material, we will make reasonable
        efforts to provide at least 30 days&apos; notice prior to any new terms
        taking effect. What constitutes a material change will be determined at
        Our sole discretion.
      </Paragraph>
      <Paragraph>
        By continuing to access or use Our Service after those revisions become
        effective, you agree to be bound by the revised terms. If You do not
        agree to the new terms, in whole or in part, please stop using the
        website and the Service.
      </Paragraph>
    </Section>
    <Section>
      <SectionHeading>Contact Us</SectionHeading>
      <Paragraph>
        If you have any questions about these Terms and Conditions, you can
        contact us By sending us an email:{' '}
        <a href="mailto:info@rezzie.co">info@rezzie.co</a>
      </Paragraph>
    </Section>
  </>
);

export default Severability;
