import React from 'react';
// import TextField from '@mui/material/TextField';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { CheckLabel } from '../../components/styles';

const SingleExperience = ({
  values,
  experienceIndex,
  touched,
  indexToSubmit,
  setIndexToSubmit,
  isFormRequesting,
  initialValues,
  setinitialValues,
}) => {
  const handleNewAchievement = () => {
    const newArr = [...initialValues];
    newArr[experienceIndex].achievement.push({
      id: `${Math.floor(Math.random() * 106)}`,
      value: '',
    });
    setinitialValues(newArr);
  };

  const handleDeleteAchievement = (id) => {
    const newArr = [...initialValues];
    newArr[experienceIndex].achievement = newArr[
      experienceIndex
    ].achievement.filter((item) => item?.id !== id);
    setinitialValues(newArr);
  };

  const updateArray = (e, index) => {
    const caret = e.target.selectionStart;
    const element = e.target;
    window.requestAnimationFrame(() => {
      if (
        e &&
        e.target &&
        (e.target.type === 'textarea' || e.target.type === 'text')
      ) {
        element.selectionStart = caret;
        element.selectionEnd = caret;
      }
    });

    const newArr = [...initialValues];

    if (e.target.type === 'checkbox') {
      newArr[experienceIndex] = {
        ...values[experienceIndex],
        [e.target.name]: e.target.checked,
      };
    } else if (
      e.target.type === 'textarea' &&
      e.target.name === 'achievement' &&
      index !== undefined
    ) {
      const array = newArr[experienceIndex].achievement;
      array[index].value = e.target.value;

      newArr[experienceIndex] = {
        ...values[experienceIndex],
        // [e.target.name]: array,
      };
    } else
      newArr[experienceIndex] = {
        ...values[experienceIndex],
        [e.target.name]: e.target.value,
      };

    setinitialValues(newArr);
  };
  return (
    <div className="single__experience_wrapper">
      <label>
        <span className="important-field">Title</span>
        <br />
        <input
          type="text"
          placeholder="E.g Product Designer"
          name="title"
          value={values[experienceIndex].title}
          className={
            values[experienceIndex]?.title?.length === 0 &&
            touched[experienceIndex]
              ? 'rez_input_error'
              : ''
          }
          required
          onChange={updateArray}
        />
      </label>
      <label>
        <span className="important-field">Employment Type</span>
        <br />
        <select
          name="employmentType"
          defaultValue={values[experienceIndex].employmentType}
          className={
            values[experienceIndex]?.employmentType?.length === 0 &&
            touched[experienceIndex]
              ? 'rez_input_error'
              : ''
          }
          required
          onChange={updateArray}
        >
          <option value="">Select the employment type</option>
          <option value="student">Student</option>
          <option value="parttime">Part time</option>
          <option value="fulltime">Full time</option>
          <option value="selfemployed">Self Employed</option>
          <option value="unemployed">Unemployed</option>
        </select>
      </label>
      <label>
        <span className="important-field">Company Name</span>
        <br />
        <input
          type="text"
          placeholder="Enter your company name"
          value={values[experienceIndex].companyName}
          name="companyName"
          className={
            values[experienceIndex]?.companyName?.length === 0 &&
            touched[experienceIndex]
              ? 'rez_input_error'
              : ''
          }
          required
          onChange={updateArray}
        />
      </label>
      <label>
        <span className="important-field">Where is this company based?</span>
        <br />
        <input
          type="text"
          name="companyLocation"
          value={values[experienceIndex].companyLocation}
          className={
            values[experienceIndex]?.companyLocation?.length === 0 &&
            touched[experienceIndex]
              ? 'rez_input_error'
              : ''
          }
          required
          onChange={updateArray}
        />
      </label>
      <label>
        <span className="important-field">
          When did you start at this role?
        </span>
        <br />
        <input
          type="date"
          name="startDate"
          className={
            values[experienceIndex]?.startDate?.length === 0 &&
            touched[experienceIndex]
              ? 'rez_input_error'
              : ''
          }
          value={
            values[experienceIndex]?.startDate &&
            new Date(values[experienceIndex].startDate)
              .toISOString()
              .substring(0, 10)
          }
          required
          onChange={updateArray}
        />
      </label>
      <input
        type="checkbox"
        name="active"
        onChange={updateArray}
        checked={values[experienceIndex].active}
      />
      &nbsp;&nbsp; I currently work here
      <br />
      <br />
      {!values[experienceIndex].active && (
        <label>
          When did you end this role?
          <br />
          <input
            type="date"
            name="endDate"
            value={
              values[experienceIndex]?.endDate &&
              new Date(values[experienceIndex].endDate)
                .toISOString()
                .substring(0, 10)
            }
            required={!values[experienceIndex].active}
            className={
              !values[experienceIndex].active &&
              values[experienceIndex]?.endDate?.length === 0 &&
              touched[experienceIndex]
                ? 'rez_input_error'
                : ''
            }
            onChange={updateArray}
          />
        </label>
      )}
      <label>
        Share any achievement and what your role entailed here
        <br />
        {values[experienceIndex]?.achievement?.map((item, index) => (
          <div key={`${item.id}`}>
            <textarea
              // as="textarea"
              placeholder="Share any achievements and what your role entailed here"
              name="achievement"
              className={
                values[experienceIndex]?.achievement[index].length === 0 &&
                touched[experienceIndex]
                  ? 'rez_input_error'
                  : ''
              }
              required
              onChange={(e) => updateArray(e, index)}
              value={values[experienceIndex].achievement[index].value}
            />
            {index === values[experienceIndex].achievement.length - 1 && (
              <span
                role="button"
                tabIndex="-9"
                className="textarea_add_experience"
                onClick={handleNewAchievement}
                onKeyPress={(e) => e.key === 'Enter' && handleNewAchievement}
              >
                <Plus />
                &nbsp;&nbsp; Add another achievement
              </span>
            )}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span
              role="button"
              tabIndex="-9"
              className="textarea_delete_experience"
              onClick={() => handleDeleteAchievement(item?.id)}
              onKeyPress={(e) =>
                e.key === 'Delete' && handleDeleteAchievement(item?.id)
              }
            >
              <Delete />
              &nbsp;&nbsp; Remove achievement
            </span>
          </div>
        ))}
      </label>
      <div>
        <button
          type="submit"
          className="form_next_btn"
          onClick={() => setIndexToSubmit(experienceIndex)}
          disabled={isFormRequesting && indexToSubmit === experienceIndex}
        >
          {isFormRequesting && indexToSubmit === experienceIndex
            ? 'Saving...'
            : 'Save Changes'}
        </button>
        <div className="clearfix" />
      </div>
    </div>
  );
};

const Plus = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9944 7.00012H8.98156V1.00293C8.98156 0.45293 8.53156 0.00292969 7.98156 0.00292969C7.43156 0.00292969 6.98156 0.45293 6.98156 1.00293V7.00012H1C0.45 7.00012 0 7.45012 0 8.00012C0 8.55012 0.45 9.00012 1 9.00012H6.98156V14.9973C6.98156 15.5473 7.43156 15.9973 7.98156 15.9973C8.53156 15.9973 8.98156 15.5473 8.98156 14.9973V9.00015H14.9944C15.5444 9.00015 15.9944 8.55015 15.9944 8.00015C15.9944 7.45015 15.5444 7.00015 14.9944 7.00015V7.00012Z"
      fill="#5C70CD"
    />
  </svg>
);

const Delete = () => (
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 6V16H3V6H11ZM9.5 0H4.5L3.5 1H0V3H14V1H10.5L9.5 0ZM13 4H1V16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4Z"
      fill="#E03232"
    />
  </svg>
);

export default SingleExperience;
