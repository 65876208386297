import React from 'react';
import Wrapper from './layout/Wrapper';
import Heading from './components/Heading&Caption';
import ExperienceForm from './components/forms/workexperience/ExperienceForm';

const Experiences = () => (
  <Wrapper>
    <Heading
      caption="Work Experience"
      skipBtn
      smallCaption="Give us all the details on the work experiences you’ve had over time. It’ll be nice to tailor this section to the role(s) you will be applying to with this resume."
    />
    {/* Experience Form */}
    <ExperienceForm formShown />
  </Wrapper>
);

export default Experiences;
