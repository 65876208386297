import styled from 'styled-components';

export const TemplateCategoryWrapper = styled.section`
  display: flex;
  justify-content: space-around;
  width: 100%;
  font-family: 'Circular Std Book';
  margin-top: 38px;
  max-width: 775px;
`;

export const Category = styled.div`
  width: 100px;

  .category__icon {
    width: 62px;
    height: 62px;
    border-radius: 50%;
    background: ${({ icon, active }) =>
      `${active ? '#5c70cd' : '#C0C7E4'} url(${icon}) no-repeat center`};
    display: block;
    margin: 0 auto;
    margin-bottom: 10px;
  }

  .category__title {
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #515151;
  }
`;

export const TemplatePreviewRow = styled.section`
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-top: 39px;
  /* align-content: space-between; */
  /* background: green; */
  max-width: 1200px;
`;

export const PreviewItemWrapper = styled.div`
  width: 354px;
  height: 419px;

  /* background: green; */
  background: rgba(92, 112, 205, 0.04);
  border: 1px solid ${({ isActive }) => (isActive ? '#5c70cd' : '#efefef')};
  border-radius: 10px;
  margin-bottom: 55px;
  padding: 35px 45px 0;
  &:hover {
    border: 1px solid #5c70cd;
  }
`;

export const PreviewItem = styled.div`
  width: 100%;
  height: 85%;

  background: rgba(92, 112, 205, 0.04);
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 15px;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const FooterBtns = styled.footer`
  display: flex;
  justify-content: space-between;
  width: 100%;

  font-family: 'Circular Std Bold';
  font-size: 14px;
  line-height: 30px;
  /* identical to box height, or 214% */

  color: #5c70cd;
  margin-top: 13px;
  /* margin-bottom: 13px; */

  div {
    cursor: pointer;
    svg {
      margin-right: 10px;
      vertical-align: middle;
    }
  }
`;

export const Footer = styled.footer`
  margin: 50px 13px 40px;
  display: flex;
  align-items: center;

  span {
    font-size: 18px;
    letter-spacing: 0em;
    color: #131936;
    margin-right: 30px;
  }

  .form_next_btn {
    height: 40px;
    padding: 0 16px;
    border-radius: 4px;
    background: #5c70cd;
    font-size: 16px;
    line-height: 29px;
    letter-spacing: 0em;
    border: none;
    color: white;
    cursor: pointer;
  }
`;
