import React from 'react';
import { GoogleLogin, useGoogleLogout } from 'react-google-login';
import { GoogleButton } from '../styles';
import googleIcon from '../../../assets/images/googleicon.svg';
import { refreshTokenSetup } from '../../../utils/refreshToken';
// Femi google token expires every .... so a fun is created to  refresh token
import { envConfig } from '../../../utils/envConfig';

const clientId = `${envConfig.googleClientId}`;

function LoginHooks({ handleAutoLogin, text }) {
  const onLogoutSuccess = () => sessionStorage.clear();
  const onLogoutFailure = () => sessionStorage.clear();

  const { signOut } = useGoogleLogout({
    clientId,
    onLogoutSuccess,
    onLogoutFailure,
  });

  const onSuccess = (res) => {
    const {
      profileObj: { familyName, givenName, imageUrl, email },
    } = res;
    handleAutoLogin(
      {
        firstName: givenName,
        lastName: familyName,
        email,
        avatar: imageUrl,
      },
      'google'
    );
    signOut();
    refreshTokenSetup(res);
  };

  const onFailure = (res) => {
    console.log(res);
    console.log('Failed to login using Google');
  };

  return (
    <div className="row mt-5">
      <div className="col-md-12">
        <GoogleLogin
          clientId={clientId}
          render={(renderProps) => (
            <button
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              type="button"
              style={{ width: '100%', border: 'none' }}
            >
              <GoogleButton>
                <img src={googleIcon} alt="google" />
                &nbsp;&nbsp;&nbsp;&nbsp; {text}
              </GoogleButton>
            </button>
          )}
          onSuccess={onSuccess}
          onFailure={onFailure}
          isSignedIn
          cookiePolicy="single_host_origin"
        />
      </div>
    </div>
  );
}

export default LoginHooks;
