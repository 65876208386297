import { LinkedInCallback } from 'react-linkedin-login-oauth2';

import Login from './screens/auth/login';
import Signup from './screens/auth/signup';
// import Home from './screens/onboarding/Home';
import ResumeBuilderHome from './screens/onboarding/resumeBuilder/index';
import ResumeBuilder from './screens/onboarding/resumeBuilder/ResumeBuilder';
import ResumeUpload from './screens/onboarding/resumeUpload/ResumeUpload';
import CoverLetterHome from './screens/onboarding/coverletterbuilder/index';
import CoverLetterBuilder from './screens/onboarding/coverletterbuilder/CoverLetterBuilder';
import CoverLetterTemplate from './screens/onboarding/coverletterbuilder/CoverLetterTemplate';
import CoverLetterReview from './screens/onboarding/coverletterbuilder/CoverLetterReview';
import Preview from './screens/onboarding/Preview';
import Onboarding from './screens/onboarding/Onboarding';
import Headline from './screens/onboarding/Headline';
import Experience from './screens/onboarding/Experience';
import Education from './screens/onboarding/Education';
import Volunteer from './screens/onboarding/Volunteer';
import Licenses from './screens/onboarding/Licenses';
import Accomplishments from './screens/onboarding/Accomplishments';
import Skills from './screens/onboarding/Skills';
import Profile from './screens/onboarding/Profile';
import SelectTemplate from './screens/selecttemplate';
import ViewTemplate from './screens/viewTemplate';
import ForgotPassword from './screens/auth/forgotpassword';
import ResumeLandingPage from './screens/landingPage/resumeBuilder/index';
import HomeLandingPage from './screens/landingPage/home/index';
import TermsAndConditions from './screens/termsAndConditions';
import PrivacyPolicy from './screens/privacyPolicy';
import Resources from './screens/landingPage/resources';
import Questionnaire from './screens/landingPage/resources/Questionnaire/index';

const routes = [
  {
    path: '/',
    component: HomeLandingPage,
    exact: true,
  },
  {
    path: '/resume-builder',
    component: ResumeLandingPage,
    exact: true,
  },
  {
    path: '/resumeandcoverletterbuilder/signup',
    component: Signup,
    exact: true,
  },
  {
    path: '/resumeandcoverletterbuilder/login',
    component: Login,
    exact: true,
  },
  {
    path: '/resources',
    component: Resources,
    exact: true,
  },
  {
    path: '/resources/questionnaire',
    component: Questionnaire,
    exact: true,
  },
  {
    path: '/forgotpassword',
    component: ForgotPassword,
    exact: true,
  },
  // {
  //   path: '/home',x
  //   component: Home,
  //   exact: true,
  // },
  {
    path: '/resume',
    component: ResumeBuilderHome,
    exact: true,
  },
  {
    path: '/resume/build/:form',
    component: ResumeBuilder,
    exact: true,
  },
  // {
  //   path: '/resume/upload/:form',
  //   component: ResumeUpload,
  //   exact: true,
  // },
  {
    path: '/coverletter',
    component: CoverLetterHome,
    exact: true,
  },
  {
    path: '/coverletter/build/:form',
    component: CoverLetterBuilder,
    exact: true,
  },

  {
    path: '/coverletter/templates',
    component: CoverLetterTemplate,
    exact: true,
  },
  {
    path: '/coverletter/review',
    component: CoverLetterReview,
    exact: true,
  },
  {
    path: '/profile',
    component: Profile,
    exact: true,
  },
  {
    path: '/onboarding',
    component: Onboarding,
    exact: true,
  },
  {
    path: '/headline',
    component: Headline,
    exact: true,
  },
  {
    path: '/experience',
    component: Experience,
    exact: true,
  },
  {
    path: '/education',
    component: Education,
    exact: true,
  },
  {
    path: '/volunteer',
    component: Volunteer,
    exact: true,
  },
  {
    path: '/licenses',
    component: Licenses,
    exact: true,
  },
  {
    path: '/accomplishments',
    component: Accomplishments,
    exact: true,
  },
  {
    path: '/skills',
    component: Skills,
    exact: true,
  },
  {
    path: '/previewsummary',
    component: Preview,
    exact: true,
  },
  {
    path: '/selecttemplate',
    component: SelectTemplate,
    exact: true,
  },
  {
    path: '/resumesample/:slug',
    component: ViewTemplate,
    exact: true,
  },
  {
    path: '/linkedin',
    component: LinkedInCallback,
    exact: true,
  },
  {
    path: '/termsandconditions',
    component: TermsAndConditions,
    exact: true,
  },
  {
    path: '/privacypolicy',
    component: PrivacyPolicy,
    exact: true,
  },
];

export default routes;
