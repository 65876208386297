import React from 'react';
import {
  Section,
  SectionHeading,
  Paragraph,
  SectionBody,
} from '../styles';

const ChildrensPrivacy = () => (
  <Section>
    <SectionHeading>Children&apos;s Privacy</SectionHeading>
    <SectionBody>
      <Paragraph>
        Our Service does not address anyone under the age of 13. We do not
        knowingly collect personally identifiable information from anyone under
        the age of 13. If You are a parent or guardian and You are aware that
        Your child has provided Us with Personal Data, please contact Us. If We
        become aware that We have collected Personal Data from anyone under the
        age of 13 without verification of parental consent, We take steps to
        remove that information from Our servers.
      </Paragraph>
      <Paragraph>
        If We need to rely on consent as a legal basis for processing Your
        information and Your country requires consent from a parent, We may
        require Your parent&apos;s consent before We collect and use that
        information.
      </Paragraph>
    </SectionBody>
  </Section>
);

export default ChildrensPrivacy;
