import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  padding: 0 8px;
  border-radius: 2px;
  border: 1px solid #a7a9bc;

  font-family: 'Circular Std Medium';
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0em;
  color: #6b6c7e;
  margin-right: 10px;
  svg {
    margin-left: 15px;
    cursor: pointer;
  }
`;

const SinglePatents = ({
  values,
  PatentIndex,
  touched,
  setAddInventor,
  addInventor,
  handleChange,
  initialValues,
  setinitialValues,
  indexToSubmit,
  setIndexToSubmit,
  isFormRequesting,
}) => {
  const removePatent = (pI, index, vd) => {
    const getArrayToUpdate = vd[pI];
    const updatedPatents = getArrayToUpdate.allInventors.filter(
      (ft, indexc) => indexc !== index
    );
    vd[pI].allInventors = updatedPatents;
    setinitialValues(vd);
  };

  const addPublication = (e, i, vd) => {
    if (vd[i].inventor !== '') {
      if (vd[i].allInventors === null) {
        vd[i].allInventors = [];
        const updatedPatents = [...vd[i].allInventors, vd[i].inventor];
        // console.log(updatedPatents);
        vd[i].allInventors = updatedPatents;
        vd[i].inventor = '';
        setinitialValues(vd);
      } else {
        const updatedPatents = [...vd[i].allInventors, vd[i].inventor];
        // console.log(updatedPatents);
        vd[i].allInventors = updatedPatents;
        vd[i].inventor = '';
        setinitialValues(vd);
      }
    }
  };

  const updateArray = (e) => {
    const caret = e.target.selectionStart;
    const element = e.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });
    const newArr = [...initialValues]; // copying the old datas array
    if (e.target.type === 'checkbox') {
      newArr[PatentIndex] = {
        ...values[PatentIndex],
        [e.target.name]: e.target.checked,
      }; // replace e.target.value with whatever you want to change it to
    } else
      newArr[PatentIndex] = {
        ...values[PatentIndex],
        [e.target.name]: e.target.value,
      }; // replace e.target.value with whatever you want to change it to

    setinitialValues(newArr);
  };

  return (
    <>
      <label>
        <span className="important-field">Patent Title</span>
        <br />
        <input
          type="text"
          name="title"
          value={values[PatentIndex]?.title}
          onChange={updateArray}
          className={
            values[PatentIndex]?.title?.length === 0 &&
            touched[PatentIndex] &&
            'rez_input_error'
          }
          required
          placeholder="E.g Corona virus vaccine"
        />
      </label>

      <label>
        <span className="important-field">Patent Office</span>
        <br />

        <input
          type="text"
          name="officeAddress"
          value={values[PatentIndex]?.officeAddress}
          onChange={updateArray}
          className={
            values[PatentIndex]?.officeAddress?.length === 0 &&
            touched[PatentIndex] &&
            'rez_input_error'
          }
          required
          placeholder="E.g Ikeja"
        />
      </label>

      <label>
        <span className="important-field">Patent or application number</span>
        <br />
        <input
          type="text"
          name="applicationNumber"
          value={values[PatentIndex]?.applicationNumber}
          onChange={updateArray}
          className={
            values[PatentIndex]?.applicationNumber?.length === 0 &&
            touched[PatentIndex] &&
            'rez_input_error'
          }
          required
          placeholder="E.g 1028-233"
        />
      </label>
      {addInventor ? (
        <>
          <label>
            <input
              type="text"
              placeholder="e.g John Doe"
              name={`[${PatentIndex}].inventor`}
              onChange={handleChange}
              value={values[PatentIndex]?.inventor}
              onKeyDown={(e) =>
                e.key === 'Enter' && addPublication(e, PatentIndex, values)
              }
            />
          </label>
        </>
      ) : null}
      <br />
      {values[PatentIndex]?.allInventors !== null &&
        values[PatentIndex]?.allInventors.length > 0 && (
          <label>
            Inventors
            <br />
            {values[PatentIndex]?.allInventors?.map((item, index) => (
              <Card key={`${Date.now()}__${item}`}>
                {item}
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => removePatent(PatentIndex, index, values)}
                >
                  <path
                    d="M5.72515 5.01764L9.37144 1.37136C9.56686 1.17593 9.56686 0.859361 9.37144 0.664488C9.17601 0.469064 8.85942 0.469064 8.66457 0.664488L5.01828 4.31078L1.37199 0.663902C1.17714 0.468478 0.859995 0.468478 0.665122 0.663902C0.469699 0.859325 0.469699 1.1759 0.665122 1.37077L4.31141 5.01764L0.665122 8.66393C0.469699 8.85936 0.469699 9.17595 0.665122 9.3708C0.762265 9.46908 0.890273 9.51766 1.01826 9.51766C1.14625 9.51766 1.27426 9.46908 1.37199 9.37137L5.01828 5.7245L8.66457 9.37078C8.7623 9.46851 8.89029 9.51708 9.01828 9.51708C9.14627 9.51708 9.27428 9.4685 9.37199 9.37078C9.56741 9.17536 9.56741 8.85877 9.37199 8.66392L5.72511 5.01763L5.72515 5.01764Z"
                    fill="#272833"
                  />
                </svg>
              </Card>
            ))}
          </label>
        )}
      <br />
      <label>
        {addInventor
          ? ''
          : 'You are an inventor. Add others that contributed to this patent'}
        <br /> <br />
        <span
          role="button"
          tabIndex="-9"
          className="textarea_add_experience"
          onClick={() => setAddInventor(!addInventor)}
          onKeyPress={(e) => e.key === 'Enter' && setAddInventor(!addInventor)}
        >
          <Plus />
          &nbsp;&nbsp; {addInventor ? 'Remove' : 'Add'}{' '}
          {addInventor ? 'inventors' : 'inventor'}
        </span>
        <br /> <br />
      </label>

      <label>
        <input
          type="radio"
          name="patentIssued"
          value="true"
          onClick={updateArray}
          checked={values[PatentIndex]?.patentIssued === true}
        />
        {/* <input type="radio" name="patentIssued" /> */}
        &nbsp; Patent issued
        <br />
      </label>
      <label>
        <input
          type="radio"
          name="patentIssued"
          value={false}
          onClick={updateArray}
          checked={values[PatentIndex]?.patentIssued === false}
        />
        {/* <input type="radio" name="patentIssued" /> */}
        &nbsp; Patent pending
        <br />
      </label>

      <div className="row-view">
        <label>
          <span className="important-field">Month</span>
          <br />
          {/* <input type="number" name="patMonth" /> */}
          <input
            type="text"
            name="patMonth"
            value={values[PatentIndex]?.patMonth}
            onChange={updateArray}
            className={
              values[PatentIndex]?.patMonth?.length === 0 &&
              touched[PatentIndex] &&
              'rez_input_error'
            }
            required
          />
        </label>

        <label>
          <span className="important-field">Day</span>
          <br />
          {/* <input type="number" name="patDay" /> */}
          <input
            type="text"
            name="patDay"
            value={values[PatentIndex]?.patDay}
            onChange={updateArray}
            className={
              values[PatentIndex]?.patDay?.length === 0 &&
              touched[PatentIndex] &&
              'rez_input_error'
            }
            required
          />
        </label>

        <label>
          <span className="important-field">Year</span>
          <br />
          {/* <input type="number" name="patYear" /> */}
          <input
            type="text"
            name="patYear"
            onChange={updateArray}
            value={values[PatentIndex]?.patYear}
            className={
              values[PatentIndex]?.patYear?.length === 0 &&
              touched[PatentIndex] &&
              'rez_input_error'
            }
            required
          />
        </label>
      </div>

      <label>
        <span className="">Patent URL</span>
        <br />
        {/* <input
          name="publicationUrl"
          type="text"
          placeholder="https://"
          // value={values.portfolioUrl}
          // onChange={handleChange}
        /> */}

        <input
          type="text"
          name="patUrl"
          value={values[PatentIndex]?.patUrl}
          onChange={updateArray}
          placeholder="https://"
        />
      </label>

      <label>
        <span className="important-field">Description</span>
        <br />
        {/* <textarea name="description" required /> */}
        <textarea
          type="textarea"
          name="description"
          value={values[PatentIndex]?.description}
          onChange={updateArray}
          className={
            values[PatentIndex]?.description?.length === 0 &&
            touched[PatentIndex] &&
            'rez_input_error'
          }
          placeholder="description..."
          required
        />
      </label>

      <div>
        <button
          type="submit"
          className="form_next_btn"
          onClick={() => setIndexToSubmit(PatentIndex)}
          disabled={isFormRequesting && indexToSubmit === PatentIndex}
        >
          {isFormRequesting && indexToSubmit === PatentIndex
            ? 'Saving...'
            : 'Save Changes'}
        </button>
        <div className="clearfix" />
      </div>
    </>
  );
};

export default SinglePatents;

const Plus = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9944 7.00012H8.98156V1.00293C8.98156 0.45293 8.53156 0.00292969 7.98156 0.00292969C7.43156 0.00292969 6.98156 0.45293 6.98156 1.00293V7.00012H1C0.45 7.00012 0 7.45012 0 8.00012C0 8.55012 0.45 9.00012 1 9.00012H6.98156V14.9973C6.98156 15.5473 7.43156 15.9973 7.98156 15.9973C8.53156 15.9973 8.98156 15.5473 8.98156 14.9973V9.00015H14.9944C15.5444 9.00015 15.9944 8.55015 15.9944 8.00015C15.9944 7.45015 15.5444 7.00015 14.9944 7.00015V7.00012Z"
      fill="#5C70CD"
    />
  </svg>
);
