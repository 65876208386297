import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import useSWR from 'swr';
// import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import FormWrapper from '../../../FormWrapper';
import SingleHonor from './singleHonor';
import apiClient from '../../../../../../api/server';
import {
  postHonor,
  // postHonorWNextRoute,
} from '../../../../../../redux/actions';
import { GOTO_NEXT_ONBOARDINGROUTE } from '../../../../../../redux/actionTypes';

const Honorform = ({ noFooter, formShown }) => {
  const { user } = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  const { isFormRequesting } = useSelector((state) => state?.onboarding);

  const { data, mutate } = useSWR(
    () => `/honor/${user.id}`,
    () => apiClient.getHonor(user.id)
  );
  const [initialValues, setinitialValues] = useState([
    {
      id: Math.floor(Math.random() * 106),
      title: '',
      issuer: '',
      issueDate: '',
      associatedWith: '',
      description: '',
    },
  ]);

  const [submitFromWrapper, setSubmitFromWrapper] = useState(false);
  const [indexToSubmit, setIndexToSubmit] = useState(0);
  const newHonorInstance = {
    id: Math.floor(Math.random() * 106),
    title: '',
    issuer: '',
    issueDate: '',
    associatedWith: '',
    description: '',
  };
  useEffect(() => {
    if (data?.data && data.data.length > 0) {
      const userHonors = data.data;
      setinitialValues(userHonors);
    } else {
      setinitialValues([newHonorInstance]);
    }
  }, [data]);

  const handleNewHonorInstance = () => {
    setinitialValues([...initialValues, newHonorInstance]);
  };
  const handleDelHonorInstance = (id) => {
    setinitialValues(initialValues.filter((item) => item?.id !== id));
    const dataExists = data?.data.some((dt) => dt.id === id);
    if (dataExists) {
      dispatch(postHonor(user.id, [], 'delete', id));
      mutate();
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        const dataExists = data?.data.some(
          (dt) => dt.id === values[indexToSubmit].id
        );
        if (dataExists) {
          dispatch(
            postHonor(
              user.id,
              values[indexToSubmit],
              'patch',
              values[indexToSubmit].id
            )
          );
          mutate();
        } else if (!dataExists) {
          dispatch(postHonor(user.id, values[indexToSubmit]));
          mutate();
        }

        if (submitFromWrapper) {
          dispatch({ type: GOTO_NEXT_ONBOARDINGROUTE });
        }
      }}
    >
      {({ values, handleSubmit, touched, handleChange }) => (
        <FormWrapper
          formShown={formShown}
          noFooter={noFooter}
          handleSubmit={handleSubmit}
          indexToSubmit={indexToSubmit}
          setSubmitFromWrapper={setSubmitFromWrapper}
        >
          {values.map((item, index) => (
            <>
              <SingleHonor
                key={`${item.id}`}
                values={values}
                touched={touched}
                HonorIndex={index}
                indexToSubmit={indexToSubmit}
                setIndexToSubmit={setIndexToSubmit}
                isFormRequesting={isFormRequesting}
                handleChange={handleChange}
                initialValues={initialValues}
                setinitialValues={setinitialValues}
              />
              <span
                role="button"
                tabIndex="-9"
                className="textarea_add_experience"
                onClick={handleNewHonorInstance}
                onKeyPress={(e) => e.key === 'Enter' && handleNewHonorInstance}
              >
                <Plus />
                &nbsp;&nbsp; Add another Honor
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span
                role="button"
                tabIndex="-9"
                className="textarea_delete_experience"
                onClick={() => {
                  handleDelHonorInstance(item?.id);
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Delete') {
                    handleDelHonorInstance(item?.id);
                  }
                }}
              >
                <Delete />
                &nbsp;&nbsp; Remove Honor
              </span>
            </>
          ))}
        </FormWrapper>
      )}
    </Formik>
  );
};
export default Honorform;

const Plus = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9944 7.00012H8.98156V1.00293C8.98156 0.45293 8.53156 0.00292969 7.98156 0.00292969C7.43156 0.00292969 6.98156 0.45293 6.98156 1.00293V7.00012H1C0.45 7.00012 0 7.45012 0 8.00012C0 8.55012 0.45 9.00012 1 9.00012H6.98156V14.9973C6.98156 15.5473 7.43156 15.9973 7.98156 15.9973C8.53156 15.9973 8.98156 15.5473 8.98156 14.9973V9.00015H14.9944C15.5444 9.00015 15.9944 8.55015 15.9944 8.00015C15.9944 7.45015 15.5444 7.00015 14.9944 7.00015V7.00012Z"
      fill="#5C70CD"
    />
  </svg>
);

const Delete = () => (
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 6V16H3V6H11ZM9.5 0H4.5L3.5 1H0V3H14V1H10.5L9.5 0ZM13 4H1V16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4Z"
      fill="#E03232"
    />
  </svg>
);
