import React from 'react';
import Wrapper from './layout/Wrapper';
import Heading from './components/Heading&Caption';
import Skillform from './components/forms/skills/skillForm';

const Skills = () => (
  <Wrapper>
    <Heading
      caption="Add Skills"
      skipBtn
      smallCaption="Add all skills that best suit you."
    />
    <Skillform formShown />
  </Wrapper>
);

export default Skills;

// const Times = () => (
//   <svg
//     width="10"
//     height="10"
//     viewBox="0 0 10 10"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <path
//       d="M5.72515 5.01764L9.37144 1.37136C9.56686 1.17593 9.56686 0.859361 9.37144 0.664488C9.17601 0.469064 8.85942 0.469064 8.66457 0.664488L5.01828 4.31078L1.37199 0.663902C1.17714 0.468478 0.859995 0.468478 0.665122 0.663902C0.469699 0.859325 0.469699 1.1759 0.665122 1.37077L4.31141 5.01764L0.665122 8.66393C0.469699 8.85936 0.469699 9.17595 0.665122 9.3708C0.762265 9.46908 0.890273 9.51766 1.01826 9.51766C1.14625 9.51766 1.27426 9.46908 1.37199 9.37137L5.01828 5.7245L8.66457 9.37078C8.7623 9.46851 8.89029 9.51708 9.01828 9.51708C9.14627 9.51708 9.27428 9.4685 9.37199 9.37078C9.56741 9.17536 9.56741 8.85877 9.37199 8.66392L5.72511 5.01763L5.72515 5.01764Z"
//       fill="#272833"
//     />
//   </svg>
// );
