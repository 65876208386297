import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import './i18n';
import 'react-toastify/dist/ReactToastify.css';
import { store } from './redux/store';
import App from './App';
import 'normalize.css';
import './index.css';

Modal.setAppElement('#root');

/* Global color scheme */
const theme = {
  baseDarkBlue: '#131936',
  baseLightBlue: '#5C70CD',
  baseText: '#272833',
  baseTextLighter: '#6B6C7E',
  baseHeadingDark: '#131936',
  baseInputBackground: '#E7E7ED',
};

/* Global Style */
const GlobalStyle = createGlobalStyle`
.rez_input_error {
    border: 1px solid red !important;
}
    .row-view{
      display: flex;
    }
`;

ReactDOM.render(
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <HelmetProvider>
      <Helmet>
        <script>
          {` window['_fs_debug'] = false; window['_fs_host'] = 'fullstory.com'; window['_fs_script'] = 'edge.fullstory.com/s/fs.js'; window['_fs_org'] = 'o-19C1XR-na1'; window['_fs_namespace'] = 'FS'; (function(m,n,e,t,l,o,g,y){ if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;} g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[]; o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script; y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y); g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)}; g.anonymize=function(){g.identify(!!0)}; g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)}; g.log = function(a,b){g("log",[a,b])}; g.consent=function(a){g("consent",!arguments.length||a)}; g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)}; g.clearUserCookie=function(){}; g.setVars=function(n, p){g('setVars',[n,p]);}; g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y]; if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)}; g._v="1.3.0"; })(window,document,window['_fs_namespace'],'script','user'); `}
        </script>
      </Helmet>
      <Provider store={store}>
        <React.StrictMode>
          <ThemeProvider theme={theme}>
            <ToastContainer
              hideProgressBar
              pauseOnHover={false}
              pauseOnFocusLoss={false}
              draggable={false}
              autoClose={2000}
            />
            <GlobalStyle />
            <Router>
              <PersistGate persistor={store.__PERSISTOR}>
                <App />
              </PersistGate>
            </Router>
          </ThemeProvider>
        </React.StrictMode>
      </Provider>
    </HelmetProvider>
  </LocalizationProvider>,
  document.getElementById('root')
);
