import styled from 'styled-components';

export const PageContainer = styled.main`
  font-family: 'Quicksand';
  max-width: 1200px;
  margin: 0 auto;
`;

export const PageHeading = styled.h1`
  font-size: 35px;
  padding-bottom: 20px;
  border-bottom: 1px solid #131936;
  @media screen and (min-width: 768px) {
    font-size: 45px;
  }
`;

export const PageSubheading = styled.h3`
  font-size: 30px;
  margin: 20px 0;
`;

export const Paragraph = styled.p`
  margin-bottom: 16px;
`;

export const Section = styled.section`
  padding: 16px 0;
`;

export const SectionHeading = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;
`;

export const SectionSubheading = styled.h4`
  font-size: 18px;
  margin-bottom: 8px;
`;

export const SectionBody = styled.div`
  margin: 16px 0;
`;

export const List = styled.ul`
  margin: 10px 0 0 40px;
  list-style: circle;
`;

export const ListItem = styled.li`
  margin-bottom: 12px;
`;
